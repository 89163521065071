<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        Uplink Relocator
      </template>

      <template v-slot:body>
        <div class="pillbox-wrapper">
          <Pillbox ref="pillbox" :pills="pills" :activePillChanged="activePillChanged" :defaultPill="activeSection" />
        </div>

        <hr class="form-sep" />

        <div style="min-height: 445px">
          <RecentJobsTable v-if="activeSection === 'recent'" :jobsData="jobsData" />
          <NewRelocationRequestForm
            ref="relocationRequestForm"
            :data="formData"
            :jobsData="jobsData"
            @setCanSubmit="setCanSubmit"
            @onSubmitSuccess="onSubmitSuccess"
            v-else-if="activeSection === 'new'" />
          <AboutSection v-else />
        </div>

      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button v-if="activeSection === 'new'" class="btn" @click="submitForm()" :disabled="!canSubmit">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>

import Modal from '@/components/modals/Modal.vue'
import AboutSection from '@/components/tools/UplinkRelocator/AboutSection.vue'
import RecentJobsTable from '@/components/tools/UplinkRelocator/RecentJobsTable.vue'
import NewRelocationRequestForm from '@/components/tools/UplinkRelocator/NewRelocationRequestForm.vue'
import Pillbox from '@/components/controls/Pillbox.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'uplink-relocator-modal',
  props: [],
  data () {
    return {
      isBlocking: false,
      formData: {quickMove: true},
      isLoading: false,
      activeSection: 'about',
      canSubmit: false,
      jobsData: []
    }
  },
  computed: {
    pills () {
      return [
        {id: 'about', label: 'About'},
        {id: 'recent', label: 'Recent Requests'},
        {id: 'new', label: 'New Request'},
      ]
    }
  },
  methods: {
    onSubmitSuccess () {
      this.loadJobs()
      this.$refs.pillbox.pillClicked('recent')
    },
    loadJobs () {
      FleetDataService.getJobsByService('uplink-relocator')
      .then((resp) => {
        this.jobsData = resp.data
        let shouldReload = false
        for (const row of resp.data) {
          if (row && row.status && row.status === 'new') {
            shouldReload = true
            break
          }
        }
        if (shouldReload && this.isBlocking) {
          setTimeout(this.loadJobs, 3000)
        }
      })
      .catch((e) => {
        HelpContent.setFlashMessage('Failed to load Recent Uplink Relocation Requests list.', true)
        console.log('failed to load jobs', e)
      })
    },
    submitForm () {
      this.$refs.relocationRequestForm.submitForm()
    },
    setCanSubmit (t) {
      this.canSubmit = t
    },
    activePillChanged (value) {
      if (value) {
        this.activeSection = value
      }
    },
    reset () {
      this.mode = ''
      this.formData = {
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
      this.loadJobs()
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    }
  },
  mounted () {
  },
  components: { Modal, Pillbox, AboutSection, RecentJobsTable, NewRelocationRequestForm }
}
</script>

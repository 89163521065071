<template>
  <div :class="wrapperClasses">
    <span class="pillbox-label" v-if="label">{{ label }}</span>
    <div class="btn-group" role="group" :aria-label="label">
      <template v-for="pill of pills" :key="pill">
        <input type="radio" class="btn-check" :value="pillValue(pill)" :name="groupName"
          :id="pillId(pill)" v-model="activePill" autocomplete="off" @click="pillElementClicked" />
        <label class="btn btn-outline-primary" :for="pillId(pill)" style="min-width: 45px;">
          <template v-if="pill.label && pill.icon || !pill.icon">
            &nbsp;{{ pillLabel(pill) }}&nbsp;
          </template>
          <fa :icon="pill.icon" v-if="pill.icon" style="max-height: 15px;" />
        </label>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pillbox',
  props: ['label', 'group', 'pills', 'defaultPill', 'activePillChanged', 'wrapperClass', 'modelValue'],
  emits: ['pillChanged', 'update:modelValue'],
  data () {
    return {
      activePill: this.defaultPill || ''
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    groupName () {
      if (this.group) {
        return this.group
      }
      if (this.label) {
        return this.label.replaceAll(' ', '').toLowerCase()
      }
      return 'pillbox'
    },
    wrapperClasses () {
      const classes = "d-flex align-items-center"
      if (this.wrapperClass) {
        return `${classes} ${this.wrapperClass}`
      }
      return classes
    }
  },
  methods: {
    pillLabel (pill) {
      if (pill.label) {
        return pill.label
      }
      return "No Label"
    },
    pillId (pill) {
      if (pill.id) {
        return pill.id
      }
      const prefix = this.group ? this.group + '.' : ''
      return prefix + this.pillLabel(pill).replaceAll(' ', '').toLowerCase()
    },
    pillValue (pill) {
      if (pill.value) {
        return pill.value
      }
      return this.pillId(pill)
    },
    pillClicked (value) {
      if (value){
        this.activePill = value
        this.$emit('update:modelValue', value)
        this.$emit('pillChanged', value)
        if (this.activePillChanged) {
          this.activePillChanged(value)
        }
      }
    },
    pillElementClicked (e) {
      if (e && e.target && e.target.value){
        this.pillClicked(e.target.value)
      }
    }
  }
}
</script>
<style scoped>
  .pillbox-label {
    font-size: var(--font-reduce-2);
    font-weight: 600;
    margin-right: 7.5px;
  }
</style>
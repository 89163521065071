<template>
  <CaseViewerPanel><template v-slot:body>
    <div class="mt-1">
      <b>Status:</b> <span >{{ caseData.status }}</span>
    </div>
    <div class="mt-1">
      <b>Priority:</b> <span >{{ caseData.priority }}</span>
    </div>
    <div class="mt-1" v-if="caseData.assignee">
      <b>Assignee:</b> <span >{{ caseData.assignee }}</span>
    </div>
    <div class="mt-1">
      <b>Start Time:</b> <span >{{ caseData.startTS }}</span>
    </div>
    <div class="mt-1" v-if="['resolved', 'deleted'].includes(caseData.status) && caseData.endTS">
      <b>End Time:</b> <span >{{ caseData.endTS }}</span>
    </div>
  </template></CaseViewerPanel>
</template>
<script>
import CaseViewerPanel from '@/components/fleet/Maintenance/CaseViewerPanel.vue'

export default {
  name: 'maintenance-case-viewer-general',
  props: ['caseData'],
  data () {
    return {
      isLoading: false
    }
  },
  components: { CaseViewerPanel }
}
</script>
<style scoped>
  .case-viewer-pane {
    padding: 50px;
    margin-bottom: 10px;
  }
</style>
<template>
  <div class="buttons-row" :class="wrapperClass">
    <BasicButton
      v-for="button, i of buttons" :key="i"
      :color="button.color"
      :disabled="button.disabled"
      :icon="button.icon"
      :caption="button.caption"
      :action="button.action"
      :short="button.short"
    />
  </div>
</template>
<script>
import BasicButton from '@/components/controls/BasicButton.vue'

export default {
  name: 'buttons-row',
  props: ['buttons', 'wrapperClass'],
  components: { BasicButton }
}
</script>
<style scoped>
  .buttons-row {
    display: flex;
  }

  .buttons-row.centered {
    justify-content: center;
  }
</style>
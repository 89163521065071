<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="smx2">
      <template v-slot:header>
        Node Layout Manager
      </template>

      <template v-slot:body>
        <div class="p-3">

          <div class="d-flex">

            <SelectRow
              class="pillbox-row me-3"
              @change="getLayoutsByFunction"
              labelClass="width-auto"
              selectClass="width-200"
              label="Node Role"
              v-model="dgFunc"
              :disabled="isDirty || isLoading"
            >
              <template v-slot:options>
                <option value="">-- Select a Role --</option>
                <NodeRoleOptions />
              </template>
            </SelectRow>

            <SelectRow
              class="pillbox-row"
              @change="layoutOptsChanged"
              labelClass="width-auto"
              selectClass="width-295"
              label="Layout"
              v-model="dgLayout"
              v-if="dgFunc"
              :disabled="isDirty || isLoading"
            >
              <template v-slot:options>
                <option value="">-- Select a Layout --</option>
                <option v-for="(layout, i) in funcLayouts" :key="i" :value="layout.id">{{  layout.displayName  }}</option>
                <option value="new">Add New Layout ...</option>
              </template>
            </SelectRow>
          </div>
          <hr class="mt-3" v-if="layoutOpts && dgFunc && dgLayout" />
          <div class="mt-3 mb-2" v-if="layoutOpts && dgFunc && dgLayout">

            <TextInputRow
              v-if="layoutOpts"
              class="pillbox-row mb-3"
              labelClass="width-auto"
              inputClass="width-300"
              label="Layout Name"
              v-model="layoutOpts.displayName"
              placeholder="Layout Name"
              maxlength="36"
            />

            <InfoPane
              class="width-auto position-map-pane"
              title="Layout Positions"
              actionIcon="plus"
              :actionFunc="addPositionMode"
              actionLabel="Add Position / Slot"
              :actionDisabled="!canSubmit"
            >
              <template v-slot:info>
                <table class="dt">

                  <tr v-if="isAddingPosition">
                    <td colspan="3">
                      <div class="d-flex align-items-center">
                        <SelectRow
                          v-if="layoutOpts"
                          class="pillbox-row"
                          labelClass="width-auto font-size-default"
                          label="Position"
                          v-model="newPosition"
                          :okFunc="addPosition"
                          :cancelFunc="disableAddPositionMode"
                        >
                          <template v-slot:options>
                            <option value="">-- Select a Position --</option>
                            <DevicePositionOptions />
                          </template>
                        </SelectRow>
                        <div>
                          <BasicButton
                            :caption="addPositionOrSlotLabel"
                            :action="addPosition"
                            class="ms-2 width-100"
                            :disabled="!canAddPositionOrSlot"
                          />
                          <BasicButton caption="Cancel" :action="disableAddPositionMode" class="ms-2" />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="!isAddingPosition && !displayedPositions">
                    <td colspan="3">
                      No Positions for this Layout yet.
                    </td>
                  </tr>

                  <tr class="dt-header" v-else-if="displayedPositions">
                    <th class="width-135">Position</th>
                    <th class="width-75">Slot #</th>
                    <th class="width-490">Permitted Devices</th>
                  </tr>

                  <tr v-for="row of displayedPositions" :key="row.slot">
                    <td><span v-if="row.slot == 0">{{ row.position }}</span></td>
                    <td class="ps-3">{{ parseInt(row.slot) + 1 }}</td>
                    <td>
                      <div class="tag-bubble-group-container">
                        <TagBubbleGroup
                          allowAddTagBubble="true"
                          :tagBubbles="bubbleCfgFromModels(row.models)"
                          newItemPlaceholder="Model Name"
                          :addTagBubbleFunc="addModelToPosition"
                          :removeTagBubbleFunc="removeModelFromPosition"
                          :groupName="row.position + '.' + row.slot"
                          emptyGroupNote="Empty Positions / Slots are removed when the Layout is saved."
                          :isAddingFunc="setIsAddingModel"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </template>
            </InfoPane>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button v-if="!isModeAddNewLayout && !isAddingPosition && isDirty" class="btn btn-green ms-2 height-32" @click="updateLayout" :disabled="!canSubmit">Save Layout Changes</button>
          <button v-if="!isModeAddNewLayout && !isAddingPosition && isDirty" class="btn btn-blue ms-2 height-32" @click.prevent="abortChanges">Revert Layout Changes</button>
          <button v-if="isModeAddNewLayout && !isAddingPosition && isDirty" class="btn btn-green ms-2 height-32" @click="addLayout" :disabled="!canSubmit">Create Layout</button>
          <button v-if="isModeAddNewLayout && !isAddingPosition && isDirty" class="btn btn-blue ms-2 height-32" @click="dgLayout = ''; layoutOpts = undefined;">Abort Creating Layout</button>
          <button v-if="!isDirty && !isAddingPosition" class="btn btn-blue ms-2 height-32" @click.prevent="close">Close</button>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import HelpContent from '@/services/HelpContent'

import TagBubbleGroup from '@/components/controls/TagBubbleGroup.vue'
import InfoPane from '@/components/tables/InfoPane.vue'

import BasicButton from '@/components/controls/BasicButton.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import NodeRoleOptions from '@/components/selectOptions/NodeRoleOptions.vue'
import DevicePositionOptions from '@/components/selectOptions/DevicePositionOptions.vue'
import Modal from '@/components/modals/Modal.vue'

import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'modal-node-layouts',
  components: { Modal, NodeRoleOptions, DevicePositionOptions, TagBubbleGroup, SelectRow, TextInputRow, BasicButton, InfoPane },
  data () {
    return {
      isBlocking: false,
      isLoading: false,
      isAddingPosition: false,
      isAddingModel: false,
      dgFunc: '',
      dgLayout: '',
      layoutOpts: undefined,
      layoutOptsPE: undefined,
      newPosition: '',
      funcLayouts: []
    }
  },
  computed: {
    canSubmit () {
      if (this.isAddingModel || this.isAddingPosition ||
        !this.layoutOpts.displayName) {
        return false
      }
      return true
    },
    canAddPositionOrSlot () {
      if (this.newPosition) {
        return true
      }
      return false
    },
    addPositionOrSlotLabel () {
      if (!this.newPosition || !this.layoutOpts || !this.layoutOpts.positions) {
        return "Add Position"
      }
      if (this.newPosition in this.layoutOpts.positions) {
        return "Add Slot"
      }
      return "Add Position"
    },
    layoutOptsWithSlots () {
      const out = {}
      if (this.layoutOpts.id) {
        out.id = this.layoutOpts.id
      }
      if (this.layoutOpts.displayName) {
        out.displayName = this.layoutOpts.displayName
      }
      if (this.layoutOpts.function) {
        out.function = this.layoutOpts.function
      }
      if (!('positions' in this.layoutOpts)) {
        return out
      }
      out.positions = {}
      for (const key in this.layoutOpts.positions) {
        const data = this.layoutOpts.positions[key] || ''
        if (typeof(data) === 'string') {
          out.positions[key] = this.layoutOpts.positions[key].split('|')
        }
      }
      return out
    },
    displayedPositions () {
      if (this.isAddingPosition || !this.layoutOptsWithSlots || !this.layoutOptsWithSlots.positions) {
        return undefined
      }
      const out = []
      for (const key in this.layoutOptsWithSlots.positions) {
        for (const i in this.layoutOptsWithSlots.positions[key]) {
          out.push({
            position: key,
            slot: i,
            models: this.layoutOptsWithSlots.positions[key][i]
          })
        }
      }
      return out
    },
    isDirty () {
      if (this.layoutOpts && this.layoutOptsPE) {
        if (this.layoutOpts.displayName !== this.layoutOptsPE.displayName) {
          return true
        }
        for (const k in this.layoutOpts.positions) {
          if (!(k in this.layoutOptsPE.positions) || this.layoutOptsPE.positions[k] !== this.layoutOpts.positions[k]) {
            return true
          }
        }
        for (const k in this.layoutOptsPE.positions) {
          if (!(k in this.layoutOpts.positions)) {
            return true
          }
        }
      }
      return false
    },
    isModeAddNewLayout () {
      return this.dgLayout === 'new'
    },
    closeDisabled () {
      return this.isModeAddNewLayout
    }
  },
  methods: {
    setIsAddingModel (t) {
      if (t) {
        this.isAddingModel = true
      } else {
        this.isAddingModel = false
      }
    },
    emptyObj (func = '') {
      return {
        positions: undefined,
        displayName: '',
        function: func
      }
    },
    updateLayout () {
      if (!this.layoutOpts) {
        return
      }
      for (const k in this.layoutOpts.positions) {
        if (!this.layoutOpts.positions[k]) {
          delete this.layoutOpts.positions[k]
          continue
        }
        const slots = this.layoutOpts.positions[k].split('|')
        const out = []
        for (const i in slots) {
          if (!slots[i]) {
            continue
          }
          const models = slots[i].split(',')
          const modelsOut = []
          for (const model of models) {
            if (!model) {
              continue
            }
            modelsOut.push(model)
          }
          if (modelsOut.length > 0) {
            out.push(modelsOut.join(','))
          }
        }
        if (out.length === 0) {
          delete this.layoutOpts.positions[k]
          continue
        }
        this.layoutOpts.positions[k] = out.join('|')
      }
      this.$nextTick(() => {
        if (!this.isDirty) {
          HelpContent.setTimedFlashMessage('No changes detected.')
        } else {
          this.isLoading = true
          const id = this.layoutOpts.id
          delete this.layoutOpts.id
          FleetDataService.updateNodeLayout(id, this.layoutOpts)
            .then(() => {
              HelpContent.setTimedFlashMessage('Layout Updated')
              this.getLayoutsByFunction()
              this.layoutOpts.id = id
              this.layoutOptsPE = this.copyLayoutObj(this.layoutOpts)
            })
            .catch(e => {
              this.layoutOpts.id = id
              let message = 'Layout Update Failed'
              if (e.response && e.response.data && e.response.data.message) {
                message += `: ${e.response.data.message}`
              } else {
                message += `: ${e}`
              }
              HelpContent.setFlashMessage(message, true)
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    addLayout () {
      if (!this.layoutOpts || this.dgLayout !== 'new') {
        return
      }
      for (const l of this.funcLayouts) {
        if (l && l.displayName === this.layoutOpts.displayName) {
          HelpContent.setFlashMessage(`A layout named ${this.layoutOpts.displayName} is already configured for ${this.dgFunc}`, true)
          return
        }
      }
      for (const k in this.layoutOpts.positions) {
        if (!this.layoutOpts.positions[k]) {
          delete this.layoutOpts.positions[k]
        }
      }
      this.$nextTick(() => {
        if (!this.isDirty) {
          HelpContent.setTimedFlashMessage('No changes detected.')
        } else {
          this.isLoading = true
          delete this.layoutOpts.id
          FleetDataService.createNodeLayout(this.layoutOpts)
            .then((resp) => {
              HelpContent.setTimedFlashMessage('Layout Created')
              if (resp && resp.data && resp.data.id) {
                this.dgLayout = resp.data.id
                this.layoutOpts.id = this.dgLayout
              }
              this.layoutOptsPE = this.copyLayoutObj(this.layoutOpts)
              this.getLayoutsByFunction()
            })
            .catch(e => {
              let message = 'Layout Creation Failed'
              if (e.response && e.response.data && e.response.data.message) {
                message += `: ${e.response.data.message}`
              } else {
                message += `: ${e}`
              }
              HelpContent.setFlashMessage(message, true)
            })
            .finally(() => {
              this.isLoading = false
              this.close()
            })
        }
      })
    },
    abortChanges () {
      this.layoutOpts = this.copyLayoutObj(this.layoutOptsPE)
    },
    addPositionMode () {
      this.isAddingPosition = true
    },
    disableAddPositionMode () {
      this.isAddingPosition = false
      this.newPosition = ''
    },
    addPosition () {
      if (!this.layoutOpts) {
        this.disableAddPositionMode()
        return
      }
      if (!this.newPosition) {
        this.disableAddPositionMode()
        return
      }
      if (!this.layoutOpts.positions) {
        this.layoutOpts.positions = {}
      }
      if (this.newPosition in this.layoutOpts.positions) {
        this.layoutOpts.positions[this.newPosition] = this.layoutOpts.positions[this.newPosition] + '|'
      } else {
        this.layoutOpts.positions[this.newPosition] = ''
      }
      this.disableAddPositionMode()
    },
    removeModelFromPosition (pos, model) {
      if (!this.layoutOpts || !pos || !model) {
        return
      }
      let slot = 0
      const parts = pos.split('.')
      if (parts.length === 2) {
        pos = parts[0]
        slot = parts[1]
      }
      if (!(pos in this.layoutOpts.positions) || !this.layoutOpts.positions[pos]) {
        return
      }
      const slots = [...this.layoutOptsWithSlots.positions[pos]]
      if (slots.length <= slot || !slots[slot]) {
        return
      }
      const models = slots[slot].split(',')
      if (!models.includes(model)) {
        return
      }
      const newModels = []
      for (const m of models) {
        if (m === model) {
          continue
        }
        newModels.push(m)
      }
      slots[slot] = newModels
      this.layoutOpts.positions[pos] = slots.join('|')
    },
    addModelToPosition (pos, model) {
      if (!this.layoutOpts || !pos || !model) {
        return
      }
      let slot = 0
      const parts = pos.split('.')
      if (parts.length === 2) {
        pos = parts[0]
        slot = parts[1]
      }
      if (!(pos in this.layoutOpts.positions) || !this.layoutOpts.positions[pos]) {
        this.layoutOpts.positions[pos] = model
        return
      }
      const slots = [...this.layoutOptsWithSlots.positions[pos]]
      if (slots.length <= slot) {
        slots.push(model)
      } else {
        if (!slots[slot]) {
          slots[slot] = model
        } else {
          const models = slots[slot].split(',')
          if (models.includes(model)) {
            return
          }
          slots[slot] = slots[slot] + ',' + model
        }
      }
      this.layoutOpts.positions[pos] = slots.join('|')
    },
    bubbleCfgFromModels (input) {
      if (!input) {
        return []
      }
      const parts = input.split(',')
      if (!parts || parts.length === 0) {
        return []
      }
      const tbs = []
      for (const part of parts) {
        tbs.push({ label: part })
      }
      return tbs
    },
    copyLayoutObj (obj) {
      if (!obj) {
        return obj
      }
      const cpy = { id: obj.id, displayName: obj.displayName, function: obj.function }
      cpy.positions = {}
      for (const key in obj.positions) {
        cpy.positions[key] = obj.positions[key]
      }
      return cpy
    },
    layoutOptsChanged () {
      if (this.dgLayout) {
        if (this.dgLayout === 'new') {
          this.layoutOpts = this.emptyObj(this.dgFunc)
          this.layoutOptsPE = this.emptyObj(this.dgFunc)
          return
        }
        for (const l of this.funcLayouts) {
          if (l.id === this.dgLayout) {
            this.layoutOptsPE = this.copyLayoutObj(l)
            this.layoutOpts = this.copyLayoutObj(l)
            return
          }
        }
      }
      this.layoutOpts = undefined
      this.layoutOptsPE = undefined
    },
    reset () {
      this.isLoading = false
      this.dgFunc = ''
      this.dgLayout = ''
      this.newPosition = ''
      this.layoutOpts = undefined
      this.layoutOptsPE = undefined
      this.funcLayouts = []
      this.isAddingPosition = false
    },
    show () {
      this.isBlocking = true
      this.reset()
    },
    close () {
      if (this.closeDisabled) {
        return
      }
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    getLayoutsByFunction () {
      if (!this.dgFunc) {
        return
      }
      this.isLoading = true
      FleetDataService.listNodeLayoutsByFunction(this.dgFunc)
        .then(response => {
          this.funcLayouts = response.data
        })
        .catch(e => {
          let message = 'Failed to list Device Group Layouts for Function ' + this.dgFunc
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          console.log('ERROR', e)
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style scoped>
.tag-bubble-group-container {
    display: flex;
    max-width: 465px;
    flex-wrap: wrap;
}
</style>
<template>
  <CaseViewerPanel style="height: 32px; max-height: 32px; min-height: 32px;"><template v-slot:body>
    <div>
      <b>Case #:</b> <span class="font-reduce-1">{{ caseData.id }}</span>
    </div>
  </template></CaseViewerPanel>
</template>
<script>
import CaseViewerPanel from '@/components/fleet/Maintenance/CaseViewerPanel.vue'

export default {
  name: 'maintenance-case-viewer-id',
  props: ['caseData'],
  data () {
    return {
      isLoading: false
    }
  },
  components: { CaseViewerPanel }
}
</script>
<style scoped>
  .case-viewer-pane {
    margin-bottom: 10px;
  }
</style>
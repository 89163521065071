<template>
  <div class="case-map">
    <MapControl
      :height="mapHeight"
      :markers="markers"
      :useBounds="true"
      :noPopupDivider="true"
    />
  </div>
</template>
<script>
import MapControl from '@/components/map/MapControl.vue'
import { NewMarkers, NewMarker, NewMapMarkerContent, NewMapMarkerContentItem } from '@/components/map/MapControl.ts'

const markerColor = (priority, status) => {
  if (['resolved', 'deleted'].includes(status)) {
    return '#aaa'
  }
  if (priority === 'high') {
    return '#cc0000'
  }
  if (priority === 'medium') {
    return '#e8b800'
  }
  if (priority === 'low') {
    return '#3366ff'
  }
}

export default {
  name: 'maint-case-map',
  components: { MapControl },
  props: ['caseList', 'loading'],
  emits: ['showCaseEditor'],
  data () {
    return {
      mapHeight: this.getMapHeight()
    }
  },
  computed: {
    markers () {
      const out = NewMarkers()
      if (!this.caseList || this.caseList === 0) {
        return out
      }
      for (const mc of this.caseList) {
        const marker = NewMarker()
        marker.icon = 'map-pin'
        marker.color = markerColor(mc.priority, mc.status)
        if ('latitude' in mc && 'longitude' in mc) {
          marker.coords = [mc.longitude, mc.latitude]
        }
        const content = NewMapMarkerContent()
        const elemType = mc.elementType === 'gateway' ? 'Gateway' : 'Device'
        content.title = `${elemType}: ${mc.elementID}`

        content.subtitle = 'Case #: '
        content.subtitleLinkText = this.shortID(mc.id)
        content.subtitleClickFunc = () => { this.$emit('showCaseEditor', mc) }

        const statusData = NewMapMarkerContentItem()
        statusData.title = 'Status: '
        statusData.body = mc.status
        content.items.push(statusData)

        const assigneeData = NewMapMarkerContentItem()
        assigneeData.title = 'Assignee: '
        assigneeData.body = mc.assignee
        content.items.push(assigneeData)

        const priorityData = NewMapMarkerContentItem()
        priorityData.title = 'Priority:'
        priorityData.body = mc.priority
        content.items.push(priorityData)

        const failmodeData = NewMapMarkerContentItem()
        failmodeData.title = 'Failure Mode:'
        failmodeData.body = mc.failureMode
        content.items.push(failmodeData)

        const descrData = NewMapMarkerContentItem()
        descrData.title = 'Description:'
        descrData.body = mc.description
        content.items.push(descrData)

        marker.content = content
        out.push(marker)
      }
      return out
    }
  },
  methods: {
    shortID (input) {
      if (!input || input.length < 12) {
        return input
      }
      return input.substring(input.length - 12)
    },
    getMapHeight () {
      return (window.innerHeight - 200) + 'px'
    }
  }
}
</script>

<style scoped>
  .case-map {
    padding-top: 10px;
  }
</style>
<template>
<div style="toolbar-container">
  <Toolbar class="filter-row-fixed mt-0 pt-0 ms-0 ps-0" style="padding-bottom: 3px;">
    <template v-slot:sections>
      <ToolbarSection label="View:" class="mb-0 mt-0 pt-0">
        <template v-slot:buttons>
          <ToolbarButton icon="list" :isActive="viewMode === 'list'" @click="() => { viewModeChanged('list') }" :isSingletonGroupMember="true" />
          <ToolbarButton icon="map" :isActive="viewMode === 'map'" @click="() => { viewModeChanged('map') }" :isSingletonGroupMember="true" />
        </template>
      </ToolbarSection>
      <ToolbarSeparator class="my-25px" />
      <ToolbarSection label="Filter:" labelClass="ps-1" :noHover="true">
        <template v-slot:buttons>
          <ToolbarButton :id="iconId" icon="filter" :isToggle="true" :isActive="filterFormIsOpen" @click="() => { toggleFilterMenu() }" />
          <div class="filter-summary d-none d-md-block" v-if="filterSummary">{{ filterSummary }}</div>
        </template>
      </ToolbarSection>
    </template>
  </Toolbar>
  <ContextMenuForm
    ref="filterContextMenu"
    :posAnchorId="iconId"
    :closer="() => {filterFormIsOpen = false}"
    :formFields="formFields"
    :formData="formData"
    @setFormData="formDataChanged"
    formTitle="Maintenance Case Filter"
    containerClass="mt-7px default-background"
    :bigShadow="true"
  />
</div>
</template>
<script>
import api from '@/services/fleet/FleetDataService'
import Toolbar from '@/components/controls/Toolbar/Toolbar.vue'
import ToolbarSection from '@/components/controls/Toolbar/ToolbarSection.vue'
import ToolbarButton from '@/components/controls/Toolbar/ToolbarButton.vue'
import ToolbarSeparator from '@/components/controls/Toolbar/ToolbarSeparator.vue'
import ContextMenuForm from '@/components/controls/ContextMenuForm/ContextMenuForm.vue'
import { StatusOpen, StatusResolved, StatusDeleted, FilterIconId } from '@/components/fleet/Maintenance/constants.ts'
import { loadFilter, saveFilter, All, Self, Other, Resolved, Customer, Site, Gateway, Device } from '@/components/fleet/Maintenance/filters.ts'

export default {
  name: 'case-manager-filters',
  components: { Toolbar, ToolbarSection, ToolbarButton, ToolbarSeparator, ContextMenuForm },
  emits: ['updateFilterSettings', 'updateViewMode'],
  props: ['viewMode'],
  data () {
    const defaultDataCache = this.defaultFormData()
    return {
      filterFormIsOpen: false,
      formData: { ...defaultDataCache },
      appliedFilter: { ... defaultDataCache}
    }
  },
  computed: {
    eventualElementId () {
      switch (this.formData.elementType) {
        case Customer: 
          return this.formData.cElementId
        case Site: 
          return this.formData.sElementId
        case Device: 
          return this.formData.dElementId
        case Gateway: 
          return this.formData.gElementId
        default:
          break
      }
      return undefined
    },
    areAllAll () {
      return this.formData.assigneeMode === All && this.formData.elementType === All && [All, Resolved].includes(this.formData.statusMode)
    },
    canApply () {
      if (this.areAllAll) {
        return false
      }
      if (this.formData.assigneeMode === Other && !this.formData.assigneeEmail) {
        return false
      }
      if (this.formData.elementType !== All && !this.eventualElementId) {
        return false
      }
      return true
    },
    filterSummary () {
      if (!this.appliedFilter) {
        return undefined
      }
      if (this.appliedFilter.assigneeMode === All &&
        this.appliedFilter.statusMode === All && 
        this.appliedFilter.elementType === All) {
        return undefined
      }
      let out = ''
      const sep = ' • '
      let currentSep = ''
      // assignee
      if (this.appliedFilter.assigneeMode === Self) {
        out += 'Assignee: Me'
        currentSep = sep
      } else if (this.appliedFilter.assigneeMode === Other && this.appliedFilter.assigneeEmail) {
        out += `Assignee: ${this.appliedFilter.assigneeEmail.toLowerCase()}`
        currentSep = sep
      }
      // case status
      if (this.appliedFilter.statusMode === StatusOpen) {
        out += `${currentSep}Status: Open`
        currentSep = sep
      } else if (this.appliedFilter.statusMode === StatusResolved) {
        out += `${currentSep}Status: Resolved`
        currentSep = sep
      } else if (this.appliedFilter.statusMode === StatusDeleted) {
        out += `${currentSep}Status: Deleted`
        currentSep = sep
      }
      // Element
      if (this.appliedFilter.elementType !== All && this.appliedFilter.elementId) {
        if (this.appliedFilter.elementType === Customer) {
          out += `${currentSep}Customer: ${api.getCustomerDisplayName(this.appliedFilter.elementId)}`
          currentSep = sep
        } else if (this.appliedFilter.elementType === Site) {
          out += `${currentSep}Site: ${api.getSiteDisplayName(this.appliedFilter.elementId)}`
          currentSep = sep
        } else if (this.appliedFilter.elementType === Gateway) {
          out += `${currentSep}Gateway: ${this.appliedFilter.elementId.toUpperCase()}`
          currentSep = sep
        } else if (this.appliedFilter.elementType === Device) {
          out += `${currentSep}Device: ${this.appliedFilter.elementId.toUpperCase()}`
          currentSep = sep
        }
      }
      return out
    },
    filter () {
      return this.appliedFilter
    },
    formFields () {
      return [
        {
          controlType: 'sectionHeader',
          label: 'Assignee',
          headingClass: 'no-border-top'
        },
        {
          controlType: 'pillboxInput',
          pills: [{label: 'All', value: All} , {label: 'Me', value: Self}, {label: 'Other', value: Other}],
          field: 'assigneeMode',
          labelClass: 'width-0 font-reduce-1',
          group: 'assigneeMode',
          wrapperClass: 'mt-3 mb-3'
        },
        {
          hidden: this.formData.assigneeMode !== Other,
          field: 'assigneeEmail',
          label: 'Email',
          controlType: 'textInput',
          required: true,
          labelClass: 'width-85 font-reduce-1',
          inputClass: 'width-234 font-reduce-1'
          
        },
        {
          controlType: 'sectionHeader',
          label: 'Case Status'
        },
        {
          controlType: 'pillboxInput',
          pills: [ {label: 'All', value: All}, {label: 'Open', value: StatusOpen}, {label: 'Resolved', value: StatusResolved}, {icon: 'trash', value: StatusDeleted}],
          field: 'statusMode',
          labelClass: 'width-0 font-reduce-1',
          group: 'statusMode',
          wrapperClass: 'mt-3 mb-3'
        },
        {
          controlType: 'sectionHeader',
          label: 'Topology Element'
        },
        {
          controlType: 'pillboxInput',
          pills: [{label: 'All', value: All}, {label: 'Customer', value: Customer} , {label: 'Site', value: Site}, {label: 'Device', value: Device}, {label: 'Gateway', value: Gateway}],
          field: 'elementType',
          labelClass: 'width-0 font-reduce-1',
          group: 'subjectMode',
          wrapperClass: 'mt-3 mb-3'
        },
        {
          hidden: this.formData.elementType !== Customer,
          field: 'cElementId',
          label:  'Customer:',
          controlType: 'searchRow',
          required: this.formData.elementType === Customer,
          labelClass: 'width-95 font-reduce-2',
          inputClass: 'width-255 font-reduce-1',
          elementType: this.formData.elementType,
          placeholder: 'Start typing a Customer Name or ID',
          resultsClass: 'width-255',
          valueClass: 'width-255'
        },
        {
          hidden: this.formData.elementType !== Site,
          field: 'sElementId',
          label: 'Site:',
          controlType: 'searchRow',
          required: this.formData.elementType === Site,
          labelClass: 'width-95 font-reduce-2',
          inputClass: 'width-255 font-reduce-1',
          elementType: this.formData.elementType,
          placeholder: 'Start typing a Site Name or ID',
          resultsClass: 'width-255',
          valueClass: 'width-255'
        },
        {
          hidden: this.formData.elementType !== Device,
          field: 'dElementId',
          label: 'Device:',
          controlType: 'searchRow',
          required: this.formData.elementType === Device,
          labelClass: 'width-95 font-reduce-2',
          inputClass: 'width-255 font-reduce-1',
          elementType: this.formData.elementType,
          placeholder: 'Start typing a Device EUI',
          resultsClass: 'width-255',
          valueClass: 'width-255'
        },
        {
          hidden: this.formData.elementType !== Gateway,
          field: 'gElementId',
          label: 'Gateway:',
          controlType: 'searchRow',
          required: this.formData.elementType === Gateway,
          labelClass: 'width-95 font-reduce-2',
          inputClass: 'width-255 font-reduce-1',
          elementType: this.formData.elementType,
          placeholder: 'Start typing a Gateway EUI',
          resultsClass: 'width-255',
          valueClass: 'width-255'
        },
        {
          controlType: 'hr'
        },
        {
          hidden: !this.areAllAll,
          controlType: 'textContent',
          text: 'This filter configuration is not supported.',
          textClass: 'status-error font-reduce-2 centered px-0'
        },
        {
          hidden: !this.areAllAll,
          controlType: 'hr'
        },
        {
          controlType: 'buttonsRow',
          wrapperClass: 'centered mt-3 mb-3',
          buttons: [
            {
              caption: 'Apply Filter',
              action: this.applySettings,
              disabled: !this.canApply
            }
          ]
        }
      ]
    },
    iconId () {
      return FilterIconId
    }
  },
  methods: {
    applySettings() {
      const filter = { ...this.formData }
      if (this.eventualElementId) {
        filter.elementId = this.eventualElementId
      }
      const deletes = ['cElementId', 'sElementId', 'dElementId', 'gElementId']
      for (const d of deletes) {
        delete filter[d]
      }
      if ('assigneeEmail' in filter) {
        if (!filter.assigneeMode || filter.assigneeMode !== Other) {
          delete filter.assigneeEmail
        } else {
          let email = (filter.assigneeEmail || '').toLowerCase()
          if (email) {
            if (!(email.includes('@'))) {
              email += '@agrology.ag'
            }
            filter.assigneeEmail = email
          }
        }
      }
      saveFilter(filter)
      this.setURL(filter)
      this.appliedFilter = filter
      this.$nextTick(() => {
        this.$emit('updateFilterSettings')
        this.$refs.filterContextMenu.close()
      })
    },
    formDataChanged (data) {
      this.formData = data
    },
    setURL (filter) {
      const query = {}
      // assignee
      if (filter.assigneeMode === Self)  {
        query.a = this.$state.email
      } else if (filter.assigneeMode === All) {
        query.a = All
      } else if (filter.assigneeMode === Other && filter.assigneeEmail)  {
        query.a = filter.assigneeEmail
      }
      // status
      if (filter.statusMode) {
        query.s = filter.statusMode
      }
      // element
      if (filter.elementType && filter.elementId) {
        query.t = filter.elementType
        query.i = filter.elementId
      }
      this.$router.replace({ query: query })
    },
    defaultFormData () {
      const out = loadFilter(this.$route.query, this.$state.email)
      if (out.elementType === Customer) {
        out.cElementId = out.elementId
      } else if (out.elementType === Site) {
        out.sElementId = out.elementId
      } else if (out.elementType === Device) {
        out.dElementId = out.elementId
      } else if (out.elementType === Gateway) {
        out.gElementId = out.elementId
      }
      this.setURL(out)
      return out
    },
    toggleFilterMenu () {
      const val = !this.filterFormIsOpen
      this.filterFormIsOpen = val
      if (val) {
        this.$refs.filterContextMenu.open()
      } else {
        this.$refs.filterContextMenu.close()
      }
    },
    viewModeChanged (mode) {
      this.$emit('updateViewMode', mode)
    }
  }
}
</script>
<style scoped>
div.toolbar-container {
  display: flex;
  margin-top: -2px;
  margin-left: 5px;
  background-color: red !important;
}
div.container {
  padding-left: 0px !important;
}
.maint-case-filter-row {
  display: flex;
}
div.filter-summary {
  font-size: var(--font-reduce-2);
  /* color: var(--app-subtle-color); */
  margin-left: 5px;
  margin-top: 2.30px;
}

.filter-row-fixed {
  z-index: 22;
  position: relative;
  background-color: var(--app-bg-color);
}

.filter-menu {
  margin-top: 50px;
}
</style>
<template>
    <DataTable
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No Jobs Found"
      :initialSort="1"
      style="max-height: 530px; overflow-y: auto;"
    />

</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'

export default {
  name: 'recent-requests-table',
  props: ['jobsData'],
  extends: BaseComponent,
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    cols () {
      return [
        { text: 'Device EUI', sort: 0, class: 'table-small-text' },
        { icon: 'clock', sort: 1 },
        { text: 'Stat.', sort: 2, class: 'table-extra-small-text' },
        { text: 'Requested By', sort: 3, class: 'table-small-text' }
      ]
    },
    rows () {
      const out = []
      if (!this.jobsData) {
        return out
      }
      for (const row of this.jobsData) {
        out.push([
          { value: row.metadata && row.metadata.deviceEui ? row.metadata.deviceEui : '-', class: 'table-small-text' },
          { value: this.getSinceSecs(row.lastModified), sortValue: (Date.now() / 1000) - row.lastModified, class: 'table-small-text' },
          { leftIcon: this.statusIconFromStatus(row.status), leftIconStyle: {marginLeft: '4px', height: '14px', width: '14px' }, leftIconContainerClass: {'status-ok': row.status === 'success', 'status-error': row.status === 'failed'} },
          { value: row.createdBy ? row.createdBy : '-', class: 'table-small-text' },
        ])
      }
      return out
    }
  },
  methods: {
    statusIconFromStatus (input) {
      if (!input || input === 'new') {
        return 'gears'
      }
      if (input === 'success') {
        return 'check'
      }
      if (input === 'failed') {
        return 'times'
      }
      return undefined
    }
  },
  mounted () {
  },
  components: { DataTable }
}
</script>

<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm" :showSpinner="isLoading">
      <template v-slot:header>
        <span v-if="isCreate">New Maintenance Case</span>
        <span v-else>Maintenance Case Details</span>
      </template>

      <template v-slot:body>
        <div>
          <SubmissionForm
            ref="caseSubmissionForm"
            :fields1="formFields"
            @setFormData="formDataChanged"
            @setCanSubmit="setCanSubmit"
            @searchItemSelected="searchItemSelected"
            @apiResponse="apiResponse"
            @prepareToClose="() => { this.isClosing = true }"
            @setIsLoading="(val) => { this.isLoading = val }"
            :formData="formData"
            :isCreate="isCreate"
            :createFunc="createCaseFunc"
            :updateFunc="updateCaseFunc"
            :deleteFunc="deleteCaseFunc"
            elementType="Maintenance Case"
            responseDisplayType="panes"
            :hideCloseTime="hideCloseTime"
            :allowRetry="true"
            v-if="!isFlashing"
          >
            <!-- timed delete custom mode -->
            <template v-slot:customMode1>
              <div class="modal-add-update-case-pane">
                <div style="margin-top: 50px;">
                  <fa icon="circle-info" style="max-width: 100px" />
                </div>
                <br />
                <br />
                <b>Just a Quick Confirmation...</b>
                <br />
                <br />
                Are you sure you want to delete this Case? Remember to only delete Cases that were opened in error.
                <br />
                <br />
                This Case is recoverable for the next 7 days, after which it will be permanently deleted.
                <br />
                <br />
                Press Confirm or Go Back below to proceed.
              </div>
            </template>
          </SubmissionForm>
        </div>
      </template>
      <template v-slot:footer>
        <template v-if="formData.viewingTab === 'caseFiles'">
          <BasicButton color="blue" class="ms-3" :action="close" :caption="isCreate ? 'Cancel' : 'Close'" :disabled="isClosing || isLoading" v-if="!isDeleting" />
        </template>
        <template v-else>
          <BasicButton color="red" :action="executeDeletion" caption="Confirm Deletion" :disabled="isClosing || isLoading" v-if="isDeleting" />
          <BasicButton class="ms-3" color="blue" :action="backToEditor" caption="Go Back" v-if="isDeleting" :disabled="isClosing || isLoading"/>
          <BasicButton class="ms-3" :action="reopenCase" caption="Reopen Case" v-if="this.caseData && this.caseData.status === 'resolved' && !isDeleting" :disabled="isClosing || isLoading"/>
          <BasicButton class="ms-3" :action="recoverCase" caption="Recover Case" v-if="canRecover" :disabled="isClosing || isLoading"/>
          <BasicButton class="ms-3" :action="submit" :caption="isRetrying ? 'Retry Request' : isCreate ? 'Create Case' : 'Update Case'" :disabled="!canSubmit || isClosing || isLoading" v-if="!allFieldsDisabled && !isDeleting && !canRecover" />
          <BasicButton color="blue" class="ms-3" :action="backToEditor" caption="Back to Editor" v-if="isRetrying" :disabled="isClosing || isLoading"/>
          <BasicButton color="red" class="ms-3" :action="startDelete" caption="Delete Case" v-if="showDeleteBtn" :disabled="isLoading || isClosing"/>
          <BasicButton color="blue" class="ms-3" :action="close" :caption="isCreate ? 'Cancel' : 'Close'" :disabled="isClosing || isLoading" v-if="!isDeleting" />
        </template>
      </template>
    </Modal>
  </transition>
</template>
<script>

import Modal from '@/components/modals/Modal.vue'
import SubmissionForm from '@/components/forms/SubmissionForm.vue'
import BasicButton from '@/components/controls/BasicButton.vue'
import { failModeOptions, elementTypeOptions, statusOptions,
  StatusOpen, StatusDeleted, StatusResolved, priorityOptions } from '@/components/fleet/Maintenance/constants'
import api from '@/components/fleet/Maintenance/api'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'maintenance-case-add-update',
  props: ['caseData'],
  emits: ['requestCaseDataRefresh'],
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: this.defaultFormData(),
      canSubmit: false,
      isRetrying: false,
      isClosing: false,
      oldRoute: undefined,
      isDeleting: false,
      isScheduledDelete: false,
      isFlashing: false,
      inProgressVerb: undefined
    }
  },
  watch: {
    caseData: {
      handler () {
        this.caseDataChanged()
      }
    },
    isBlocking: {
      handler () {
        this.caseDataChanged()
      }
    }
  },
  computed: {
    hideCloseTime () {
      return ['recover', 'reopen'].includes(this.inProgressVerb)
    },
    allFieldsDisabled () {
      return this.caseData && [StatusDeleted, StatusResolved].includes(this.caseData.status)
    },
    canRecover () {
      return this.caseData && this.caseData.status === StatusDeleted
    },
    caseDeleteTime () {
      if (!this.caseData || !this.caseData.status === StatusDeleted || !this.caseData.deleteTS) {
        return
      }
      const date = new Date(this.caseData.deleteTS * 1000)
      return (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + (date.getFullYear() % 2000).toString()
    },
    createCaseFunc () {
      return async (data) => {
        if (this.formData.elementType && this.formData.elementID) {
          const manifest = FleetDataService.getManifest()
          const key = `${this.formData.elementType}.${this.formData.elementID}`.toLowerCase()
          if (key in manifest) {
            const elem = manifest[key]
            if ('lat' in elem && 'long' in elem) {
              this.formData.latitude = elem.lat
              this.formData.longitude = elem.long
            }
          }
        }
        return api.createMaintenanceCase(data)
      }
    },
    updateCaseFunc () {
      return api.updateMaintCaseById
    },
    deleteCaseFunc () {
      return api.deleteMaintCaseById
    },
    showDeleteBtn () {
      if (this.canRecover) {
        return false
      }
      if (this.isCreate) {
        return false
      }
      if (this.isDeleting) {
        return false
      }
      return true
    },
    isCreate () {
      if (this.caseData) {
        return false
      }
      return true
    },
    formFields () {
      const out = []
      if (!this.isCreate) {
        out.push({
          controlType: 'id',
          value: this.caseData.id,
          field: 'id',
          hideLabel: true
        })
        out.push({
          controlType: 'textLabelRow',
          label: 'Element',
          text: `${this.caseData.elementType} / ${this.caseData.elementID}`,
          labelClass: 'width-100 ps-0 font-reduce-2',
          textClass: 'subtle',
          disabled: this.allFieldsDisabled
        })
        out.push({
          controlType: 'pillboxInput',
          label: '',
          labelClass: 'width-0 ps-0 ms-0',
          hidden: !this.caseData,
          disabled: this.allFieldsDisabled,
          pills: [{label: 'Case Properties', value: 'caseProps'}, {label: 'Files', value: 'caseFiles'}],
          field: 'viewingTab',
          group: 'viewingTab',
          wrapperClass: 'mt-3'
        })
      }

      if (this.formData.viewingTab === 'caseFiles') {
        return out
      }

      if (!this.isCreate) {
        out.push({
          controlType: 'selectInput',
          label: 'Case Status',
          field: 'status',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          options: statusOptions(),
          required: (this.caseData.status === StatusOpen),
          hidden: this.caseData.status !== StatusOpen,
          disabled: this.allFieldsDisabled
        })
        out.push({
          controlType: 'textLabelRow',
          label: 'Case Status',
          labelClass: 'width-100 ps-0 font-reduce-2 subtle',
          text: this.caseDeleteTime ? `Scheduled for Deletion on ${this.caseDeleteTime}` : 'Scheduled for Deletion',
          textClass: 'status-caution font-reduce-2',
          hidden: this.caseData.status !== StatusDeleted
        })
      }

      out.push({
        controlType: 'selectInput',
        label: 'Priority',
        field: 'priority',
        labelClass: 'width-100 ps-0 font-reduce-2',
        inputClass: 'width-270 font-reduce-1',
        options: priorityOptions(),
        required: !this.allFieldsDisabled,
        disabled: this.allFieldsDisabled
      })
      out.push({
        controlType: 'selectInput',
        label: 'Failure Mode',
        field: 'failureMode',
        labelClass: 'width-100 ps-0 font-reduce-2',
        inputClass: 'width-270 font-reduce-1',
        options: failModeOptions(),
        required: !this.allFieldsDisabled,
        disabled: this.allFieldsDisabled
      })
      out.push({
        controlType: 'simpleDateTime',
        label: 'Start',
        field: 'startTS',
        labelClass: 'width-100 ps-0 font-reduce-2',
        required: !this.allFieldsDisabled,
        disabled: this.allFieldsDisabled,
        useLocalTime: true,
        showAmPm: true
      })
      out.push({
        controlType: 'textLabelRow',
        label: '',
        labelClass: 'width-75 font-reduce-2',
        text: `Times are in your computer's Time Zone`,
        textClass: 'subtle font-reduce-2',
        height: 'shorter',
        wrapperClass: 'mb-2'
      })

      if (this.formData.status === StatusResolved) {
        out.push({
          controlType: 'simpleDateTime',
          label: 'End',
          field: 'endTS',
          labelClass: 'width-100 ps-0 font-reduce-2',
          required: this.formData.status === StatusResolved && !this.allFieldsDisabled,
          hidden: this.formData.status !== StatusResolved,
          disabled: this.allFieldsDisabled,
          useLocalTime: true,
          showAmPm: true
        })
      }

      if (this.isCreate) {
        out.push({
          controlType: 'selectInput',
          label: 'Element Type',
          field: 'elementType',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          options: elementTypeOptions(),
          required: true,
          disabled: this.allFieldsDisabled
        })
        out.push({
          controlType: 'searchRow',
          label: 'Element ID',
          field: 'elementID',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          placeholder: this.formData.elementType ? 'Paste or Start Typing an EUI' : 'Select an Element Type',
          required: true,
          disabled: !this.formData.elementType || (this.caseData && this.caseData.status === StatusDeleted),
          elementType: this.formData.elementType,
          resultsClass: 'width-270',
          valueClass: 'width-270',
        })
      }

      out.push({
          controlType: 'textInput',
          label: 'Assignee',
          field: 'assignee',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          placeholder: 'field-tech@agrology.ag',
          required: !this.allFieldsDisabled,
          wrapperClass: 'mb-2',
          disabled: this.allFieldsDisabled
        })
      out.push({
          controlType: 'textarea',
          label: 'Description',
          field: 'description',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          required: !this.allFieldsDisabled,
          disabled: this.allFieldsDisabled
        })

      if (this.formData.status === StatusResolved) {
        out.push({
          controlType: 'textarea',
          label: 'Resolution',
          field: 'resolutionDescription',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          hidden: this.formData.status !== StatusResolved,
          required: this.formData.status === StatusResolved,
          disabled: this.allFieldsDisabled
        })
      }

      out.push({
          controlType: 'textarea',
          label: 'Notes',
          field: 'notes',
          labelClass: 'width-100 ps-0 font-reduce-2',
          inputClass: 'width-270 font-reduce-1',
          hidden: this.formData.status === StatusResolved,
          disabled: this.allFieldsDisabled
        })

      return out
    }
  },
  methods: {
    caseDataChanged () {
      if (this.caseData) {
        this.reset()
        setTimeout(this.refreshSubmittable, 50)
        this.isFlashing = true
        this.$nextTick(() => {
          this.isFlashing = false
        })
      }
    },
    startDelete () {
      if (!this.caseData || !(this.caseData.startTS)) {
        return
      }
      this.isDeleting = true
      if (Date.now() / 1000 > this.caseData.startTS + 3600) {
        this.isScheduledDelete = true
        this.$refs.caseSubmissionForm.setMode('custom1')
      } else {
        this.$refs.caseSubmissionForm.confirmDeleteElement()
      }
    },
    reopenCase () {
      if (!this.caseData || !this.caseData.status === StatusResolved) {
        return
      }
      const f = async () => {
        return api.updateMaintCaseById(this.caseData.id, {
          status: 'open',
          unset: ['endTS']
        })
      }
      this.inProgressVerb = 'reopen'
      this.$refs.caseSubmissionForm.doSubmit(f, 'Reopen')
    },
    recoverCase () {
      if (!this.caseData || !this.caseData.status === StatusDeleted) {
        return
      }
      const f = async () => {
        return api.updateMaintCaseById(this.caseData.id, {
          status: StatusResolved,
          unset: ['deleteTS']
        })
      }
      this.inProgressVerb = 'recover'
      this.$refs.caseSubmissionForm.doSubmit(f, 'Recover')
    },
    executeDeletion () {
      if (!this.caseData) {
        return
      }
      if (!this.isScheduledDelete) {
        this.$refs.caseSubmissionForm.deleteElement()
      } else {
        const f = async () => {
          return api.updateMaintCaseById(this.caseData.id, {
            status: StatusDeleted,
            deleteTS: Math.floor(Date.now() / 1000) + (86400 * 7)
          })
        }
        this.inProgressVerb = 'delete'
        this.$refs.caseSubmissionForm.doSubmit(f, 'Delete')
      }
    },
    backToEditor () {
      this.isDeleting = false
      this.isScheduledDelete = false
      this.isRetrying = false
      this.$refs.caseSubmissionForm.backToForm()
    },
    apiResponse(verb, resp, errorData = undefined) {
      if (!errorData) {
        this.isRetrying = false
        this.isDeleting = false
        this.isScheduledDelete = false
        if (verb == 'create' || verb == 'update' || verb == 'delete') {
          this.isClosing = true
          this.close()
        }
        if (verb == 'recover') {
          this.$emit('requestCaseDataRefresh', this.caseData.id)
          this.$refs.caseSubmissionForm.reset()
        }
        if (verb == 'reopen') {
          this.$emit('requestCaseDataRefresh', this.caseData.id)
          this.$refs.caseSubmissionForm.reset()
        }
        return
      }
      this.isRetrying = true
    },
    searchItemSelected (item) {
      if (!item || !item.customer || !item.site) {
        return
      }
      this.formData.customerID = item.customer
      this.formData.siteID = item.site
    },
    submit () {
      this.$refs.caseSubmissionForm.submit()
    },
    setCanSubmit (t) {
      this.canSubmit = t
    },
    formDataChanged(data) {
      if (data.elementID === null) {
        delete data.elementID
      }
      if (data.elementID && data.elementID.length === 16) {
        data.elementID = data.elementID.toUpperCase()
      }
      if (data.status === 'resolved' && (!('endTS' in data) || !data.endTS)) {
        data.endTS = Math.floor(Date.now() / 1000)
      }
      this.formData = data
    },
    defaultFormData( ) {
      if (this.caseData) {
        const out = { ...this.caseData }
        delete out.createdAt
        delete out.createdBy
        delete out.customerID
        delete out.siteID
        delete out.lastModifiedBy
        delete out.lastModified
        delete out.elementType
        delete out.elementID

        if (!out.viewingTab) {
          out.viewingTab = 'caseProps'
        }
        return out
      }
      const out = {
        status: StatusOpen,
        assignee: this.$state.email,
        startTS: Math.floor(Date.now() / 1000),
        viewingTab: 'caseProps'
      }
      return out
    },
    reset () {
      this.formData = this.defaultFormData()
      this.isRetrying = false
      this.canSubmit = false
      this.isLoading = false
      this.isClosing = false
      this.isDeleting = false
      this.isScheduledDelete = false
      this.inProgressVerb = undefined
    },
    setEditPath () {
      let path = this.$route.href
      let st = path.indexOf('/maint')
      if (st > 0) {
        path = path.substring(0, st + 6)
      }
      this.oldRoute = path
      if (this.caseData && this.caseData.id) {
        history.pushState(
          {},
          null,
          `${path}/edit/${this.caseData.id}`
        )
      }
    },
    refreshSubmittable () {
      if (!this.isBlocking || !this.$refs.caseSubmissionForm) {
        return
      }
      this.canSubmit = this.$refs.caseSubmissionForm.isSubmittable()
    },
    show () {
      this.reset()
      this.isBlocking = true
      if (this.caseData) {
        this.setEditPath()
      }
      this.$nextTick(() => {
        this.refreshSubmittable()
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      if (this.oldRoute) {
        history.pushState(
          {},
          null,
          this.oldRoute
        )
      }
    }
  },
  components: { Modal, BasicButton, SubmissionForm }
}
</script>
<style scoped>
  .modal-add-update-case-pane {
    min-height: 430px;
    margin: 20px 15px;
    text-align: center;
  }
</style>
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
//
// Home
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
// Admin
import AdminHome from '@/views/admin/AdminHome.vue'
import AppUserAccess from '@/views/admin/AppUserAccess.vue'
import StaffAccess from '@/views/admin/StaffAccess.vue'
import StaffRoles from '@/views/admin/StaffRoles.vue'
//
// Fleet
import FleetHome from '@/views/fleet/FleetHome.vue'
// Fleet - Customers
import CustomersList from '@/views/fleet/CustomersList.vue'
import CustomerDetails from '@/views/fleet/CustomerDetails.vue'
// Fleet - Sites
import SitesList from '@/views/fleet/SitesList.vue'
import SiteDetails from '@/views/fleet/SiteDetails.vue'
// Fleet - Devices
import DeviceList from '@/views/fleet/DeviceList.vue'
import DeviceDetails from '@/views/fleet/DeviceDetails.vue'
// Fleet - Gateways
import GatewaysList from '@/views/fleet/GatewaysList.vue'
import GatewayDetails from '@/views/fleet/GatewayDetails.vue'
import AddGateway from '@/views/fleet/AddGateway.vue'
// Fleet - Misc
import FleetMisc from '@/views/fleet/Misc.vue'
// Fleet - Network Servers
import NetworkServersList from '@/views/fleet/NetworkServersList.vue'
import NetworkServerDetails from '@/views/fleet/NetworkServerDetails.vue'
import AddNetworkServer from '@/views/fleet/AddNetworkServer.vue'
// Fleet - Manufacturers
import ManufacturersList from '@/views/fleet/ManufacturersList.vue'
import ManufacturerDetails from '@/views/fleet/ManufacturerDetails.vue'
import AddManufacturer from '@/views/fleet/AddManufacturer.vue'
// Fleet - Chambers
import ChamberConfigsList from '@/views/fleet/ChamberConfigs.vue'
// Fleet - Firmware
import FirmwareList from '@/views/fleet/FirmwareList.vue'
// Fleet - Products, Crops & Varietals
import ProductsList from '@/views/fleet/ProductsList.vue'
// Fleet - Taz
import TazAdmin from '@/views/fleet/TazAdmin.vue'
// Fleet - Maintenance
import MaintenanceMain from '@/components/fleet/Maintenance/Main.vue'
//
// Notifications
import NotificationsHome from '@/views/notifications/NotificationsHome.vue'
// Notifications - Alerts
import AlertDetails from '@/views/notifications/AlertDetails.vue'
// Notifications - Thresholds
import ThresholdsList from '@/views/notifications/ThresholdsList.vue'
import ThresholdDetails from '@/views/notifications/ThresholdDetails.vue'
import ADSList from '@/views/notifications/ADSList.vue'

// Notifications - Templates
import TemplatesList from '@/views/notifications/TemplatesList.vue'
import TemplateDetails from '@/views/notifications/TemplateDetails.vue'
// Notifications - Subscribers
import SubscribersList from '@/views/notifications/SubscribersList.vue'
import ApnSubscriptionDetails from '@/views/notifications/ApnSubscriptionDetails.vue'
// Notifications - Misc
import NotificationsMisc from '@/views/notifications/Misc.vue'
import ExclusionZones from '@/views/notifications/ExclusionZones.vue'
//
// Tools
import ToolsHome from '@/views/tools/ToolsHome.vue'
//
// Modeling
import ModelingHome from '@/views/modeling/ModelingHome.vue'
import ModelingJobInfo from '@/views/modeling/ModelingJobInfo.vue'
import ModelingScriptInfo from '@/views/modeling/ModelingScriptInfo.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sites',
    name: 'site-list',
    component: SitesList
  },
  {
    path: '/fleet',
    name: 'fleet-home',
    alias: '/fleet-map',
    component: FleetHome
  },
  {
    path: '/fleet/misc',
    name: 'fleet-misc',
    component: FleetMisc
  },
  {
    path: '/maint',
    name: 'maint-main',
    component: MaintenanceMain
  },
  {
    path: '/maint/edit/:caseId',
    name: 'maint-case-editor',
    component: MaintenanceMain
  },
  {
    path: '/maint/case/:caseId',
    name: 'maint-case-viewer',
    component: MaintenanceMain
  },
  {
    path: '/fleet/taz',
    name: 'fleet-taz',
    component: TazAdmin
  },
  {
    path: '/customers',
    name: 'customer-list',
    component: CustomersList
  },
  {
    path: '/customers/:id',
    name: 'customer-details',
    component: CustomerDetails
  },
  {
    path: '/sites/:id',
    name: 'site-details',
    component: SiteDetails
  },
  {
    path: '/sites/:id/node/:nodeID',
    name: 'site-details-node',
    component: SiteDetails
  },
  {
    path: '/sites/:id/file/:fileId/view',
    name: 'site-details-file-viewer',
    component: SiteDetails
  },
  {
    path: '/sites/:id/file/:fileId/edit',
    name: 'site-details-file-editor',
    component: SiteDetails
  },
  {
    path: '/devices',
    name: 'device-list',
    component: DeviceList
  },
  {
    path: '/devices/:id',
    name: 'device-details',
    component: DeviceDetails
  },
  {
    path: '/networks',
    name: 'network-list',
    component: NetworkServersList
  },
  {
    path: '/networks/add',
    name: 'network-add',
    component: AddNetworkServer
  },
  {
    path: '/networks/:id',
    name: 'network-details',
    component: NetworkServerDetails
  },
  {
    path: '/manufacturers',
    name: 'manufacturer-list',
    component: ManufacturersList
  },
  {
    path: '/manufacturers/add',
    name: 'manufacturer-add',
    component: AddManufacturer
  },
  {
    path: '/manufacturers/:id',
    name: 'manufacturer-details',
    component: ManufacturerDetails
  },
  {
    path: '/chambers',
    name: 'chamber-configs-list',
    component: ChamberConfigsList
  },
  {
    path: '/firmware',
    name: 'firmware-list',
    component: FirmwareList
  },
  {
    path: '/products',
    name: 'products-list',
    component: ProductsList
  },
  {
    path: '/gateways',
    name: 'gateway-list',
    component: GatewaysList
  },
  {
    path: '/gateways/add',
    name: 'gateway-add',
    component: AddGateway
  },
  {
    path: '/gateways/:id',
    name: 'gateway-details',
    component: GatewayDetails
  },
  {
    path: '/notifications',
    name: 'notifications-home',
    alias: '/alerts',
    component: NotificationsHome
  },
  {
    path: '/ads',
    name: 'ads-list',
    component: ADSList
  },
  {
    path: '/ads/alerts',
    name: 'ads-list-alerts',
    component: ADSList
  },
  {
    path: '/ads/alerts/:alertId',
    name: 'ads-show-alerts',
    component: ADSList
  },
  {
    path: '/ads/thresholds',
    name: 'ads-list-thresholds',
    component: ADSList
  },
  {
    path: '/ads/thresholds/:thresholdId',
    name: 'ads-show-thresholds',
    component: ADSList
  },
  {
    path: '/thresholds',
    name: 'threshold-list',
    component: ThresholdsList
  },
  {
    path: '/thresholds/:id',
    name: 'threshold-details',
    component: ThresholdDetails
  },
  {
    path: '/templates',
    name: 'template-list',
    component: TemplatesList
  },
  {
    path: '/templates/:id',
    name: 'template-details',
    component: TemplateDetails
  },
  {
    path: '/alerts/:id',
    name: 'alert-details',
    component: AlertDetails
  },
  {
    path: '/subscriptions',
    name: 'subscription-list',
    component: SubscribersList
  },
  {
    path: '/subscriptions/apn/:deviceToken/:bundleID/:site',
    name: 'subscription-details',
    component: ApnSubscriptionDetails
  },
  {
    path: '/notifications/misc',
    name: 'notifications-misc',
    component: NotificationsMisc
  },
  {
    path: '/exclusion-zones',
    name: 'exclusion-zones',
    component: ExclusionZones
  },
  {
    path: '/admin',
    name: 'admin-home',
    component: AdminHome
  },
  {
    path: '/admin/app-user-access',
    name: 'admin-app-user-access',
    component: AppUserAccess
  },
  {
    path: '/admin/app-user-access/email/:email',
    name: 'admin-app-user-access-email',
    component: AppUserAccess
  },
  {
    path: '/admin/staff-access',
    name: 'admin-staff-access',
    component: StaffAccess
  },
  {
    path: '/admin/staff-access/email/:email',
    name: 'admin-staff-access-email',
    component: StaffAccess
  },
  {
    path: '/admin/app-user-access/customer/:customer',
    name: 'admin-app-user-access-customer',
    component: AppUserAccess
  },
  {
    path: '/admin/staff-roles',
    name: 'admin-staff-roles',
    component: StaffRoles
  },
  {
    path: '/admin/staff-roles/:roleID',
    name: 'admin-staff-roles-id',
    component: StaffRoles
  },
  {
    path: '/tools',
    name: 'tools-home',
    component: ToolsHome
  },
  {
    path: '/modeling',
    name: 'modeling-home',
    component: ModelingHome
  },
  {
    path: '/modeling/jobs/:id',
    name: 'modeling-jobinfo',
    component: ModelingJobInfo
  },
  {
    path: '/modeling/jobs/:id/run/:runID',
    name: 'modeling-jobinfo-run',
    component: ModelingJobInfo
  },
  {
    path: '/modeling/scripts/:id',
    name: 'modeling-scriptinfo',
    component: ModelingScriptInfo
  },
  {
    path: '/modeling/scripts/:id/run/:runID',
    name: 'modeling-scriptinfo-run',
    component: ModelingScriptInfo
  },
  {
    path: '/modeling/scripts/:id/version/:versionID',
    name: 'modeling-scriptinfo-version',
    component: ModelingScriptInfo
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

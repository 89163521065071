export interface filter {
    assigneeMode: string
    assigneeEmail?: string
    statusMode: string
    elementType: string
    elementId?: string
}

const lsKeyAssignee = 'maint.filter.assignee'
const lsKeyStatus = 'maint.filter.status'
const lsKeyElementType = 'maint.filter.elementType'
const lsKeyElementId = 'maint.filter.elementId'

export const Customer = 'customer'
export const Site = 'site'
export const Gateway = 'gateway'
export const Device = 'device'

export const All = 'all'
export const Other = 'other'
export const Self = 'self'

export const Open = 'open'
export const Resolved = 'resolved'
export const Deleted = 'deleted'

export const defaultFilter = (): filter => {
  return {
    assigneeMode: Self,
    statusMode: Open,
    elementType: All
  } as filter
}

export const loadFilter = (queryParams: any, user: string): filter => {
  const out = defaultFilter()
  if (!queryParams) {
    queryParams = {}
  }
  const validParams = ['s', 'a', 't', 'i'] as string[]
  let canUseLS = true
  for (const p of validParams) {
    if (queryParams[p]) {
      canUseLS = false
      break
    }
  }
  out.statusMode = queryParams.s || (canUseLS ? localStorage.getItem(lsKeyStatus) || Open : Open)
  let assignee = queryParams.a || (canUseLS ? localStorage.getItem(lsKeyAssignee) || Self : Self)
  const elType = queryParams.t || (canUseLS ? localStorage.getItem(lsKeyElementType) || All : All)
  const elId = queryParams.i || (canUseLS ? localStorage.getItem(lsKeyElementId) || undefined : undefined)
  if (assignee === user) {
    assignee = Self
  }

  if ([Self, All].includes(assignee)) {
    out.assigneeMode = assignee
  } else if (assignee && assignee.includes('@')) {
    out.assigneeMode = Other
    out.assigneeEmail = assignee
  }
  if (elType !== All && elId) {
    out.elementType = elType
    out.elementId = elId
  }
  return out
}

export const saveFilter = (f: filter) => {
  if (!f) {
    localStorage.removeItem(lsKeyAssignee)
    localStorage.removeItem(lsKeyStatus)
    localStorage.removeItem(lsKeyElementType)
    localStorage.removeItem(lsKeyElementId)
    return
  }
  if (f.assigneeMode === Other && f.assigneeEmail) {
    localStorage.setItem(lsKeyAssignee, f.assigneeEmail)
  } else if ([All, Self].includes(f.assigneeMode)) {
    localStorage.setItem(lsKeyAssignee, f.assigneeMode)
  } else {
    localStorage.removeItem(lsKeyAssignee)
  }
  if (f.statusMode) {
    localStorage.setItem(lsKeyStatus, f.statusMode)
  } else {
    localStorage.removeItem(lsKeyStatus)
  }
  if ([Customer, Site, Gateway, Device].includes(f.elementType) &&
    f.elementId) {
    localStorage.setItem(lsKeyElementType, f.elementType)
    localStorage.setItem(lsKeyElementId, f.elementId)
  } else {
    localStorage.removeItem(lsKeyElementType)
    localStorage.removeItem(lsKeyElementId)
  }
}
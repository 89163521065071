<template>
  <div class="settings-menu-mask" v-show="isVisible" @hide="hide()" :style="'left: ' + left.toString() + 'px;'">
    <div class="settings-menu settings-menu">
      <ul class="mt-2 mb-0">
        <li class="add-border-bottom">
          <label class="menu-icon-width" style="width:21px;"></label>
          <span class="subtle">Quick Settings</span>
        </li>
        <li class="py-2 add-border-bottom selectable" @click="toggleInactiveDevices()">
          <label class="menu-icon-width">
            <fa v-if="$state.showInactiveNodes" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show Inactive Elements
        </li>
        <li class="pt-2 selectable" @click="setTheme('darkMode')">
          <label class="menu-icon-width">
            <fa v-if="theme === 'darkMode'" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Use Dark Mode Theme
        </li>
        <li class="selectable" @click="setTheme('lightMode')">
          <label class="menu-icon-width">
            <fa v-if="theme === 'lightMode'" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Use Light Mode Theme
        </li>
        <li class="pb-2 add-border-bottom selectable" @click="setTheme('highContrastMode')">
          <label class="menu-icon-width">
            <fa v-if="theme === 'highContrastMode'" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Use High-Contrast Theme
        </li>
        <li class="pt-2 selectable" @click="updateRefreshInterval(0)">
          <label class="menu-icon-width">
            <fa v-if="$state.refreshInterval === 0" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Disable Auto-Refresh
        </li>
        <li class="selectable" @click="updateRefreshInterval(30000)">
          <label class="menu-icon-width">
            <fa v-if="$state.refreshInterval === 30000" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Auto-Refresh Every 30s
        </li>
        <li class="selectable" @click="updateRefreshInterval(60000)">
          <label class="menu-icon-width">
            <fa v-if="$state.refreshInterval === 60000" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Auto-Refresh Every 1m
        </li>
        <li class="add-border-bottom pb-2 selectable" @click="updateRefreshInterval(180000)">
          <label class="menu-icon-width">
            <fa v-if="$state.refreshInterval === 180000" icon="check" alt="Showing Inactive Devices" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Auto-Refresh Every 3m
        </li>
        <li class="py-2 selectable" @click="showPrefsPane()">
          <label class="menu-icon-width">
          </label>
          Show Full Settings Panel ...
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SettingsMenu',
  emits: ['settingsMenuVisible', 'settingsMenuHidden', 'showPrefsPane'],
  data () {
    return {
      isVisible: false,
      isLooping: true,
      iteration: 0,
      left: 5,
      theme: 'darkMode'
    }
  },
  methods: {
    showPrefsPane () {
      this.$emit('showPrefsPane')
    },
    updateRefreshInterval (interval) {
      localStorage.setItem('prefs.refreshInterval', interval)
      this.$state.refreshInterval = interval
    },
    toggleInactiveDevices () {
      if (this.$state.showInactiveNodes) {
        localStorage.removeItem('prefs.show_inactive')
        this.$state.showInactiveNodes = false
      } else {
        localStorage.setItem('prefs.show_inactive', 1)
        this.$state.showInactiveNodes = true
      }
    },
    setTheme (theme) {
      document.documentElement.setAttribute('data-theme', theme)
      localStorage.setItem('prefs.theme', theme)
    },
    settingsMenuPositionLoop () {
      if (!this.isVisible || !this.isLooping) {
        return
      }
      const icon = document.getElementById('settingsIcon')
      if (icon) {
        const rect = icon.getBoundingClientRect()
        const l = Math.floor(rect.x - 240)
        if (l > 5) {
          this.left = l
        } else {
          this.left = 5
        }
      } else {
        this.left = 5
      }
      if (this.iteration < 5) {
        this.iteration++
        if (this.iteration === 2) {
          document.addEventListener('click', this.hide)
        }
      }
      setTimeout(this.settingsMenuPositionLoop, 150)
    },
    isSettingsMenuVisible () {
      return this.isVisible
    },
    toggle () {
      if (this.isVisible) {
        this.hide()
      } else {
        this.show()
      }
    },
    reset () {
      let ri = false
      if (localStorage.getItem('prefs.refreshInterval')) {
        ri = true
        this.$state.refreshInterval = localStorage.getItem('prefs.refreshInterval') * 1
      }
      if (!('refreshInterval' in this.$state)) {
        ri = true
        this.$state.refreshInterval = 0 * 1
        localStorage.setItem('prefs.refreshInterval', this.$state.refreshInterval)
      }
      if (!ri) {
        this.$state.refreshInterval = 0
      }
      if (localStorage.getItem('prefs.show_inactive') === '1') {
        this.$state.showInactiveNodes = true
      }
      if (localStorage.getItem('prefs.theme')) {
        this.theme = localStorage.getItem('prefs.theme')
      }
    },
    show () {
      this.reset()
      this.isVisible = true
      this.iteration = 0
      this.$nextTick(() => {
        this.$emit('settingsMenuVisible')
        this.settingsMenuPositionLoop()
      })
    },
    hide () {
      this.$emit('settingsMenuHidden')
      this.isVisible = false
      document.removeEventListener('click', this.hide)
    }
  },
  unmounted () {
    this.isLooping = false
  }
}
</script>
<style scoped>
.settings-menu-mask {
  transition: opacity 0.25s ease;
  z-index: 23;
  position: fixed;
  top: 38px;
  width: 265px;
}

.settings-menu {
  background-color: var(--menu-bg-color);
  box-shadow: 0 0 25px 15px var(--modal-shadow);
  border-radius: 6px;
  border: 1px var(--form-control-border-color) solid;
  transition: all 0.25s ease;
  font-size: var(--font-enlarge-2)
}

.settings-menu ul {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-left: 0px;
}

.settings-menu hr {
  margin-top: 2px;
  margin-bottom: 2px;
}

.settings-menu ul li {
  position: relative;
  padding: 5px 16px 5px 12px;
  cursor: pointer;
  list-style: none;
  margin-left: 0px;
  font-size: var(--font-reduce-2);
}
/* 
.settings-menu ul li {
    position: relative;
    padding: 5px 16px 5px 4px;
    cursor: pointer;
    list-style: none;
    margin-left: 0px;
    font-size: var(--font-reduce-2);
} */

.settings-menu ul li.selectable:hover {
  background-color: var(--menu-hover-bg-color);
}

</style>
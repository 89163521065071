
export const FilterIconId = 'filter-icon-id'

export const StatusOpen = 'open'
export const StatusResolved = 'resolved'
export const StatusLocked = 'locked'
export const StatusDeleted = 'deleted'

export const FailModeDamage = 'physicalDamage'
export const FailModeEnviron = 'environmentalDamage'
export const FailModeMBoard = 'sensorBoard'
export const FailModeMSoilProbe = 'soilProbe'
export const FailModeBattery = 'batteryDied'
export const FailModeOther = 'other'
export const FailModeUnknown = 'unknown'

export const PrioirtyLow = 'low'
export const PrioirtyMedium = 'medium'
export const PrioirtyHigh = 'high'

export const ElTypeDevice = 'device'
export const ElTypeGateway = 'gateway'

export const allStatuses = (): any => {
    return {
        StatusOpen: 'Open',
        StatusResolved: 'Resolved',
        StatusLocked: 'Locked',
        StatusDeleted: 'Deleted',
    }
}

export const selectableStatuses = (): any => {
    return {
        StatusOpen: 'Open',
        StatusResolved: 'Resolved'
    }
}

export const statusOptions = (): any[] => {
    return [
        {
            id: StatusOpen,
            displayName: 'Open',
        },
        {
            id: StatusResolved,
            displayName: 'Resolved',
        },
    ]
}

export const failModeOptions = (): any[] => {
    return [
        {
            id: FailModeDamage,
            displayName: 'Physical Damage',
        },
        {
            id: FailModeEnviron,
            displayName: 'Environmental Damage',
        },
        {
            id: FailModeMBoard,
            displayName: 'Sensor Board Failure',
        },
        {
            id: FailModeMSoilProbe,
            displayName: 'Soil Probe Failure',
        },
        {
            id: FailModeBattery,
            displayName: 'Batteries Died',
        },
        {
            id: FailModeOther,
            displayName: 'Other (See Notes)',
        },
        {
            id: FailModeUnknown,
            displayName: 'Unknown Failure',
        },
    ]
}

export const priorityOptions = (): any[] => {
    return [
        {
            id: PrioirtyLow,
            displayName: 'Low',
        },
        {
            id: PrioirtyMedium,
            displayName: 'Medium',
        },
        {
            id: PrioirtyHigh,
            displayName: 'High',
        },
    ]
}

export const elementTypeOptions = (): any[] => {
    return [
        {
            id: ElTypeDevice,
            displayName: 'Device',
        },
        {
            id: ElTypeGateway,
            displayName: 'Gateway',
        },
    ]
}

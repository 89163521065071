<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm" :showSpinner="isLoading">
      <template v-slot:header>
        <span class="font-reduce-1">{{ elementVerb }} {{ elementTypeShort }} {{ elementId || ''}}</span>
      </template>
      <template v-slot:body>
        <div class="element-install-wizard-choose-pane" v-if="mode === 'chooseInstallOrUninstall'">
          <span>This {{ elementType }} is Temporarily Uninstalled at {{ siteName }}</span>
          <br />
          <br />
          <br />
          <br />
          <br />
          <BasicButton caption="Re-install at this Site" :action="() => { mode = 'form'}" />
          <br />
          <br />
          <br />
          <BasicButton caption="Completely Uninstall" :action="() => { formData.uninstallType = 'uninstall' ; initialAction = 'uninstall'; mode = 'form' }" />
        </div>
        <div class="element-install-wizard-status-pane" v-if="mode === 'resultSuccess'">
          {{ elementVerb }} Completed
          <div style="margin-top: 40px;">
            <fa icon="check" style="max-width: 100px" class="status-ok" />
          </div>
        </div>
        <div class="element-install-wizard-status-pane" v-if="mode === 'resultError'">
          <span class="status-error font-enlarge-1">{{ elementVerb }} Failed</span>
          <br />
          <div style="font-weight: 400; font-size: .9em; padding: 26px 8px; line-height: 1.75em">
          Unfortunately the {{ elementVerb }} did not succeed.<br />
          Press Retry below to give it another go.
          </div>
          <hr v-if="failureText" />
          <div class="font-reduce-2" style="text-align: left; font-weight: 400; font-size: .9em; padding: 20px 10px 0px 20px;" v-if="failureText">
            {{ failureText }}
          </div>
        </div>
        <div class="element-install-wizard-body" v-else-if="mode === 'form'">
          <!-- Failed Validation -->
          <template v-if="!isValidForInstall && !isUninstall">
            <div class="element-install-wizard-bigicon">
              <fa class="status-danger" icon="exclamation-triangle" />
            </div>
            <div class="element-install-wizard-copy ms-3 me-1">
              This {{ elementType }} can't be installed until the following issues are corrected:
              <ul style="margin: 20px 0px 30px 0px;">
                <li v-for="(issue, i) of validationDetails" :key="i">
                  {{ issue }}
                </li>
              </ul>
            </div>
          </template>
          <!-- Succeeded Validation -->
          <template v-else-if="isValidForInstall || isUninstall">
            <div class="element-install-wizard-copy">
              <SubmissionForm
                :fields1="formFields"
                @setFormData="formDataChanged"
                @setCanSubmit="setIsFormReadyToSubmit"
                :formData="formData"
              />
            </div>
          </template>
          <template v-if="!isUninstall && isValidForInstall">
            <div  class="element-install-wizard-reminder" style="white-space: normal;">
              <div style="text-align: center" class="my-2 status-caution">
              </div>
              <fa icon="triangle-exclamation" class="status-caution me-1" style="height: 22px; opacity: .8;" />
              Don't forget to take photos of the install and upload them to
              the Site's File Library as soon as network connectivity allows.
              <div v-if="node && nodeFixes && nodeFixes.length > 0" style="margin-top: 10px;">
                <hr />
                Please set these properies on Node {{ node.displayName }}: {{ nodeFixes }}
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:footer>
        <BasicButton color="red" :caption="buttonCaption" :action="buttonSubmitted" :disabled="!canSubmit" />
        <BasicButton class="ms-3" color="blue" caption="Close" :action="close" />
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import Modal from '@/components/modals/Modal.vue'
import SubmissionForm from '@/components/forms/SubmissionForm.vue'
import BasicButton from '@/components/controls/BasicButton.vue'
import { install, uninstall, tempUninstall, validateDeviceForInstall, validateGatewayForInstall } from '@/components/fleet/DeviceInstallations/validation.ts'
import { HoldingSites } from '@/app/data/holdingSites.ts'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'modal-element-install-wizard',
  components: { Modal, BasicButton, SubmissionForm },
  props: ['device', 'site', 'node', 'customer', 'action', 'gateway'],
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: this.defaultFormData(this.isUninstall),
      mode: 'form',
      failureText: '',
      initialAction: this.action
    }
  },
  computed: {
    siteName () {
      if (this.site && this.site.displayName) {
        return this.site.displayName
      }
      return 'Unknown Site'
    },
    nodeFixes () {
      if (!this.node) {
        return undefined
      }
      const out = []
      if (!this.node.role) {
        out.push('Role')
      }
      if (!this.node.layout) {
        out.push('Layout')
      }
      if (!this.node.lat || !this.node.long) {
        outpush('GPS Coordinates')
      }
      return out.join(',')
    },
    elementVerb () {
      return this.isUninstall ? 'Uninstall' : 'Install'
    },
    elementId () {
      return this.device ? this.device.id : this.gateway ? this.gateway.id : undefined
    },
    elementTypeShort () {
      return this.device ? 'Device' : this.gateway ? 'GW' : ''
    },
    elementType () {
      return this.device ? 'Device' : this.gateway ? 'Gateway' : 'Element'
    },
    buttonCaption () {
      if (this.mode === 'resultError') {
        return 'Retry '
      }
      return `${this.elementVerb} This ${this.elementType}`
    },
    holdingSiteOptions () {
      const out = []
      for (const k in HoldingSites) {
        out.push({
          id: HoldingSites[k],
          value: HoldingSites[k],
          displayName: k
        })
      }
      return out
    },
    submissionData () {
      const out = {}
      if (!this.formData) {
        return out
      }
      if (this.customer) {
        out.customerID = this.customer
      }
      if (this.formData.installedBy) {
        out.installedBy = this.formData.installedBy
      }
      if (this.formData.installationDate) {
        out.installationDate = this.formData.installationDate
      }
      if (this.formData.notes) {
        out.notes = this.formData.notes
      }
      out.elementType = this.elementType.toLowerCase()
      if (this.elementId) {
        out.elementID = this.elementId
      }
      if (this.device) {
        if (this.device.site) {
          out.siteID = this.device.site
        }
        if (this.device.position) {
          out.position = this.device.position
        }
        if (this.device.node) {
          out.nodeID = this.device.node
        }
      } else if (this.gateway) {
        if (this.gateway.site) {
          out.siteID = this.gateway.site
        }
      }
      if (!this.isUninstall) {
        out.action = install
      } else {
        if (this.isTempUninstall) {
          out.action = tempUninstall
        } else {
          out.action = uninstall
          if (this.formData.holdingSiteID) {
            out.holdingSiteID = this.formData.holdingSiteID
          }
        }
      }
      return out
    },
    uninstallTypeText () {
      if (this.initialAction !== uninstall || !this.formData.uninstallType) {
        return undefined
      }
      if (this.formData.uninstallType === tempUninstall) {
        return `Temporary Uninstall means the ${this.elementType} is still located on-site and may be re-installed at this site in the future.`
      }
      if (this.formData.uninstallType === uninstall) {
        return `Full Uninstall means the ${this.elementType} is completely removed from the Site and in is Agrology's possession to repair, redeploy or retire.`
      }
        return undefined
    },
    isUninstall () {
      return this.initialAction === uninstall
    },
    isTempUninstall () {
      return this.isUninstall && this.formData && this.formData.uninstallType === tempUninstall
    },
    formFields () {
      return [
        {
          controlType: 'pillboxInput',
          hidden: !this.isUninstall,
          pills: [{label: 'Temporary Uninstall', value: tempUninstall} , {label: 'Full Uninstall', value: uninstall}],
          field: 'uninstallType',
          labelClass: 'width-125 font-reduce-1',
          inputClass: 'width-234 font-reduce-1',
          disabled: this.isLoading
        },
        {
          controlType: 'textContent',
          hidden: !this.isUninstall || !this.uninstallTypeText,
          text: this.uninstallTypeText,
          textClass: 'pb-2 mb-2 ps-4 pe-2'
        },
        {
          controlType: 'textContent',
          hidden: !this.isUninstall || this.elementType === 'Gateway',
          text: `Monitoring will be disabled for this ${this.elementType}, but can be re-enabled in the ${this.elementType} Editor.`,
          textClass: 'pb-3 mb-3 ps-4 pe-2',
          borderBottom: true
        },
        {
          controlType: 'textContent',
          hidden: this.isUninstall || this.elementType === 'Gateway',
          text: `Monitoring will be enabled for this ${this.elementType}, but can be disabled later in the ${this.elementType} Editor.`,
          textClass: 'pb-3 mb-2 ps-4 pe-2',
          borderBottom: true
        },
        {
          hidden: !this.site || !this.site.displayName,
          controlType: 'textLabelRow',
          label: 'Site',
          text: this.site && this.site.displayName ? this.site.displayName : '',
          labelClass: 'width-125 font-reduce-1',
        },
        {
          hidden: !this.device || !this.device.node || !this.node || !this.node.displayName,
          controlType: 'textLabelRow',
          label: 'Node',
          text: this.node && this.node.displayName ? this.node.displayName : undefined,
          labelClass: 'width-125 font-reduce-1',
        },
        {
          hidden: !this.device || !this.device.position,
          controlType: 'textLabelRow',
          label: 'Position',
          text: this.device && this.device.position ? this.device.position : undefined,
          labelClass: 'width-125 font-reduce-1',
        },
        {
          hidden: !this.device && !this.node && !this.site,
          controlType: 'hr',
        },
        {
          field: 'installedBy',
          label: this.isUninstall ? 'Uninstaller' : 'Installer',
          controlType: 'textInput',
          required: true,
          labelClass: 'width-125 font-reduce-1',
          inputClass: 'width-234 font-reduce-1',
          disabled: this.isLoading
        },
        {
          field: 'installationDate',
          label:this.isUninstall ? 'Uninstall Date' : 'Install Date',
          controlType: 'simpleDateTime',
          required: true,
          labelClass: 'width-125 font-reduce-1',
          disabled: this.isLoading,
          hideTime: true,
          showPlaceholderBelow: true
        },
        {
          controlType: 'selectInput',
          hidden: !this.isUninstall || this.isTempUninstall,
          field: 'holdingSiteID',
          label: 'Holding Site',
          options: this.holdingSiteOptions,
          required: this.isUninstall && !this.isTempUninstall,
          labelClass: 'width-125 font-reduce-1',
          inputClass: 'width-234 font-reduce-1',
          disabled: this.isLoading        },
        {
          controlType: 'textarea',
          field: 'notes',
          label: 'Notes',
          labelClass: 'width-65 font-reduce-1',
          inputClass: 'width-295 height-75 font-reduce-1',
          disabled: this.isLoading,
          wrapperClass: 'mt-3'
        }
      ]
    },
    canSubmit () {
      if (this.isLoading) {
        return false
      }
      if (this.mode === 'resultSuccess' || this.mode === 'chooseInstallOrUninstall') {
        return false
      }
      if (!this.isUninstall && !this.isValidForInstall) {
        return false
      }
      if (![install, tempUninstall, uninstall].includes(this.submissionData.action)) {
        return false
      }
      const out = this.submissionData && this.submissionData.installationDate &&
        this.submissionData.installedBy
      if (!out) {
        return false
      }
      if ([tempUninstall, uninstall].includes(this.submissionData.action)) {
        if (this.submissionData.action === uninstall) {
          return out && this.submissionData.holdingSiteID
        }
        return out
      }
      return out && this.submissionData.customerID && this.submissionData.siteID &&
        this.submissionData.elementID && ((this.submissionData.nodeID &&
        this.submissionData.position && this.submissionData.elementType === 'device') || 
        this.submissionData.elementType === 'gateway')
    },
    validationData () {
      if (this.gateway) {
        return validateGatewayForInstall(this.gateway, this.customer)
      }
        return validateDeviceForInstall(this.device, this.customer)
    },
    isValidForInstall () {
      if (this.validationData && this.validationData.isValid) {
        return true
      }
      return false
    },
    validationDetails () {
      if (!this.validationData || !this.validationData.details || this.validationData.details.length === 0) {
        return []
      }
      return this.validationData.details
    }
  },
  methods: {
    timedClose () {
      Reloadables.requestReload()
      setTimeout(this.close, 1500)
    },
    buttonSubmitted() {
      this.isLoading = true
      FleetDataService.createInstallationRecord(this.submissionData)
      .then(() => {
        this.failureText = ''
        this.mode = 'resultSuccess'
        this.timedClose()
      })
      .catch(e => {
        this.mode = 'resultError'
        this.failureText = e
        console.log("ERROR Creating Installation Record", e)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    defaultFormData(isUninstall) {
      const out = {
        installationDate: Math.trunc((Date.now() / 1000) / 86400) * 86400,
        installedBy: this.$state.email,
      }
      if (isUninstall) {
        out.uninstallType = tempUninstall
      }
      return out
    },
    formDataChanged(data) {
      this.formData = data
    },
    setIsFormReadyToSubmit (input) {},
    reset () {
      if ((this.gateway && this.gateway.installationStatus === 'tempUninstalled') ||
        (this.device && this.device.installationStatus === 'tempUninstalled')) {
        this.mode = 'chooseInstallOrUninstall'
      } else {
        this.mode = 'form'
      }
      this.isLoading = false
      this.failureText = ''
      this.initialAction = this.action
      this.formData = this.defaultFormData(this.isUninstall)
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      if (this.$refs.baseModal && this.$refs.baseModal.close) {
        this.$refs.baseModal.close
      }
      this.isBlocking = false
    },
  }
}
</script>
<style scoped>
  .element-install-wizard-body {
    max-width: 375px;
    min-height: 400px;

  }

  .element-install-wizard-status-pane {
    min-height: 400px;
    margin-top: 135px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-enlarge-3);
  }

  .element-install-wizard-bigicon {
    margin-top: 30px;
    text-align: center;
    max-height: 75px;
    height: 75px;
  }

  .element-install-wizard-bigicon svg {
    height: 75px;
  }

  .element-install-wizard-copy {
    margin-top: 10px;
    white-space: normal;
    text-align: left;
  }
  .element-install-wizard-reminder {
    padding: 5px 12px 15px 18px;
    margin: 5px 15px 20px 15px;
    border: 1.5px var(--app-caution-color) solid;
    border-radius: 10px;
    font-size: var(--font-reduce-1);
    background-color: #e898000c;
  }
  .element-install-wizard-choose-pane {
    max-width: 375px;
    min-height: 400px;
    white-space: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 10px;
    padding-right: 25px;
    padding-left: 25px;
    line-height: 1.7em;
  }

  .element-install-wizard-choose-pane span {
    font-size: 18px;
    font-weight: bold;
  }
</style>
<template>
  <div class="search-mask" v-show="isVisible" @hide="hide()" :style="'left: ' + left.toString() + 'px;'">
    <div class="search-menu">
      <ul class="search-box">
        <li class="search my-1">
          <div class="input-with-close">
            <input ref="searchBox"
              v-model="searchTerm"
              type="text" class="form-input search-input" placeholder="Device ID, Site ID, Customer, etc."
              @keypress="keyPressed" @keyup="inputKeyUp" @keydown="inputKeyDown" />
            <a v-if="searchTerm" @click="clearSearchTerm" class="normal active-link-framed">&#x2715;</a>
          </div>
        </li>
      </ul>
      <ul class="search-results">
        <li v-for="(result, i) in searchResults" :key="result.key"
          @click="navigate(result)"
          class="result" :class="{'navigation-target': i === resultsFocusIndex}"
        >
          <div class="title" :class="{'subtle': result.shouldDim }" v-if="result.type">
            <span class="element-type me-1 gold" v-if="result.highPriority">
              <fa icon="star" :class="result.shouldDim ? 'subtle' : 'gold'" style="height: 14px; width: 14px; margin-top: -3px; margin-right: 3px;" />
            </span>
            <span class="element-type me-1" :class="{'subtle': result.shouldDim }" v-if="result.classification">{{ result.classification }}</span>
            <span class="element-type me-1" :class="{'subtle': result.shouldDim }" v-else-if="result.type">{{ result.type }}</span>
            <span class="title-main" :class="{'subtle': result.shouldDim, 'font-reduce-1': result.title.length > 28 }">{{ result.title }}</span>
            <span class="result-suffix ms-2" :class="{'subtle': result.shouldDim }" v-if="result.suffix">{{ result.suffix }}</span>
          </div>
          <div class="subtitle" v-if="result.subtitle">{{ result.subtitle }}</div>
          <div v-if="i === resultsFocusIndex" class="results-selector-icon-area">
            <fa icon="turn-down" alt="Search" class="return-key-icon" />
          </div>
        </li>
      </ul>
      <ul>
        <li class="footnote">
          <div class="d-flex justify-content-between">
            <label>
              Tip: Use <span class="gray-highlight font-reduce-2">{{ ctrlOrCmd }} + K</span> to show + focus Search Box
            </label>
            <label v-if="searchResults && searchTerm">
              <span class="app-text-color bold">{{ searchResults.length }}</span>
              Results
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'SearchResults',
  emits: ['searchVisible', 'searchHidden'],
  data () {
    return {
      searchTerm: '',
      searchIndex: [],
      resultsFocusIndex: 0,
      searchURL: '',
      isVisible: false,
      isLooping: true,
      left: 5
    }
  },
  watch: {
    searchTerm: {
      handler () {
        this.resultsFocusIndex = 0
      }
    }
  },
  computed: {
    ctrlOrCmd () {
      const ua = window.navigator.userAgent.replaceAll(' ', '').toLowerCase()
      if ((ua.includes('macintosh') && ua.includes('macos')) || ua.includes('iphone') || ua.includes('ipad')) {
        return 'CMD'
      }
      return 'CTRL'
    },
    searchResults () {
      const out = []
      const dimmed = []
      if (!this.searchTerm || this.searchTerm.length < 2) {
        return out
      }
      const lc = this.searchTerm.toLowerCase().trim()
      for (const key in this.searchIndex) {
        const entry = this.searchIndex[key]
        if (entry && entry.indexData && entry.indexData.includes(lc)) {
          if (entry.shouldDim) {
            dimmed.push(entry)
          } else {
            out.push(entry)
          }
        }
      }
      return out.concat(dimmed)
    }
  },
  methods: {
    searchIndexPSUFetchLoop () {
      FleetDataService.getSearchIndexURL()
        .then(resp => {
          if (resp.data && resp.data.url) {
            this.searchURL = resp.data.url
            this.fetchSearchIndex()
          }
        })
        .catch(e => {
          console.log('failed to fetch search index url', e)
        })
        .finally(() => {
          setTimeout(this.searchIndexPSUFetchLoop, 1150000)
        })
    },
    isSearchVisible () {
      return this.isVisible
    },
    navigate (result) {
      if (result.route) {
        this.$router.push({ name: result.route, params: result.params })
        this.hide()
      }
    },
    reset () {
      this.searchTerm = ''
    },
    keyUp (e) {
      // if ctrl + / or cmd + /, toggle the search box
      if (e.keyCode === 191 && (e.metaKey || e.ctrlKey)) {
        this.toggle()
        e.preventDefault()
        return
      }
      // if ctrl + e or cmd + e, show the search box
      if (e.keyCode === 75 && (e.metaKey || e.ctrlKey)) {
        this.show()
        e.preventDefault()
        return
      }
      // if the box is not visible or keycode is not esc, abort
      if (!this.isVisible || e.keyCode !== 27) {
        return
      }
      // if the search box is populated, clear it
      if (this.searchTerm !== '') {
        this.searchTerm = ''
      } else {
      // if the search box was not populated, close the search menu
        this.hide()
      }
    },
    inputKeyDown (e) {
      if (!this.isVisible || this.searchResults.length === 0) {
        return
      }
      if ((e.keyCode === 38 && this.resultsFocusIndex !== 0) ||
        (e.keyCode === 40 && this.resultsFocusIndex < this.searchResults.length - 1)) {
        e.preventDefault()
      }
    },
    inputKeyUp (e) {
      if (!this.isVisible || this.searchResults.length === 0) {
        return
      }
      if (e.keyCode === 38) {
        if (this.resultsFocusIndex === 0) {
          return
        }
        let i = this.resultsFocusIndex - 1
        if (i < 0) {
          i = 0
        }
        this.resultsFocusIndex = i
        e.preventDefault()
        return
      }
      if (e.keyCode === 40) {
        if (this.resultsFocusIndex >= this.searchResults.length - 1) {
          return
        }
        let i = this.resultsFocusIndex + 1
        if (i >= this.searchResults.length) {
          i = this.searchResults.length - 1
        }
        this.resultsFocusIndex = i
        e.preventDefault()
      }
    },
    keyPressed (e) {
      if (!this.isVisible || this.searchResults.length === 0 || e.keyCode !== 13) {
        return
      }
      if (this.searchResults[this.resultsFocusIndex].route) {
        this.$router.push(
          {
            name: this.searchResults[this.resultsFocusIndex].route,
            params: this.searchResults[this.resultsFocusIndex].params
          }
        )
        this.hide()
      }
    },
    searchBoxPositionLoop () {
      if (!this.isVisible) {
        return
      }
      const icon = document.getElementById('settingsIcon')
      if (icon) {
        const rect = icon.getBoundingClientRect()
        const l = Math.floor(rect.x - 340)
        if (l > 5) {
          this.left = l
        } else {
          this.left = 5
        }
      } else {
        this.left = 5
      }
      setTimeout(this.searchBoxPositionLoop, 150)
    },
    searchIndexFetchLoop () {
      if (!this.searchURL) {
        this.fetchSearchIndexURL()
        if (!this.searchRUL) {
          setTimeout(this.searchIndexFetchLoop, 2000)
          return
        }
        setTimeout(this.searchIndexFetchLoop, 60000)
        return
      }
      FleetDataService.getSearchIndex(this.searchURL)
        .then(response => {
          FleetDataService.setManifest(response.data)
          this.searchIndex = FleetDataService.getManifest()
        })
        .catch(e => {
          console.log('error downloading file', e)
        })
        .finally(() => {
          if (this.isLooping) {
            setTimeout(this.searchIndexFetchLoop, 60000)
          }
        })
    },
    fetchSearchIndex () {
      FleetDataService.getSearchIndex(this.searchURL)
        .then(response => {
          FleetDataService.setManifest(response.data)
          this.searchIndex = FleetDataService.getManifest()
        })
        .catch(e => {
          console.log('error downloading file', e)
        })
    },
    fetchSearchIndexURL () {
      FleetDataService.getSearchIndexURL()
        .then(resp => {
          if (resp.data && resp.data.url) {
            this.searchURL = resp.data.url
            this.fetchSearchIndex()
          }
        })
        .catch(e => {
          console.log('failed to fetch search index url', e)
        })
    },
    clearSearchTerm () {
      this.searchTerm = ''
      this.focus()
    },
    focus () {
      this.$nextTick(() => {
        this.$refs.searchBox.focus()
        this.$refs.searchBox.select()
      })
    },
    toggle () {
      if (this.isVisible) {
        this.hide()
      } else {
        this.show()
      }
    },
    show () {
      this.$emit('searchVisible')
      // this.reset()
      this.isVisible = true
      this.searchBoxPositionLoop()
      if (!this.searchURL) {
        this.fetchSearchIndexURL()
      }
      this.focus()
    },
    hide () {
      this.$emit('searchHidden')
      this.isVisible = false
      // this.reset()
    }
  },
  mounted () {
    document.addEventListener('keydown', this.keyUp)
    this.searchIndexPSUFetchLoop()
    this.searchIndexFetchLoop()
  },
  unmounted () {
    document.removeEventListener('keydown', this.keyUp)
    this.isLooping = false
  }
}
</script>
<style scoped>

/* Search Menu */

.modal-enter .search-menu,
.modal-leave-active .search-menu {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.search-mask {
    transition: opacity 0.25s ease;
    z-index: 23;
    position: fixed;
    top: 38px;
    width: 365px;
    max-height: 300px;
}

.search-menu {
    background-color: var(--search-menu-bg-color);
    box-shadow: 0px 5px 25px 25px rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    border: 1px var(--form-control-border-color) solid;
    transition: all 0.25s ease;
}

.search-menu ul {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
}

ul.search-results {
    max-height: 515px;
    overflow-y: scroll;
    max-height: 515px;
}

.search-menu hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.search-menu ul li {
    position: relative;
    padding: 7px 0px 7px 8px;
    cursor: pointer;
    list-style: none;
    margin-left: 0px;
    font-size: var(--font-reduce-1);
    overflow-x: clip;
    white-space: nowrap;
}

.search-menu ul li.search {
    cursor: default;
    padding: 8px 8px 7px 8px;
}

.search-menu ul li.result,
.search-menu ul li.footnote {
    border-top: 1px solid var(--form-control-border-color);
}

.search-menu ul li.footnote {
    color: var(--app-subtle-color);
    font-size: var(--font-reduce-4);
    cursor: default;
    border-top: 1px solid var(--form-control-border-color);
    text-align: left;
    padding: 7px 8px 7px 8px;
}

.search-menu ul li.result:hover {
    background-color: var(--form-row-hover);
}

li.result span.result-suffix {
    color: var(--app-subtle-color);
    font-size: var(--font-reduce-1);
}

.icon-watermark {
    background-color: var(--tab-hover-background);
    border-radius: 3px;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.icon-watermark svg {
    width: 32px;
    height: 20px;
    color: var(--app-highlight-color);
    opacity: .8;
}

.search-input, .search-input:hover {
  border: none !important;
}

.results-selector-icon-area {
    background-color: var(--tab-hover-background);
    border-radius: 3px;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    right: 4px;
    height: 24px;
    top: calc(50% - 12px);
}

.results-selector-icon-area .return-key-icon {
    width: 28px;
    height: 16px;
    color: var(--app-highlight-color);
    opacity: .8;
    transform: rotate(90deg);
}

</style>
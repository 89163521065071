<template>
  <div>
    <ModalSubmissionForm
      ref="ventadd"
      elementType="Vent Type"
      :fields1="formFields"
      :createFunc="createVent"
      :isCreate="true"
    />
    <ModalSubmissionForm
      ref="ventedit"
      elementType="Vent Type"
      :formData="ventData"
      :fields1="formFields"
      :updateFunc="updateVent"
    />
    <DataTable
      class="dt-ventslist"
      title="Vent Types"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="showVentAdd"
      actionIcon="plus"
      itemType="Vent"
    />
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import ModalSubmissionForm from '@/components/forms/ModalSubmissionForm.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'vents-list',
  extends: BaseFleetComponent,
  data () {
    return {
      vents: {},
      ventData: undefined,
      cols: [
        { text: 'Manufacturer' },
        { text: 'Model' },
        { text: 'CO2 Diffusion' },
        { text: 'N2O Diffusion' }
      ],
      loading: false
    }
  },
  computed: {
    formFields () {
      return [
        {
          field: 'id',
          label: 'ID',
          controlType: 'id',
          hideLabel: true
        },
        {
          field: 'manufacturer',
          label: 'Manufacturer',
          controlType: 'textInput',
          required: true,
          dataType: 'description'
        },
        {
          field: 'model',
          label: 'Model',
          controlType: 'textInput',
          required: true,
          dataType: 'description'
        },
        {
          field: 'co2DiffusionConstant',
          label: 'CO2 Diffusion',
          controlType: 'textInput',
          required: true,
          dataType: 'decimal'
        },
        {
          field: 'n2oDiffusionConstant',
          label: 'N2O Diffusion',
          controlType: 'textInput',
          required: true,
          dataType: 'decimal'
        }
      ]
    },
    rows () {
      const out = []
      if (this.vents && this.vents.length > 0) {
        for (const vent of this.vents) {
          out.push([
            { value: vent.manufacturer, click: this.showVentEditor,
              i: vent, class: this.ventLinkColor(vent, '') },
            { value: vent.model },
            { value: vent.co2DiffusionConstant },
            { value: vent.n2oDiffusionConstant }
          ])
        }
      }
      return out
    }
  },
  methods: {
    preparePayload (data) {
      if ('co2DiffusionConstant' in data){
        data.co2DiffusionConstant = parseFloat(data.co2DiffusionConstant)
      }
      if ('n2oDiffusionConstant' in data){
        data.n2oDiffusionConstant = parseFloat(data.n2oDiffusionConstant)
      }
    },
    async updateVent (id, data) {
      this.preparePayload(data)
      await FleetDataService.updateChamberVent(id, data)
    },
    async createVent (data) {
      this.preparePayload(data)
      await FleetDataService.createChamberVent(data)
    },
    showVentEditor (vent) {
      this.ventData = vent
      this.$nextTick(() => {
        this.$refs.ventedit.show()
      })
    },
    showVentAdd () {
      this.$refs.ventadd.show()
    },
    ventLinkColor (vent, baseClass) {
      let classes = baseClass
      if ('status' in vent && vent.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.loading = true
      FleetDataService.getAllChamberVents()
        .then(response => {
          this.vents = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('vents-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('vents-list')
  },
  components: { DataTable, ModalSubmissionForm }
}
</script>

<template>
  <transition name="modal">
  <ModalMediaViewer v-if="isBlocking && file" @close="close" sz="auto">
      <template v-slot:body>
        <div class="log-viewer-body">
          <div class="log-viewer-options">
            <CheckboxInputRow
              wrapperClass="ms-2"
              labelClass="width-0"
              v-model="isMonospace"
              checkboxLabel="Monospace Font"
              checkboxLabelClass="light-on-dark"
              id="log-viewer-monospace-cbx"
            />

            <CheckboxInputRow
              wrapperClass="ms-2"
              labelClass="width-0"
              v-model="isWrap"
              checkboxLabel="Wrap Long Lines"
              checkboxLabelClass="light-on-dark"
              id="log-viewer-wrap-lines-cbx"
            />
          </div>
          <ViewerTopBar :file="file" :showEditorFunc="showEditorFunc" :closer="close" :style="`width: ${textareaWidth}; margin: 0 auto; transition: width 0.3s ease;`" />
          <div :class="viewboxClasses" v-if="logData">
            <span ref="widthCheck" id="widthCheck" :class="widthCheckClasses" >
              {{ logData.replaceAll('\n', '\r\n') }}
            </span>
            <textarea ref="viewboxData" id="viewboxData" :style="`width: ${textareaWidth}`" v-model="logData" spellcheck="false" readonly />
          </div>
          <div class="loading-placeholder" v-else>
            Loading ...
          </div>
          <ViewerBottomBar :file="file" />
        </div>
      </template>
  </ModalMediaViewer>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import ModalMediaViewer from '@/components/controls/Viewers/ModalMediaViewer.vue'
import IconButton from '@/components/controls/IconButton.vue'
import ViewerTopBar from '@/components/controls/Viewers/ViewerTopBar.vue'
import ViewerBottomBar from '@/components/controls/Viewers/ViewerBottomBar.vue'

const monospacePrefKey = 'viewer.log.monospace'
const wrapTextPrefKey = 'viewer.log.wrap'

export default {
  name: 'log-viewer',
  extends: BaseComponent,
  components: { ModalMediaViewer, IconButton, ViewerTopBar, CheckboxInputRow, ViewerBottomBar },
  props: ['file', 'showEditorFunc', 'closer'],
  data () {
    return {
      isBlocking: false,
      isLoading: false,
      logData: undefined,
      isMonospace: localStorage.getItem(monospacePrefKey) !== 'false',
      isWrap: localStorage.getItem(wrapTextPrefKey) !== 'false',
      textareaWidth: '100vw',
      runCount: 0
    }
  },
  computed: {
    widthCheckClasses () {
      const base = 'width-check'
      let out = base
      if (this.isMonospace) {
        out = `${out} monospace`
      }
      return out
    },
    viewboxClasses () {
      const base = 'log-viewbox'
      let out = base
      if (!this.isWrap) {
        out = `${out} nowrap`
      }
      if (this.isMonospace) {
        out = `${out} monospace`
      }
      return out
    }
  },
  watch: {
    isWrap: {
      handler () {
        localStorage.setItem(wrapTextPrefKey, this.isWrap)
        this.runCount = 0
        this.updateTextAreaWidth()
      }
    },
    isMonospace: {
      handler () {
        localStorage.setItem(monospacePrefKey, this.isMonospace)
        this.runCount = 0
        this.updateTextAreaWidth()
      }
    },
  },
  methods: {
    updateTextAreaWidth () {
      if (this.runCount > 34) {
        return
      }
      this.runCount += 1
      setTimeout(this.updateTextAreaWidth, 150)
      if((!this.logData && this.logData !== '') || !this.$refs.widthCheck) {
        return
      }
      const max = window.innerWidth - 20
      const min = 375
      let out = this.$refs.widthCheck.offsetWidth + 30
      if (!out || out > max) {
        out = max
      } else if (out < min) {
        out = min
      }
      this.textareaWidth = `${out}px`
    },
    loadData () {
      if (!this.file || !this.file.s3url) {
        return
      }
      this.isLoading = true
      fetch(this.file.s3url)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error(`Error: ${response.statusText}`)
        }
        resp.text()
        .then(txt => {
          this.logData = txt
        })
        .catch(e => {
          console.log('failed to load file', this.file.filename, e)
        })
        .finally(() => {
          this.isLoading = false
        })
      })
      .catch(e => {
        console.log('failed to load file', this.file.filename, e)
        this.isLoading = false
      })
    },
    show () {
      this.logData = undefined
      this.isBlocking = true
      this.loadData()
      this.runCount = 0
      this.updateTextAreaWidth()
    },
    close () {
      this.runCount = 1000
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    }
  }
}
</script>
<style scoped>
  .log-viewer-body {
    position: relative;
  }

  .log-viewbox {
    margin: 0 auto;
    text-align: center;
    max-width: calc(100vw - 20px);
    width: calc(100vw - 30px);
  }

  .log-viewbox textarea {
    min-width: 375px;
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    transition: width 0.3s ease;
  }

  .loading-placeholder {
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    text-align: center;
    padding-top: 45vh;
    color: var(--app-subtle-color);
    font-size: 24px;
  }

  .log-viewbox.monospace textarea,
  span.width-check.monospace {
    font-family: monospace;
    font-size: 14px;
  }
  
  .log-viewbox.nowrap textarea {
    overflow-x: auto;
    white-space: pre;
  }

  .log-viewer-options {
    display: flex;
    align-items: center;
    align-content: center;
    position: absolute;
    width: 350px;
    left: calc(50% - 175px);
    padding-left: 5px;
    padding-right: 5px;
    max-height: 32px;
    border-radius: var(--form-border-radius);
  }

  span.width-check {
    visibility: hidden;
    position: absolute;
    white-space: pre;
    top: -9999px;
    left: -9999px;
  }

</style>
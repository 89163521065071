<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" :sz="modalSz" :isScrollable="modalSz === 'sm'">
      <template v-slot:header>
        ADS Alert Info
      </template>

      <template v-slot:body>
        <div v-if="alert" style="overflow-x: hidden">
          <div class="ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle">
            <ElementIdRow label="ID" :value="alert.id" :hideLabel="true" />
          </div>
          <TextLabelRow label="Status"
            :value="alert.status"
            valueClass="ms-1"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Customer"
            :value="alert.customerName"
            valueClass="ms-1"
            v-if="alert.customerName"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Site"
            :value="alert.siteName"
            valueClass="ms-1"
            v-if="alert.siteName"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Node"
            :value="alert.nodeName"
            valueClass="ms-1"
            v-if="alert.nodeName"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Device"
            :value="alert.deviceEui"
            valueClass="ms-1"
            v-if="alert.deviceEui"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Position"
            :value="alert.position"
            valueClass="ms-1"
            v-if="alert.position"
            labelClass="bold subtle"
          />

          <TextLabelRow label="Start Time"
            :value="prettyStartTime"
            valueClass="ms-1"
            v-if="alert.startTime"
            labelClass="bold subtle"
          />

          <TextLabelRow label="End Time"
            :value="prettyEndTime"
            valueClass="ms-1"
            v-if="alert.endTime && alert.status === 'resolved'"
            labelClass="bold subtle"
          />

          <hr />

          <div class="ms-3 m-2" style="overflow-y: hidden">
            <div class="bold subtle">Description</div>
            <pre class="help-copy">{{ alert.alertDescription}}</pre>
          </div>
        </div>

      </template>
    </Modal>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import Modal from '@/components/modals/Modal.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'

export default {
  name: 'ads-alert-info-modal',
  extends: BaseComponent,
  props: ['alert', 'closer'],
  data () {
    return {
      modalSz: 'sm',
      isBlocking: false
    }
  },
  computed: {
    prettyStartTime () {
      if (!this.alert || !this.alert.startTime) {
        return ''
      }
      return this.getFormattedDateSecsN(this.alert.startTime)
    },
    prettyEndTime () {
      if (!this.alert || !this.alert.endTime) {
        return ''
      }
      return this.getFormattedDateSecsN(this.alert.endTime)
    }
  },
  methods: {
    timeFromEpochMS (inputMS) {
      const t = Date(inputMS)
      return t
    },
    show () {
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    },
  },
  components: {
    Modal,
    ElementIdRow,
    TextLabelRow,
  }
}
</script>

<template>
    <div class="d-flex">
      <input @keypress="keyPressed" ref="newTagBubbleInput" type="text" class="form-input ms-1 tag-bubble-input" :placeholder="compositePlaceholder" v-model="inputVal" />
      <div @click="okClicked" class="tag-bubble-input-action tag-bubble-input-ok font-reduce-2">
        &#x2713;
      </div>
      <div @click="cancelClicked" class="tag-bubble-input-action tag-bubble-input-cancel font-reduce-3">
        &#x2715;
      </div>
    </div>
</template>
<script>
export default {
  name: 'tag-bubble-input',
  props: ['placeholder', 'closer', 'adder', 'groupName'],
  data () {
    return {
      inputVal: ''
    }
  },
  computed: {
    compositePlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return 'New Item'
    }
  },
  methods: {
    keyPressed (e) {
      if (e.keyCode === 13) {
        this.okClicked()
      }
    },
    okClicked () {
      if (this.adder) {
        this.adder(this.groupName, this.inputVal)
      }
      this.cancelClicked()
    },
    cancelClicked () {
      if (this.closer) {
        this.closer()
      }
    },
    focus () {
      this.$nextTick(() => {
        this.$refs.newTagBubbleInput.focus()
        this.$refs.newTagBubbleInput.select()
      })
    }
  },
  mounted () {
    this.focus()
  }
}
</script>
<style scoped>
.tag-bubble-input {
    height: 28px;
    margin-top: 4px;
    border-radius: 6px;
    margin-right: -58px;
}

.tag-bubble-input-action {
    height: 27px;
    margin-top: 5px;
    padding-top: 3px;
    width: 28px;
    text-align: center;
    border-left: 1px var(--form-control-border-color) solid !important;
    cursor: pointer;
}

.tag-bubble-input-ok {
    color: var(--app-highlight-color);
}

.tag-bubble-input-ok:hover {
    background-color: #00cc0022;
}

.tag-bubble-input-cancel:hover {
    background-color: #cc000022;
}

.tag-bubble-input-cancel {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 30px;
    padding-right: 2px;
    margin-top: 5px;
    padding-top: 3.5px;
    color: var(--app-failure-color);
}
</style>
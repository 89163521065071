<template>
  <DataTable
    :cols="cols"
    :rows="rows"
    :loading="loading"
    noDataMessage="No Maintenance Cases were found for your Filter"
    :initialSort="7"
    :initialSortReverse="true"
    actionIcon="plus"
    itemType="Case"
    :actionFunc="emitShowAddCase"
    title="Maintenance Cases"
  />
</template>
<script>
import DataTable from '@/components/tables/DataTable.vue'
import BaseComponent from '@/components/BaseComponent.vue'
import api from '@/services/fleet/FleetDataService'

export default {
  name: 'maintenance-case-list-table',
  extends: BaseComponent,
  components: { DataTable },
  emits: ['showAddCase', 'showCaseViewer'],
  props: ['caseList', 'loading'],
  data () {
    return {
    }
  },
  methods: {
    priorityClass (input) {
      if (!input || !['medium', 'high'].includes(input)) {
        return undefined
      }
      if (input === 'medium') {
        return 'status-caution'
      }
      if (input === 'high') {
        return 'status-error'
      }
      return undefined
    },
    shortID (input) {
      if (!input || input.length < 12) {
        return input
      }
      return input.substring(input.length - 12)
    },
    shortElementType (input) {
      if (input === 'device') {
        return 'Dev'
      }
      if (input === 'gateway') {
        return 'GW'
      }
      return '--'
    },
    emitShowAddCase () {
      this.$emit('showAddCase')
    },
    emitShowCaseViewer (caseData) {
      this.$emit('showCaseViewer', caseData)
    }
  },
  computed: {
    cols () {
      return [
        { text: 'ID', sort: 0},
        { text: 'Type', sort: 1},
        { text: 'Element ID', sort: 2},
        { text: 'Assigned To', sort: 3},
        { text: 'Status', sort: 4, style: 'font-size: .85em;'},
        { text: 'Priority', sort: 5},
        { text: 'Failure Mode', sort: 6},
        { icon: 'clock', title: 'Time Opened', sort: 7},
        { text: 'Site', sort: 8},
        { text: 'Customer', sort: 9},
      ]
    },
    rows () {
      if (!this.caseList || this.caseList.length === 0) {
        return []
      }
      const out = []
      for (const mc of this.caseList) {
        out.push([
        { value: this.shortID(mc.id), click: this.emitShowCaseViewer, i: mc },
        { value: this.shortElementType(mc.elementType) },
        { value: mc.elementID },
        { value: mc.assignee },
        { value: mc.status },
        { value: mc.priority, class: this.priorityClass(mc.priority) },
        { value: mc.failureMode },
        { value: this.getSinceSecs(mc.startTS), sortValue: mc.startTS },
        { value: api.getSiteDisplayName(mc.siteID) },
        { value: api.getCustomerDisplayName(mc.customerID) }
        ])
      }
      return out
    }
  }
}
</script>

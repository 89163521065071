<template>
  <div :class="classes">
    {{ label }}
  </div>
</template>
<script>
export default {
  props: ['label', 'headingClass'],
  computed: {
    classes () {
      let out = 'submission-form-section-heading'
      if (this.headingClass) {
        return `${out} ${this.headingClass}`
      }
      return out
    }
  }
}
</script>
<style scoped>
.submission-form-section-heading {
  font-weight: 600;
  padding-left: 10px;
  margin-top: 4px;
  padding-top: 5px;
  margin-bottom: 2.5px;
  color: var(--app-body-color);
  font-size: var(--font-reduce-1a);
  border-top: 1px var(--form-control-border-color) solid;
}

.submission-form-section-heading.no-border-top {
  border-top: none;
}
</style>
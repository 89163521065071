<template>
  <ContextMenuContainer
    ref="contextMenuContainer"
    :posAnchorId="posAnchorId"
    :closer="closer"
    :containerClass="containerClasses"
    :bigShadow="bigShadow"
  >
    <template v-slot:menu>
      <div>
        <h4 class="context-menu-form-header" v-if="formTitle">{{ formTitle }}</h4>
        <hr v-if="formTitle" />
        <SubmissionForm
          :fields1="formFields"
          :formData="formData"
          @setFormData="setFormData"
        />
      </div>
    </template>
  </ContextMenuContainer>
</template>
<script>
import ContextMenuContainer from '@/components/controls/ContextMenu/ContextMenuContainer.vue'
import SubmissionForm from '@/components/forms/SubmissionForm.vue'

export default {
  name: 'context-menu-form',
  components: { ContextMenuContainer, SubmissionForm },
  props: ['posAnchorId', 'closer', 'formFields', 'formData', 'formTitle', 'containerClass', 'bigShadow'],
  emits: ['setFormData'],
  data () {
    return {
      assigneeType: this.getSavedOrDefaultAssigneeType(),
      statusFilter: this.getSavedOrDefaultStatusFilter(),
      elementFilter: this.getSavedOrDefaultElementFilter()
    }
  },
  computed: {
    containerClasses () {
      const base = 'context-menu-form-container'
      if (this.containerClass) {
        return `${base} ${this.containerClass}`
      }
      return base
    }
  },
  methods: {
    setFormData (data) {
      this.$emit('setFormData', data)
    },
    getSavedOrDefaultAssigneeType () {
      return 'self'
    },
    getSavedOrDefaultStatusFilter () {
      return 'open'
    },
    getSavedOrDefaultElementFilter () {
      return ''
    },
    reset () {
      this.assigneeType = this.getSavedOrDefaultAssigneeType()
      this.statusFilter = this.getSavedOrDefaultStatusFilter()
      this.elementFilter = this.getSavedOrDefaultElementFilter()
    },
    open () {
      this.$refs.contextMenuContainer.open()
    },
    close () {
      this.$refs.contextMenuContainer.close()
    }
  }
}
</script>
<style>
  .context-menu-form {
    min-height: 400px;
  }

  .context-menu-form-container {
    min-width: 373px !important;
    min-height: 350px;
  }

  .context-menu-form-header {
    margin-top: 8px;
    margin-left: 7px;
  }

  h4.context-menu-form-header {
    font-size: var(--font-increase-1);
    font-weight: 650;
  }

</style>

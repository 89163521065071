<template>
  <div class="info-section">
    <slot name="info" />
  </div>
</template>
<script>

export default {
    name: 'info-section'
}
</script>
<style scoped>
div.info-section {
    color: var(--app-text-color);
    border-radius: var(--form-border-radius);
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    background-color: var(--form-control-border-color);
    box-sizing: border-box;
}
</style>
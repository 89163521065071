<template>
  <div>
    <DataTable
      class="dt-info-pane-sm"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      noDataMessage="No Alerts"
      :initialSort="1"
    >
    </DataTable>
    <ModalADSAlertInfo ref="adsShowAlert" :alert="alertData"  />
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import ModalADSAlertInfo from '@/components/notifications/ModalADSAlertInfo.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'alerts-by-n-list',
  extends: BaseComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Alert ID', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Status', sort: 2 },
        { text: 'Category', sort: 3 }
      ],
      entityTypeName: this.entityType,
      loading: false,
      envAlerts: [],
      adsAlerts: [],
      alertData: undefined
    }
  },
  props: ['entityType', 'site'],
  computed: {
    alerts () {
      const out = []
      for (const a of this.envAlerts) {
        a.route = 'alert-details'
        out.push(a)
      }
      for (const a of this.adsAlerts) {
        a.isADS = true
        a.category = 'ADS'
        // this gets the metric name from the description
        if (a.alertDescription) {
          const x = a.alertDescription.indexOf(' for ')
          if (x) {
            const y = a.alertDescription.indexOf(' (', x)
            if (y) {
              a.metricName = a.alertDescription.substring(x + 5, y)
              a.category = `ADS / ${a.metricName}`
            }
          }
        }
        out.push(a)
      }
      return out
    },
    listTitle () {
      return 'Alerts for this ' + this.siteClassification.charAt(0).toUpperCase() + this.siteClassification.slice(1)
    },
    siteClassification () {
      if (this.site && this.site.siteClassification) {
        return this.site.siteClassification
      }
      return this.entityTypeName
    },
    rows () {
      const out = []
      if (this.alerts && this.alerts.length > 0) {
        for (const alert of this.alerts) {
          if (alert.isADS) {
            out.push([
              { value: this.getLastWord(alert.id), click: () => { this.showAdsAlert(alert) }, params: { id: alert.id } },
              { value: this.getSinceSecs(alert.startTime), sortValue: (Date.now() / 1000) - alert.startTime },
              { value: alert.status, class: { notable: alert.status === 'active' } },
              { value: alert.category }
            ])
            continue
          }
          out.push([
            { value: this.getLastWord(alert.id), route: 'alert-details', params: { id: alert.id } },
            { value: this.getSinceSecs(alert.lastModified), sortValue: (Date.now() / 1000) - alert.lastModified },
            { value: alert.status, class: { notable: alert.status === 'active' } },
            { value: alert.category }
          ])
        }
      }
      return out
    }
  },
  methods: {
    embellishAlert (data) {
      this.searchIndex = FleetDataService.getManifest()
      if (!data) {
        return
      }
      if (data.customerId) {
        const custData = this.searchIndex['customer.' + data.customerId]
        if (custData) {
          data.customerName = custData.title
        }
      }
      if (data.siteId) {
        const siteData = this.searchIndex['site.' + data.siteId]
        if (siteData) {
          data.siteName = siteData.title
        }
      }
      if (data.deviceEui) {
        const deviceData = this.searchIndex['device.' + data.deviceEui.toLowerCase()]
        if (deviceData && 'node' in deviceData) {
          if ('position' in deviceData) {
            data.position = deviceData.position
          }
          const nodeData = this.searchIndex['node.' + deviceData.node]
          if (nodeData && nodeData.title) {
            data.nodeId = nodeData.id
            data.nodeName = nodeData.title
          }
        }        
      }
    },
    showAdsAlert (data) {
      this.embellishAlert(data)
      this.alertData = data
      this.$nextTick(() => {
        this.$refs.adsShowAlert.show()
      })
    },
    retrieveAlerts (id) {
      this.loading = true
      const etn = this.entityTypeName.toLowerCase()
      NotificationsDataService.getRecentAlerts(etn, id)
        .then(response => {
          this.envAlerts = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
      var f = undefined
      if (etn === 'site') {
        f = NotificationsDataService.getADSAlertsBySite
      } else if (etn === 'device') {
        f = NotificationsDataService.getADSAlertsByDevice
      } else if (etn === 'customer') {
        f = NotificationsDataService.getADSAlertsByCustomer
      }
      if (!f) {
        return
      }
      f(id)
        .then(response => {
          this.adsAlerts = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refreshList () {
      this.retrieveAlerts(this.$route.params.id)
    }
  },
  mounted () {
    Reloadables.registerReloadable('alerts-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('alerts-list')
  },
  components: { DataTable, ModalADSAlertInfo }
}
</script>

import http from '@/http-common'
import qs from 'qs'

class NotificationsDataService {
  // AppSounds
  getAppSounds () {
    return http.get('/provisioning/appsounds')
  }

  // Alerts CRUD
  getRecentAlerts (filterBy: string, filterFor: string) {
    let query = ''
    if (filterBy && filterFor) {
      query = '?filterBy=' + filterBy + '&filterFor=' + filterFor
    }
    return http.get('/provisioning/alerts/recent' + query)
  }

  getAlert (id: string) {
    return http.get(`/provisioning/alerts/${id}`)
  }

  updateAlert (id: string, data: any) {
    return http.put(`/provisioning/alerts/${id}`, qs.stringify(data))
  }

  resolveAlert (id: string, data: any) {
    return http.put(`/provisioning/alerts/${id}/resolve`, qs.stringify(data))
  }

  lockAlert (id: string, data: any) {
    return http.put(`/provisioning/alerts/${id}/lock`, qs.stringify(data))
  }

  unlockAlert (id: string, data: any) {
    return http.put(`/provisioning/alerts/${id}/unlock`, qs.stringify(data))
  }

  // Thresholds CRUD
  getAllThresholds () {
    return http.get('/provisioning/thresholds')
  }

  getThreshold (id: string) {
    return http.get(`/provisioning/thresholds/${id}`)
  }

  createThreshold (data: any) {
    return http.post('/provisioning/thresholds', qs.stringify(data))
  }

  updateThreshold (id: string, data: any) {
    return http.put(`/provisioning/thresholds/${id}`, qs.stringify(data))
  }

  // ADS
  getAllADSAlerts () {
    return http.get('/provisioning/ads/alerts')
  }

  getADSAlertsByDevice (id) {
    return http.get(`/provisioning/ads/alerts/deviceEui/${id}`)
  }

  getADSAlertsBySite (id) {
    return http.get(`/provisioning/ads/alerts/siteId/${id}`)
  }

  getADSAlertsByCustomer (id) {
    return http.get(`/provisioning/ads/alerts/customerId/${id}`)
  }

  getAllADSThresholds () {
    return http.get('/provisioning/ads/thresholds')
  }

  createAdsThreshold (data) {
    return http.post('/provisioning/ads/thresholds', data)
  }

  updateAdsThreshold (id: string, data: any) {
    const payload = { ...data }
    delete payload.id
    return http.put(`/provisioning/ads/thresholds/${id}`, payload)
  }

  deleteAdsThreshold (id: string) {
    return http.delete(`/provisioning/ads/thresholds/${id}`)
  }

  // Templates CRUD
  getAllTemplates () {
    return http.get('/provisioning/templates')
  }

  getTemplate (id: string) {
    return http.get(`/provisioning/templates/${id}`)
  }

  createTemplate (data: any) {
    return http.post('/provisioning/templates', qs.stringify(data))
  }

  updateTemplate (id: string, data: any) {
    return http.put(`/provisioning/templates/${id}`, qs.stringify(data))
  }

  // Subscriptions Admininstration
  getApnSubscriptionsByFilter (filterBy: string, filterOn: string) {
    return http.get('/provisioning/subscriptions/apn/filtered?' + qs.stringify({ filterBy: filterBy, filterOn: filterOn }))
  }

  getApnSubscriptionByDeviceToken (deviceToken: string, bundleID: string, site: string) {
    return http.get(`/provisioning/subscriptions/apn/token/${deviceToken}/${bundleID}/${site}`)
  }

  deleteApnSubscriptionByDeviceToken (deviceToken: string, bundleID: string, site: string) {
    return http.delete(`/provisioning/subscriptions/apn/token/${deviceToken}/${bundleID}/${site}`)
  }

  sendTestNotification (data: any) {
    return http.post('/provisioning/subscriptions/apn/send', qs.stringify(data))
  }

  // Exclusion Zones
  getExclusionZones () {
    return http.get('/provisioning/exclusionZones/')
  }

  deleteExclusionZone (id: any) {
    return http.delete(`/provisioning/exclusionZones/${id}`)
  }

  updateExclusionZone (id: any, data: any) {
    return http.put(`/provisioning/exclusionZones/${id}`, data)
  }

  createExclusionZone (data: any) {
    return http.post('/provisioning/exclusionZones', data)
  }
}

export default new NotificationsDataService()

<template>
  <slot name="customMode1" v-if="mode === 'custom1'" />
  <slot name="customMode2" v-else-if="mode === 'custom2'" />
  <slot name="customMode3" v-else-if="mode === 'custom3'" />
  <slot name="customMode4" v-else-if="mode === 'custom4'" />
  <slot name="customMode5" v-else-if="mode === 'custom5'" />
  <div class="submit-confirm-delete-pane" v-else-if="mode === 'confirmDelete'">
    <div style="margin-top: 50px;">
      <fa icon="triangle-exclamation" class="status-warning" style="max-width: 100px" />
    </div>
    <br />
    <br />
    <b>Just a Quick Confirmation...</b>
    <br />
    <br />
    Are you sure you want to delete this {{ elementType }}? This cannot be undone.
    <br /><br />
    Press Confirm or Go Back below to proceed.
  </div>
  <div class="submit-result-status-pane" style="padding-top: 125px" v-else-if="mode === 'resultSuccess'">
    {{ elementType }} {{ submittedActionVerb }}<template v-if="!submittedActionVerb.endsWith('e')">e</template>d
    <div style="margin-top: 50px;">
      <fa icon="check" style="max-width: 100px" class="status-ok" />
    </div>
    <div class="subtle font-reduce-2" style="margin-top: 50px;" v-if="!hideCloseTime && closeTime">
      Closing in {{ closeTimeLeft }} ...
    </div>
  </div>
  <div class="submit-result-status-pane" v-if="mode === 'resultFailure'">
    <div class="mb-4"><fa icon="exclamation-circle" style="max-width: 100px" class="status-error" /></div>
    <span class="status-error font-enlarge-1">Request Failed</span>
    <br />
    <div style="font-weight: 400; font-size: .9em; padding: 18px 8px; line-height: 1.75em">
    An problem prevented the {{ elementType }} from being {{ submittedActionVerb.toLowerCase() }}<template v-if="!submittedActionVerb.endsWith('e')">e</template>d.
    <div class="mt-3" v-if="allowRetry">Press 'Retry' below to give it another go.</div>
    </div>
    <hr v-if="failureText" />
    <div class="font-reduce-2 subtle" style="text-align: left; font-weight: 400; font-size: .9em; padding: 5px 15px;" v-if="failureText">
      {{ failureText }}
    </div>
  </div>
  <div v-else-if="mode === 'form'">
    <div v-for="(field,i) of fields1" :key="i">
      <ElementIdRow v-if="field.controlType === 'id' && data && data.id && field.hidden !== true"
        class="element-id-row"
        :label="field.label || 'ID'"
        :value="data.id"
        :hideLabel="field.hideLabel || false"
      />
      <div v-else-if="field.controlType === 'pillboxInput' && field.hidden !== true"
        class="centered"
        style="display: flex; justify-content: center;">
        <PillboxRow
          v-model="data[field.field]"
          :label="field.label"
          :labelClass="field.labelClass"
          :valueChangedFunc="dataChanged"
          :pills="field.pills"
          :group="field.group"
          :wrapperClass="field.wrapperClass"
        />
      </div>
      <TextContent
        v-else-if="field.controlType === 'textContent' && field.hidden !== true"
        :text="field.text"
        :class="field.textClass"
        :borderTop="field.borderTop"
        :borderBottom="field.borderBottom"
      />
      <SectionHeading :label="field.label" :headingClass="field.headingClass"
        v-else-if="field.controlType === 'sectionHeader' && field.hidden !== true" />
      <hr class="field.class" v-else-if="field.controlType === 'hr' && field.hidden !== true" />
      <TextLabelRow v-else-if="field.controlType === 'textLabelRow' && field.hidden != true"
        :label="field.label"
        :labelClass="labelClass(field.labelClass, field.required)"
        :value="field.text"
        :valueClass="field.textClass"
        :disabled="field.disabled ? true : false"
        :height="field.height"
        :wrapperClass="field.wrapperClass"
      />
      <TextInputRow v-else-if="field.controlType === 'textInput' && field.hidden !== true"
        :label="field.label"
        v-model="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :inputClass="field.inputClass"
        :required="field.required ? true : false"
        :placeholder="field.placeholder || ''"
        :dataType="field.dataType || ''"
        :suffix="field.suffix"
        :disabled="field.disabled ? true : false"
        :valueChangedFunc="dataChanged"
        :wrapperClass="field.wrapperClass"
      />
      <TextAreaRow v-else-if="field.controlType === 'textarea' && field.hidden !== true"
        :label="field.label"
        v-model="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :inputClass="field.inputClass"
        :placeholder="field.placeholder || ''"
        :required="field.required ? true : false"
        :disabled="field.disabled ? true : false"
        :valueChangedFunc="dataChanged"
        :wrapperClass="field.wrapperClass"
      />
      <SelectRow v-else-if="field.controlType === 'selectInput' && field.hidden !== true"
        :label="field.label"
        v-model="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :selectClass="field.inputClass"
        :required="field.required ? true : false"
        :placeholder="field.placeholder || ''"
        :dataType="field.dataType || ''"
        :suffix="field.suffix"
        :options="field.options"
        :disabled="field.disabled ? true : false"
        :noSelectionLabel="field.noSelectionLabel"
        @change="dataChanged"
      />
      <CheckboxInputRow
        v-else-if="field.controlType === 'checkboxInput' && field.hidden !== true"
        :id="`formField.${field.field}`"
        :label="field.label"
        v-model="data[field.field]"
        :value="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :inputClass="field.inputClass"
        :required="field.required ? true : false"
        :placeholder="field.placeholder || ''"
        :dataType="field.dataType || ''"
        :suffix="field.suffix"
        :disabled="field.disabled ? true : false"
        :valueChangedFunc="dataChanged"
        :caption="field.caption"
        :warning="field.warning"
        :checkboxLabel="field.checkboxLabel"
        :wrapperClass="field.wrapperClass"
      />
      <SimpleDateTimePicker v-else-if="field.controlType === 'simpleDateTime' && field.hidden !== true"
        :label="field.label"
        v-model="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :inputClass="field.inputClass"
        :required="field.required ? true : false"
        :disabled="field.disabled ? true : false"
        :valueChangedFunc="dataChanged"
        :hideTime="field.hideTime"
        :showPlaceholderBelow="field.showPlaceholderBelow"
        :useLocalTime="field.useLocalTime"
        :showAmPm="field.showAmPm"
      />
      <IndexSearchRow v-else-if="field.controlType === 'searchRow' && field.hidden !== true"
        :label="field.label"
        :placeholder="field.placeholder"
        v-model="data[field.field]"
        :labelClass="labelClass(field.labelClass, field.required)"
        :inputClass="field.inputClass"
        :inputStyle="field.inputStyle"
        :separator="field.separator"
        :disabled="field.disabled ? true : false"
        :elementType="field.elementType"
        :required="field.required ? true : false"
        :valueChangedFunc="dataChanged"
        :wrapperClass="field.wrapperClass"
        :resultsClass="field.resultsClass"
        :valueClass="field.valueClass"
        @searchItemSelected="searchItemSelected"
      />
      <ButtonsRow v-else-if="field.controlType === 'buttonsRow' && field.hidden !== true"
        :buttons="field.buttons"
        :wrapperClass="field.wrapperClass"
      />
    </div>
    <LastModifiedRow :ts="data.lastModified" :author="data.lastModifiedBy" v-if="data && 'lastModified' in data"/>
  </div>
</template>
<script>
import Modal from '@/components/modals/Modal.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextLabelRow from '@/components/forms/SimpleTextLabelRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import PillboxRow from '@/components/forms/PillboxRow.vue'
import SimpleDateTimePicker from '@/components/forms/SimpleDateTimePicker.vue'
import IndexSearchRow from '@/components/forms/IndexSearchRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import SectionHeading from '@/components/forms/SectionHeading.vue'
import TextContent from '@/components/forms/TextContent.vue'
import ButtonsRow from '@/components/forms/ButtonsRow.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'general-submission-form',
  props: ['formData', 'fields1', 'elementType', 'isCreate', 'createFunc',
    'updateFunc', 'deleteFunc', 'defaultMode', 'allowRetry', 'responseDisplayType',
    'successPanePauseMS', 'hideCloseTime'],
  emits: ['setFormData', 'setCanSubmit', 'searchItemSelected', 'apiResponse', 'prepareToClose', 'setIsLoading'],
  data () {
    return {
      isLoading: false,
      data: this.formData ? { ...this.formData } : {},
      mode: this.defaultMode || 'form',
      failureText: undefined,
      isDelete: false,
      closeTime: undefined,
      closeTimeLeft: 0,
      submittedVerb: undefined
    }
  },
  watch: {
    canSubmit: {
      handler () {
        this.$emit('setCanSubmit', this.canSubmit)
      }
    }
  },
  computed: {
    successPanePause () {
      if (this.successPanePauseMS >= 0) {
        return this.successPanePauseMS
      }
      return 2000
    },
    changeModeOnResp () {
      if (this.responseDisplayType === 'panes') {
        return true
      }
      return false
    },
    flashMessageOnResp () {
      if (this.responseDisplayType && this.responseDisplayType !== 'flash') {
        return false
      }
      return true
    },
    submittedActionVerb () {
      return this.submittedVerb ? this.submittedVerb : this.isDelete ? 'Delete' : this.isCreate ? 'Create' : 'Update'
    },
    actionVerb () {
      return this.isDelete ? 'Delete' : this.isCreate ? 'Create' : 'Update'
    },
    actionNoun () {
      return this.isDelete ? 'Deletion' : this.isCreate ? 'Creation' : 'Update'
    },
    element () {
      return this.elementType ? this.elementType : 'Item'
    },
    canSubmit () {
      if (this.isLoading) {
        return false
      }
      if (!this.fields1) {
        return true
      }
      for (const field of this.fields1) {
        const isRequired = 'required' in field && field.required === true
        if (!isRequired) {
          continue
        }
        const fieldExists = field.field in this.data && this.data[field.field] !== ''
        if (!fieldExists) {
          return false
        }
      }
      return true
    },
    submitFunc () {
      if (this.isDelete) {
        if (this.deleteFunc) {
          return this.deleteFunc
        }
        return undefined
      }
      if (this.isCreate) {
        if (this.createFunc) {
          return this.createFunc
        }
        return undefined
      }
      if (this.updateFunc) {
        return this.updateFunc
      }
      return undefined
    }
  },
  methods: {
    setMode(mode) {
      this.mode = mode
    },
    isSubmittable () {
      return this.canSubmit
    },
    searchItemSelected (item) {
      this.$emit('searchItemSelected', item)
    },
    dataChanged (e) {
      this.$emit('setFormData', this.data)
    },
    labelClass (className, required) {
      if (!className && !required) {
        return ""
      }
      if (className && required) {
        return `${className} label-required`
      }
      if (required) {
        return 'label-required'
      }
      return className || ""
    },
    startCloseTimer (reset = true) {
      if (!this.successPanePause) {
        return
      }
      if (reset) {
        this.closeTimeLeft = Math.ceil(this.successPanePause / 1000)
        this.closeTime = Date.now() + this.successPanePause
      } else {
        const left = this.closeTime - Date.now()
        if (left <= 0) {
          this.closeTimeLeft = 0
        } else {
          this.closeTimeLeft = Math.ceil(left / 1000)
        }
      }
      if (this.closeTimeLeft > 0) {
        setTimeout(() => {this.startCloseTimer(false)}, 50)
      } else {
        this.closeTime = undefined
        this.closeTimeLeft = undefined
      }
    },
    submit () {
      if (!this.submitFunc) {
        return
      }
      const submitFuncWithId = async () => {
        return this.submitFunc(this.data.id)
      }
      const submitFuncWithBody = async () => {
        return this.submitFunc(this.data)
      }
      const submitFuncWithIdAndBody = async () => {
        return this.submitFunc(this.data.id, this.data)
      }
      const f = this.isDelete ? submitFuncWithId : this.isCreate ? submitFuncWithBody : submitFuncWithIdAndBody
      return this.doSubmit(f, this.actionVerb)
    },
    doSubmit (submitFunc, verb) {
      if (!submitFunc) {
        return
      }
      this.isLoading = true
      this.submittedVerb = verb
      this.$emit('setIsLoading', true)
      const emitSuccess = (resp) => { this.$emit('apiResponse', verb.toLowerCase(), resp) }
      submitFunc()
      .then((resp) => {
        this.failureText = undefined
        this.$emit('prepareToClose')
        if (this.flashMessageOnResp) {
          const addE = verb.endsWith('e') ? '' : 'e'
          HelpContent.setTimedFlashMessage(`${this.element} ${verb}${addE}d`)
        }
        Reloadables.requestReload()
        if (this.changeModeOnResp) {
          this.mode = 'resultSuccess'
          this.startCloseTimer(true)
          setTimeout(() => { emitSuccess(resp) }, this.successPanePause)
        } else {
          this.$nextTick(() => {
            emitSuccess(resp)
          })
        }
      })
      .catch(e => {
        console.log('api error', this.elementType, this.actionNoun, e)
        const message = e.message ? e.message : e
        this.failureText = message
        if (this.flashMessageOnResp) {
          HelpContent.setFlashMessage(message, true)
        }
        if (this.changeModeOnResp) {
          this.mode = 'resultFailure'
        }
        this.$emit('apiResponse', verb.toLowerCase(), e, message)
      })
      .finally(() => {
        this.isLoading = false
        this.$emit('setIsLoading', false)
      })
    },
    backToForm () {
      this.mode = 'form'
      this.isDelete = false
    },
    cancelDeleteElement () {
      this.mode = 'form'
      this.isDelete = false
    },
    confirmDeleteElement () {
      this.mode = 'confirmDelete'
      this.isDelete = true
    },
    deleteElement () {
      this.isDelete = true
      this.$nextTick(() => {
        this.submit()
      })
    },
    reset () {
      this.isLoading = false
      this.isDelete = false
      this.failureText = undefined
      this.mode = this.defaultMode || 'form'
      this.data = this.formData ? { ...this.formData } : {}
      this.submittedVerb = undefined
    }
  },
  mounted () {
    if (this.data) {
      this.data = { ...this.data }
      delete this.data.lastModified
      delete this.data.lastModifiedBy
      delete this.data.createdAt
      delete this.data.createdBy
    }
  },
  components: { Modal, ElementIdRow, SimpleDateTimePicker, TextInputRow,
    PillboxRow, TextLabelRow, LastModifiedRow, SelectRow, SectionHeading,
    CheckboxInputRow, TextContent, TextAreaRow, IndexSearchRow, ButtonsRow }
}
</script>
<style scoped>
  .submit-confirm-delete-pane {
    min-height: 430px;
    margin: 20px 15px;
    text-align: center;
  }
  .submit-result-status-pane {
    min-height: 430px;
    margin-top: 55px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-enlarge-3);
  }
  .element-id-row {
    color: var(--app-subtle-color);
    padding: 0px 5px 5px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--form-control-border-color);
    margin-top: -3px;

    /* ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle */
  }
</style>
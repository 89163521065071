<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <div class="col-auto m-0 p-0" v-else-if="device">
    <InfoPane
      class="dt-info-pane-sm"
      :title="device.id"
      :actionFunc="canUpdateDevices ? showEditor : undefined"
      actionIcon="pen-to-square"
      itemType="Device"
    >
      <template v-slot:info>
        <Modal ref="editModal" :deviceData="device" :customerID="this.customer ? this.customer.id : undefined" />
        <ModalChangeLog ref="changeLogModal" elementType="element" :displayName="device.id" :elementId="device.id" />
        <ModalInstallWizard ref="installWizard"
          v-if="customer"
          :device="device"
          :customer="customer.id"
          :action="installAction"
          :site="site"
          :node="deviceNode"
        />
        <SenetWidget ref="senetWidget"
          :deviceEUI="device.id"
          :appKey="device.applicationKey"
          :networkZone="device.networkZone"
          v-if="device"
        />

        <ul class="info-pane-list">

          <li class="info-pane-list-item d-flex align-items-center warning node-notes"
            v-if="device.reportingStatus && device.reportingStatus === 'warning' && device.statusDetail">
              {{ device.statusDetail }}
          </li>

          <li class="info-pane-list-item d-flex align-items-center"  v-if="device.status === 'inactive'">
            <div class="col-auto node-inactive-header">
              INACTIVE
            </div>
          </li>

          <li class="info-pane-list-item" v-if="customer && customer.highPriority">
            <div class="list-heading-label gold d-flex align-items-center">
              <fa icon="star" class="button-icon gold me-2" />
              High Priority Customer
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center">
            <span class="list-heading-label me-2">Production/Monitored:</span>
            <span v-if="device.site && !device.monitoringMuted">
              <fa icon="check" class="flash-icon-svg me-2" />
            </span>
            <span v-else>
              <fa icon="xmark" class="map-icon status-warning" />
            </span>
          </li>

          <li class="info-pane-list-item align-items-center" v-if="device.status === 'active'">
            <div class="node-general-header-value" v-if="prettyInstallStatus">
              <div class="node-general-header-auto">
                Install Status:
              </div>
              <div class="node-general-header-value">
                <span :class="{ 'status-caution': prettyInstallStatus.startsWith('Unknown')}">{{ prettyInstallStatus }}</span>
                <fa icon="wrench" class="button-icon" title="Install/Uninstall Device" alt="Install/Uninstall Device" @click="showInstallWizard" v-if="canUpdateDevices" />
              </div>
            </div>
            <div v-if="device.installedBy || prettyInstallDate" class="subtle font-reduce-1 ms-2">
              <span v-if="device.installedBy">by {{ device.installedBy }} </span>
              <span v-if="prettyInstallDate"> on {{ prettyInstallDate }}</span>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.notes">
            <div class="col node-notes node-device-notes">
              <pre class="notes">{{ device.notes }}</pre>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center"  v-if="site && site.notes">
            <div class="col node-notes node-site-notes">
              <pre class="notes">{{ site.notes }}</pre>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center"  v-if="customer && customer.notes">
            <div class="col node-notes node-customer-notes">
              <pre class="notes">{{ customer.notes }}</pre>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" >
            <div class="col node-general-header-105">
              Site:
            </div>
            <div class="col-auto">
              <router-link class="padded" :class="{'inactive': site && site.status === 'inactive'}"
                :to="{  name: 'site-details', params: { id: device.site } }">
                {{ siteDisplayName }}
              </router-link>
              <span class="inactive" v-if="site && site.status === 'inactive'"> (inactive)</span>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.node">
            <div class="col-auto node-general-header-105">
              Node:
            </div>
            <div class="col-auto">
                {{ nodeDisplayName }}
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center"
            v-if="(!device.noSoilProbe) && device.probeDepth && device.probeDepth.toLowerCase() != 'n/a'">
            <div class="col-auto node-general-header-auto">
              Soil Probe Depth:
            </div>
            <div class="col-auto" v-if="device.probeDepth.toLowerCase() != 'unknown'">
                {{ device.probeDepth }}cm ({{  (device.probeDepth * 0.39370079).toFixed(1) }}")
            </div>
            <div class="col-auto notable" v-else>
                {{ device.probeDepth }}
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.manufacturer || device.model">
            <div class="col-auto node-general-header-105">
              Make/Model:
            </div>
            <div class="col-auto font-reduce-1">
              {{ device.manufacturer }}{{ device.manufacturer && device.model ? ' / ' : '' }}{{ device.model }}{{ device.noSoilProbe ? ' / No Soil Probe' : '' }}
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center"
            v-if="device.position">
            <div class="col-auto node-general-header-105">
              Position:
            </div>
            <div class="col-auto">
                {{ device.position }}
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.serial">
            <div class="col-auto node-general-header-105">
              Serial #:
            </div>
            <div class="col-auto">
                {{ device.serial }}
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.swVersionCurrent">
            <span class="list-heading-label me-2">Firmware Version:</span>
            <span :class="{'status-warning': device.swVersionCurrent == 'unknown' }">
              {{ device.swVersionCurrent }}
              <span class="ms-3" v-if="device.swVersionTarget && device.swVersionTarget != 'default'">
                ( => {{ device.swVersionTarget }})
              </span>
            </span>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.networkServer !== 'n/a'">
            <div class="col-auto node-general-header-105">
              LoRa Server:
            </div>
            <div class="col-auto" v-if="device.networkServer">
                {{ device.networkServer }}
                <span v-if="device.networkServer === 'senet' && device.networkZone">
                  &nbsp;/&nbsp;
                  {{ device.networkZone }}
                </span>
            </div>
            <div class="col-auto" v-if="!device.networkServer">
              <span class="status-danger">
                <fa icon="triangle-exclamation" class="map-icon status-danger me-1" style="margin-top:-2px;" />
                Information Needed
              </span>
            </div>
            <div class="col d-flex justify-content-end" v-if="device.networkServer === 'senet'">
              <span>
                <a @click="showSenetWidget()">
                  <fa icon="wrench" class="button-icon" />
                </a>
              </span>
            </div>
          </li>

          <li class="info-pane-list-item d-flex align-items-center" v-if="device.networkServer && device.networkServer !== 'n/a'">
            <div class="col-auto node-general-header-105">
              App Key:
            </div>
            <div class="col-auto subtle-info" v-if="device.applicationKey">
                *****{{ getLast4(device.applicationKey.toLowerCase()) }}
            </div>
            <div class="col-auto" v-else>
              <span class="status-danger">
                <fa icon="triangle-exclamation" class="map-icon status-danger me-1" style="margin-top:-2px;" />
                Information Needed
              </span>
            </div>
          </li>

          <li class="info-pane-list-item" v-if="device.lastModified">
            <fa icon="clock-rotate-left" class="button-icon ps-1 me-2" title="Change Log" alt="Change Log" @click="showChangeLogModal"/>
            <span class="subtle-info status-last-modified">
              {{ getSinceSecs(device.lastModified) }}
            </span>
            <span class="subtle-info font-reduce-2" v-if="device.lastModifiedBy"> by {{ device.lastModifiedBy }}</span>
          </li>
        </ul>

      </template>
    </InfoPane>

    <AlertsByN entityType="Device" class="pt-3" />

  </div>

  <div class="col-auto d-none d-md-block"></div>

  <div class="col-auto component-pane mx-0 px-0" v-if="!majorError">

    <div class="container info-table pt-0 mb-4" v-if="device">

      <div class="title-row">
        <h5 class="dt-title pt-1">Most Recent Device Uplink</h5>
      </div>

      <div class="row info-table-row mt-1">
        <div class="col info-table-label">
          <span>Device ID</span>
        </div>
        <div class="col info-table-content">
          <span>{{ hypenateDeviceId(device.id) }}</span>
        </div>
      </div>

      <div class="row info-table-row" v-if="device.gw_device_id">
        <div class="col info-table-label">
          <span>Gateway ID</span>
        </div>
        <div class="col info-table-content">
          <router-link class="padded"
            :to="{  name: 'gateway-details', params: { id: device.gw_device_id } }">
            {{ device.gw_device_id }}
          </router-link>
        </div>
      </div>

      <hr class="info-sep"/>

      <div class="row info-table-row">
        <div class="col info-table-label">
          <span>Report Time</span>
        </div>
        <div class="col info-table-content">
          <span>{{ formattedReportTime }}</span>
          <span style="padding-left: 10px"
            :class="{'status-error': !device.monitoringMuted && isTimeAlertable(device.report_time, device.model), 'status-caution': !device.monitoringMuted && isTimeCautionable(device.report_time, device.model) }"
            >({{ getSince(device.report_time) }})
          </span>
        </div>
      </div>

      <hr class="info-sep" v-if="device.freq" />

      <div class="row info-table-row" v-if="device.freq">
        <div class="col info-table-label">
          <span>Frequency</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.freq }}</span>
        </div>
      </div>

      <div class="row info-table-row" v-if="device.port">
        <div class="col info-table-label">
          <span>Port</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.port }}</span>
        </div>
      </div>

      <div class="row info-table-row" v-if="device.rssi">
        <div class="col info-table-label">
          <span>RSSI</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.rssi }}</span>
        </div>
      </div>

      <div class="row info-table-row" v-if="device.seqno">
        <div class="col info-table-label">
          <span>SeqNo</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.seqno }}</span>
        </div>
      </div>

      <div class="row info-table-row" v-if="device.snr">
        <div class="col info-table-label">
          <span>SNR</span>
        </div>
        <div class="col info-table-content">
          <span>{{ device.snr }}</span>
        </div>
      </div>

      <hr class="info-sep" v-if="device.data"/>

      <div class="row justify-content-center info-table-row info-table-solo-row" v-if="device.data">
        <div class="col-auto hex-payload">
          <ElementIdRow
            label="Payload"
            :hideLabel="true"
            :copyValue="device.data"
            :value="payloadDisplay"
            :disableVariableSize="true"
          />
        </div>
      </div>

      <div class="row info-table-row mb-3" v-if="device.data && (!device.model || !pairs || pairs.length == 0)">
        &nbsp; Set the Manufacturer + Model to decode data
      </div>

      <hr class="info-sep" v-else-if="device.data" />

      <div class="row info-table-row" v-for="(key, value) in pairs" :value="key" :key="value" >
        <div class="col info-table-label" style="width:200px !important;min-width:200px;max-width:200px;">
          <span>{{ value }}</span>
        </div>
        <div class="col info-table-content">
          <span>{{ key.Value }}</span><span class="subtle font-reduce-2" v-if="key.Units">&nbsp;{{ key.Units }}</span>
        </div>
      </div>

    </div>

    <InfoPane title="Device QR Code" v-if="device">
      <template v-slot:info>
        <QR :value="device.id" />
      </template>
    </InfoPane>

  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import BasicButton from '@/components/controls/BasicButton.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'
import SenetWidget from '@/components/fleet/SenetWidget.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import QR from '@/components/tools/QRCodeWidget.vue'
import Modal from '@/components/fleet/ModalAddUpdateDevice.vue'
import ModalChangeLog from '@/components/modals/ModalChangeLog.vue'
import ModalInstallWizard from '@/components/fleet/DeviceInstallations/InstallWizard.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import { statuses } from '@/components/fleet/DeviceInstallations/validation.ts'

import FleetDataService from '@/services/fleet/FleetDataService'
import DecoderService from '@/services/decoders/DecoderService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import NavigationService from '@/services/NavigationService'
import auth from '@/app/auth'

export default {
  name: 'device',
  extends: BaseDeviceComponent,
  components: { AlertsByN, SenetWidget, InfoPane, QR, Modal, ModalChangeLog, ElementIdRow, BasicButton, ModalInstallWizard },
  data () {
    return {
      pairs: {},
      siteNodes: null,
      site: null,
      customer: null
    }
  },
  computed: {
    canUpdateDevices () {
      return auth.userHasPrivilege('devices', 'update', this.site ? this.site.customer : 'XXX' )
    },
    prettyInstallDate () {
      if (!this.device.installationDate) {
        return undefined
      }
      const d = new Date(this.device.installationDate * 1000)
      return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`
    },
    prettyInstallStatus () {
      if (!this.device || !this.device.installationStatus) {
        return 'Unknown'
      }
      const lkp = statuses()
      if (this.device.installationStatus in lkp) {
        return lkp[this.device.installationStatus]
      }
      return 'Unknown'
    },
    isInstalled () {
      return this.device && this.device.installationStatus === 'installed'
    },
    installAction () {
      return this.isInstalled ? 'uninstall' : 'install'
    },
    payloadDisplay () {
      if (!this.device || !this.device.data) {
        return ''
      }
      if (this.device.data.length > 54) {
        return this.device.data.substring(0, 42) + '... '
      }
      return this.device.data
    },
    siteDisplayName () {
      if (!this.device) {
        return ''
      }
      return FleetDataService.getSiteDisplayName(this.device.site)
    },
    deviceNode () {
      if (!this.device || !this.device.node || !this.siteNodes ||
        this.siteNodes.length === 0) {
        return undefined
      }
      for (const node of this.siteNodes) {
        if (node.id === this.device.node) {
          return node
        }
      }
      return undefined
    },
    nodeDisplayName () {
      if (!this.deviceNode) {
        return ''
      }
      return this.deviceNode.displayName
    },
    formattedReportTime () {
      if (this.device) {
        if (this.device.report_time_hr) {
          return this.device.report_time_hr.replace(' UTC', 'Z')
        } else {
          return '--'
        }
      }
      return ''
    }
  },
  methods: {
    showInstallWizard () {
      this.$refs.installWizard.show()
    },
    showEditor () {
      this.$refs.editModal.show()
    },
    retrieveSiteNodes (id) {
      this.isLoading = true
      FleetDataService.getSiteNodes(id)
        .then(response => {
          this.siteNodes = response
        })
        .catch(e => {
          let message = 'Failed to load Site Nodes'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showSenetWidget () {
      this.$refs.senetWidget.show()
    },
    showChangeLogModal () {
      this.$refs.changeLogModal.show()
    },
    getCustomer (id) {
      this.isLoading = true
      FleetDataService.getCustomer(id)
        .then(response => {
          this.customer = response.data
        })
        .catch(e => {
          let message = 'Failed to load device\'s customer info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSite (id, deviceId) {
      this.isLoading = true
      FleetDataService.getSite(id)
        .then(response => {
          this.site = response
          NavigationService.setNavLevel4(
            'Fleet', FleetDataService.getCustomerDisplayName(response.customer),
            response.displayName, deviceId,
            '#/fleet', '#/customers/' + response.customer,
            '#/sites/' + response.id, ''
          )
          this.getCustomer(this.site.customer)
        })
        .catch(e => {
          let message = 'Failed to load device\'s site info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getDevice (id) {
      id = id.toUpperCase()
      this.isLoading = true
      FleetDataService.getDevice(id)
        .then(response => {
          this.device = response.data
          if (this.device.site) {
            this.retrieveSiteNodes(this.device.site)
            this.getSite(this.device.site, id)
          }
          let data = this.device.data
          if (this.device.notes && this.device.notes.includes('tare=')) {
            const x = this.device.notes.indexOf('tare=')
            if (x > 1) {
              data = data + '|' + this.device.notes.substring(x)
            }
          }
          this.pairs = DecoderService.decode(this.device.model, data)
        })
        .catch(e => {
          let message = 'Failed to retrieve Device info.'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getDeviceFromParams () {
      var id = this.$route.params.id
      id = id.replaceAll(' ', '').replaceAll('-', '').replaceAll(':', '')
      if (id !== this.$route.params.id) {
        this.$router.replace({ name: 'device-details', params: { id: id } })
      }
      this.getDevice(id)
    }
  },
  unmounted () {
    Reloadables.unregisterReloadable('device-info-telemetry')
  },
  mounted () {
    Reloadables.registerReloadable('device-info-telemetry', this.getDeviceFromParams)
    this.$nextTick(() => {
      this.getDeviceFromParams()
    })
  }
}
</script>

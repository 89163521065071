<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="caseData && isBlocking" @close="close()" sz="auto" :showSpinner="isLoading" heightClass="case-viewer-height">
      <template v-slot:header>
        Maintenance Case Details
      </template>
      <template v-slot:body>
        <template v-if="viewMode === 'main'">
          <CaseViewer :caseData="caseData" />
        </template>
      </template>
      <template v-slot:footer>
        <BasicButton color="blue" class="ms-3" :action="close" caption="Close" :disabled="isClosing || isLoading" v-if="!isDeleting" />
      </template>
    </Modal>
  </transition>
</template>
<script>

import Modal from '@/components/modals/Modal.vue'
import CaseViewer from '@/components/fleet/Maintenance/CaseViewer.vue'
import BasicButton from '@/components/controls/BasicButton.vue'

export default {
  name: 'maintenance-case-viewer',
  props: ['caseData'],
  emits: ['requestCaseDataRefresh'],
  data () {
    return {
      viewMode: 'main',
      isLoading: false,
      isBlocking: false,
      isClosing: false,
      oldRoute: undefined
    }
  },
  methods: {
    reset () {
      this.viewMode = 'main'
      this.isLoading = false
      this.isClosing = false
      this.isDeleting = false
      this.oldRoute = undefined
    },
    setViewPath () {
      let path = this.$route.href
      let st = path.indexOf('/maint')
      if (st > 0) {
        path = path.substring(0, st + 6)
      }
      this.oldRoute = path
      if (this.caseData && this.caseData.id) {
        history.pushState(
          {},
          null,
          `${path}/case/${this.caseData.id}`
        )
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
      if (this.caseData) {
        this.setViewPath()
      }
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      if (this.oldRoute) {
        history.pushState(
          {},
          null,
          this.oldRoute
        )
      }
    }
  },
  components: { Modal, CaseViewer, BasicButton }
}
</script>

<template>
  <InfoPane title="Files"
    :actionFunc="canEdit ? showAddNewFileDialog : undefined"
    actionLabel="Upload File" 
    actionIcon="plus"
    :wide="true"
  >
    <template v-slot:info>
      <div class="file-manager">
        <Toolbar :bottomBorder="true">
          <template v-slot:sections>
            <ToolbarSection label="Layout:">
              <template v-slot:buttons>
                <ToolbarButton icon="grip" :isActive="viewMode === 'thumbnails'" @click="() => { viewModeChanged('thumbnails') }" :isSingletonGroupMember="true" />
                <ToolbarButton icon="list" :isActive="viewMode === 'list'" @click="() => { viewModeChanged('list') }" :isSingletonGroupMember="true" />
              </template>
            </ToolbarSection>
            <ToolbarSeparator />
            <ToolbarSection label="Sort:">
              <template v-slot:buttons>
                <ToolbarButton label="Name" :isActive="sortMode === 'name'" @click="() => { sortModeChanged('name') }" :isSingletonGroupMember="true" />
                <ToolbarButton label="Date" :isActive="sortMode === 'date'" @click="() => { sortModeChanged('date') }" :isSingletonGroupMember="true" />
              </template>
            </ToolbarSection>
            <ToolbarSeparator class="d-none d-xl-block d-lg-block d-md-block d-sm-block" />
            <ToolbarSection label="Show:" class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex" labelClass="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
              <template v-slot:buttons>
                <ToolbarButton label="All" :isActive="filter.includes('all')" @click="() => { filterChanged('all')}" :isSingletonGroupMember="true" />
                <ToolbarButton icon="image" :isActive="!filter.includes('all') && filter.includes('img')"  @click="() => { filterChanged('img')}" />
                <ToolbarButton label="CSV" :isActive="!filter.includes('all') && filter.includes('csv')"  @click="() => { filterChanged('csv')}" />
                <ToolbarButton label="PDF" :isActive="!filter.includes('all') && filter.includes('pdf')"  @click="() => { filterChanged('pdf')}" />
                <ToolbarButton icon="video" :isActive="!filter.includes('all') && filter.includes('vid')"  @click="() => { filterChanged('vid')}" />
              </template>
            </ToolbarSection>
          </template>
        </Toolbar>

        <FileManagerList
          class="file-manager-body"
          :sortMode="sortMode"
          :viewMode="viewMode"
          :fileTypeFilter="filter"
          :files="files"
          :showEditorFunc="canEdit ? showEditFileDialog : undefined"
          ref="fileManagerList"
          :fileId="viewFileId"
          :isLoading="isLoading"
        />
      </div>
    </template>
  </InfoPane>
  <ModalUploadFile ref="modalUploadFile"
      :scope="scope"
      :target="target"
      :site="site"
      :nodes="nodes"
      :customer="customer"
      :devices="devices"
      :gateways="gateways"
  />
  <ModalEditFile ref="modalEditFile"
      :scope="scope"
      :target="target"
      :site="site"
      :nodes="nodes"
      :customer="customer"
      :devices="devices"
      :gateways="gateways"
      :file="showEditorFile"
  />
</template>
<script>
import auth from '@/app/auth'
import InfoPane from '@/components/tables/InfoPane.vue'
import ModalUploadFile from '@/components/controls/FileManager/ModalUploadFile.vue'
import ModalEditFile from '@/components/controls/FileManager/ModalEditFileAttributes.vue'
import FileManagerList from '@/components/controls/FileManager/FileManagerList.vue'
import Toolbar from '@/components/controls/Toolbar/Toolbar.vue'
import ToolbarSection from '@/components/controls/Toolbar/ToolbarSection.vue'
import ToolbarButton from '@/components/controls/Toolbar/ToolbarButton.vue'
import ToolbarSeparator from '@/components/controls/Toolbar/ToolbarSeparator.vue'

export default {
  name: 'file-manager',
  components: { InfoPane, Toolbar, ToolbarSection, ToolbarButton, ToolbarSeparator, ModalUploadFile, ModalEditFile, FileManagerList },
  props: ['isLoading', 'scope', 'target', 'site', 'nodes', 'customer', 'devices', 'gateways', 'files', 'fileId'],
  data () {
    return {
      changedView: '',
      changedSort: '',
      filter: 'all|',
      showEditorFile: undefined
    }
  },
  watch: {
    files: {
      handler () {
        this.checkDeepLink()
      }
    }
  },
  computed: {
    canEdit () {
      if (!this.customer) {
        return false
      }
      return auth.userHasPrivilege('sites', 'view', this.customer)
    },
    viewFileId () {
      if (!this.fileId) {
        return undefined
      }
      if (!this.$route.path.endsWith('/view')) {
        return undefined
      }
      return this.fileId
    },
    viewMode () {
      if (this.changedView) {
        return this.changedView
      }
      const viewMode = localStorage.getItem('fileManager.viewMode')
      if (viewMode) {
        return viewMode
      }
      return 'thumbnails'
    },
    sortMode () {
      if (this.changedSort) {
        return this.changedSort
      }
      const sortMode = localStorage.getItem('fileManager.sortMode')
      if (sortMode) {
        return sortMode
      }
      return 'name'
    }
  },
  methods: {
    showViewerForFileId (fileId) {
      this.$refs.fileManagerList.showViewerForFileId(fileId)
    },
    checkDeepLink () {
      if (this.fileId && this.files && this.files.length > 0) {
        for (const file of this.files) {
          if (file.id === this.fileId) {
            if (this.$route.path.endsWith('/edit')) {
              this.showEditFileDialog(file)
            }
            break
          }
        }
      }
    },
    viewModeChanged (input) {
      this.changedView = input
      localStorage.setItem('fileManager.viewMode', input)
    },
    sortModeChanged (input) {
      this.changedSort = input
      localStorage.setItem('fileManager.sortMode', input)
    },
    filterChanged (input) {
      if (input === 'all') {
        if (this.filter === 'all|') {
          return
        }
        this.filter = 'all|'
        return
      }
      if (this.filter.includes(input + '|')) {
        if (this.filter.includes('all|')) {
          this.filter = input + '|'
          return
        }
        this.filter = this.filter.replaceAll(input + '|', '')
        if (!this.filter) {
          this.filter = 'all|'
        }
      } else {
        this.filter = this.filter.replaceAll('all|', '')
        this.filter += input + '|'
      }
    },
    showAddNewFileDialog () {
      this.$refs.modalUploadFile.show()
    },
    showEditFileDialog (file) {
      this.showEditorFile = file
      this.$nextTick(() => {
        this.$refs.modalEditFile.show()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkDeepLink()
    })
  }
}
</script>
<style scoped>
  .file-manager .file-manager-opts-row {
    display: flex;
    border-bottom: 1px var(--form-control-border-color) solid;
    justify-content: center;
    height: 40px;
  }

  .file-manager .file-manager-body {
    padding: 0px;
  }
</style>
<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" :sz="modalSz">
      <template v-slot:header>
        <span class="font-reduce-1">{{ headerText }}</span>
      </template>

      <template v-slot:body>
        <div v-if="isLoading" class="p-3 ps-4 pb-4">
          Loading ...
        </div>
        <div v-else-if="changeLog.length === 0" class="p-3 ps-4 pb-4">
          No Change Log Entries Available
        </div>
        <div class="change-log-card-list" v-else>
          <div class="change-log-card" v-for="(entry, i) in changeLogWithDisplayNames" :key="i">
            <div class="change-log-card-highlight d-flex justify-content-between mb-1">
              <div>
                {{  timeToDate(entry.ts)  }}
              </div>
              <div>
                {{ entry.principal }}
              </div>
            </div>
            <div class="my-1">
              <span class="change-log-card-header me-1">Element:</span>
              <span class="me-1">{{ entry.elementType }}</span>
              <span class="bold" v-if="!entry.elementName">{{  entry.element }}</span>
              <span v-else>
                <span class="bold me-1">{{ entry.elementName }}</span>
                <span class="subtle">{{ entry.element }}</span>
              </span>
            </div>
            <div class="change-log-card-details"><span class="change-log-card-header">Details:</span> {{  entry.detail  }}</div>
          </div>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/modals/Modal.vue'

export default {
  name: 'modal-change-log',
  props: ['elementType', 'elementId', 'displayName'],
  data () {
    return {
      isLoading: true,
      isBlocking: false,
      changeLog: [],
      modalSz: 'sm'
    }
  },
  computed: {
    changeLogWithDisplayNames () {
      const out = []
      for (const entry of this.changeLog) {
        const dupe = { ...entry }
        dupe.elementName = this.getEntryDisplayName(dupe)
        dupe.detail = this.addDetailDisplayNames(dupe.detail)
        out.push(dupe)
      }
      return out
    },
    headerText () {
      return 'Change Log: ' + this.displayName
    }
  },
  methods: {
    addDetailDisplayNames (detail) {
      let out = detail
      out = this.addDetailDisplayName(out, 'site')
      out = this.addDetailDisplayName(out, 'node')
      out = this.addDetailDisplayName(out, 'customer')
      out = this.addDetailDisplayName(out, 'vent')
      out = this.addDetailDisplayName(out, 'ventType')
      out = this.addDetailDisplayName(out, 'chamber')
      out = this.addDetailDisplayName(out, 'chamberType')
      out = this.addDetailDisplayName(out, 'layout')
      return out
    },
    addDetailDisplayName (detail, elementType) {
      let out = detail
      let i = out.indexOf(`set [${elementType}] to [`)
      if (i >= 0) {
        let id = ''
        let testPortion = out.substring(i + 11 + elementType.length)
        i = testPortion.indexOf(']')
        if (i >= 0) {
          id = testPortion.substring(0, i)
          if (id) {
            const dn = FleetDataService.getDisplayNameByElement(elementType.replace('Type', ''), id)
            if (dn) {
              out = out.replace(`[${id}]`, `[${dn} / ${id}]`)
            }
          }
          i = testPortion.indexOf(' (was [')
          if (i >= 0) {
            testPortion = testPortion.substring(i + 7)
            i = testPortion.indexOf(']')
            if (i >= 0) {
              id = testPortion.substring(0, i)
              if (id) {
                const dn = FleetDataService.getDisplayNameByElement(elementType.replace('Type', ''), id)
                if (dn) {
                  out = out.replace(`[${id}]`, `[${dn} / ${id}]`)
                }
              }
            }
          }
        }
      }
      return out
    },
    getEntryDisplayName (entry) {
      if (!entry.element || !entry.elementType) {
        return ''
      }
      if (['node', 'site', 'customer'].includes(entry.elementType)) {
        return FleetDataService.getDisplayNameByElement(entry.elementType, entry.element)
      }
    },
    compare (a, b) {
      if (a.ts > b.ts) {
        return -1
      }
      if (a.ts < b.ts) {
        return 1
      }
      return 0
    },
    checkSize () {
      if (window.innerWidth < 761) {
        this.modalSz = 'sm'
      } else if (window.innerWidth < 951) {
        this.modalSz = 'md'
      } else {
        this.modalSz = 'lg'
      }
    },
    timedCheckSize () {
      if (!this.isBlocking) {
        return
      }
      this.checkSize()
      setTimeout(this.timedCheckSize, 150)
    },
    timeToDate (input) {
      var d = new Date(0)
      d.setUTCSeconds(input)
      return d.toLocaleString()
    },
    reset () {
      this.changeLog = []
    },
    show () {
      this.reset()
      this.isLoading = true
      this.isBlocking = true
      this.retrieveChangeLog()
      this.$nextTick(() => {
        this.timedCheckSize()
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    retrieveChangeLog () {
      this.isLoading = true
      FleetDataService.listChangeLogBy(this.elementType, this.elementId)
        .then(response => {
          this.changeLog = response.data
          this.changeLog.sort(this.compare)
        })
        .catch(e => {
          let message = 'Failed to load Change Log'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal }
}
</script>
<style scoped>
.change-log-card:first-child {
    padding-top: 0px;
    border-top: none;
}

.change-log-card {
    border-top: 2px var(--form-control-border-color) solid;
    padding: 7px 10px 10px 10px;
    font-size: var(--font-reduce-1);
}

.change-log-card-details {
    white-space:break-spaces;
}

.change-log-card-list {
    min-height: 100px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
}

.change-log-card-highlight {
    color: var(--app-highlight-color);
    font-weight: 500;
}

.change-log-card-header {
    font-weight: 500;
    color: var(--info-label-color);
}
</style>
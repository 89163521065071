<template>
    <ul class="nav nav-tabs">
        <li class="nav-item" v-if="hasFleetPerms">
            <router-link :to="{  path: '/fleet-map' }" class="nav-link" v-bind:class="{'active': activeTab === 'map'}">Map</router-link>
        </li>
        <li class="nav-item d-none d-md-block" v-if="hasDevices">
            <router-link :to="{  name: 'device-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'devices'}">Devices</router-link>
        </li>
        <li class="nav-item d-md-none" v-if="hasDevices">
            <router-link :to="{  name: 'device-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'devices'}">Dev</router-link>
        </li>
        <li class="nav-item d-none d-md-block" v-if="hasSites">
            <router-link :to="{  name: 'site-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'sites'}">Sites</router-link>
        </li>
        <li class="nav-item d-md-none" v-if="hasSites">
            <router-link :to="{  name: 'site-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'sites'}">Site</router-link>
        </li>
        <li class="nav-item d-none d-md-block" v-if="hasCustomers">
            <router-link :to="{  name: 'customer-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'customers'}">Customers</router-link>
        </li>
        <li class="nav-item d-md-none" v-if="hasCustomers">
            <router-link :to="{  name: 'customer-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'customers'}">Cust</router-link>
        </li>
        <li class="nav-item d-none d-md-block" v-if="hasGateways">
            <router-link :to="{  name: 'gateway-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'gateways'}">Gateways</router-link>
        </li>
        <li class="nav-item d-md-none" v-if="hasGateways">
            <router-link :to="{  name: 'gateway-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'gateways'}">GW</router-link>
        </li>
        <!-- <li class="nav-item d-md-none" v-if="hasFleetPerms">
            <router-link :to="{  name: 'maint-main' }" class="nav-link" v-bind:class="{'active': activeTab === 'maint'}">Maint</router-link>
        </li>
        <li class="nav-item d-none d-md-block" v-if="hasFleetPerms">
            <router-link :to="{  name: 'maint-main' }" class="nav-link" v-bind:class="{'active': activeTab === 'maint'}">Maintenance</router-link>
        </li> -->
        <li class="nav-item" v-if="hasFleetPerms">
            <router-link :to="{  name: 'fleet-misc' }" class="nav-link" v-bind:class="{'active': activeTab === 'misc'}">Misc</router-link>
        </li>
    </ul>
</template>
<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
export default {
  data () {
    return {
      activeTab: this.activeTabName
    }
  },
  extends: BaseFleetComponent,
  props: ['activeTabName']
}
</script>

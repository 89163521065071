<template>
  <TabBar activeTabName="maint" class="mb-2" />
  <div style="margin-left: -8px; margin-right: -8px;">
    <FilterRow ref="filterSettingsRow" @updateFilterSettings="updateFilterSettings" @updateViewMode="updateViewMode" :viewMode="viewMode" />
    <CaseMap
      :caseList="filteredCaseList"
      @showCaseEditor="showCaseEditor"
      :loading="isLoading"
      v-if="viewMode === map"
    />
    <CaseList
      :caseList="filteredCaseList"
      @showAddCase="showNewCaseModal"
      @showCaseViewer="showCaseEditor"
      :loading="isLoading"
      v-else
    />
  </div>
  <ModalAddUpdateCase ref="modalAddUpdateCase" :caseData="caseData" @requestCaseDataRefresh="loadCaseData" />
  <ModalCaseViewer ref="modalCaseViewer" :caseData="caseData" />
</template>

<script>
import Reloadables from '@/services/reloadables'
import TabBar from '@/components/fleet/TabBar.vue'
import FilterRow from '@/components/fleet/Maintenance/FilterRow.vue'
import CaseList from '@/components/fleet/Maintenance/CaseList.vue'
import CaseMap from '@/components/fleet/Maintenance/CaseMap.vue'
import ModalAddUpdateCase from '@/components/fleet/Maintenance/ModalAddUpdateCase.vue'
import ModalCaseViewer from '@/components/fleet/Maintenance/ModalCaseViewer.vue'
import api from '@/components/fleet/Maintenance/api'
import HelpContent from '@/services/HelpContent'
import { loadFilter, All, Self, Other, Customer, Site, Gateway, Device, Open, Resolved, Deleted } from '@/components/fleet/Maintenance/filters.ts'

const List = 'list'
const Map = 'map'
const PageName = 'maint-main'

const ViewModeKey = 'maint.viewMode'

export default  {
  name: PageName,
  components: { TabBar, FilterRow, CaseList, CaseMap, ModalAddUpdateCase, ModalCaseViewer },
  data () {
    return {
      filter: loadFilter(this.$route.query, this.$state.email),
      viewMode: localStorage.getItem(ViewModeKey) || List,
      caseData: undefined,
      caseList: undefined,
      isLoading: false,
      caseId: this.$route.params.caseId,
    }
  },
  computed: {
    map () {
      return Map
    },
    filteredCaseList () {
      if (!this.filter || !this.caseList || this.caseList.length === 0) {
        return this.caseList
      }
      const out = []
      for (const c of this.caseList) {
        if (this.filter.assigneeMode === Self && c.assignee !== this.$state.email) {
          continue
        }
        if (this.filter.assigneeMode === Other && this.filter.assigneeEmail && c.assignee !== this.filter.assigneeEmail) {
          continue
        }
        if ([Open, Resolved, Deleted].includes(this.filter.statusMode) && c.status !== this.filter.statusMode) {
          continue
        }
        if (this.filter.elementType === Customer && this.filter.elementId && c.customerID !== this.filter.elementId) {
          continue
        }
        if (this.filter.elementType === Site && this.filter.elementId && c.siteID !== this.filter.elementId) {
          continue
        }
        if (this.filter.elementType === Device && this.filter.elementId && c.elementID !== this.filter.elementId) {
          continue
        }
        if (this.filter.elementType === Gateway && this.filter.elementId && c.elementID !== this.filter.elementId) {
          continue
        }
        out.push(c)
      }
      return out
    }
  },
  methods: {
    loadCaseList () {
      let f = async () => { return api.listMaintenanceCasesByAssignee(this.$state.email) }
      if (this.filter) {
        if (this.filter.assigneeMode === Other && this.filter.assigneeEmail) {
          f = async () => { return api.listMaintenanceCasesByAssignee(this.filter.assigneeEmail) }
        } else if (this.filter.elementId && this.filter.elementType && this.filter.elementType !== All) {
          const elementId = this.filter.elementId
          if (this.filter.elementType === Site) {
            f = async () => { return api.listMaintenanceCasesBySite(elementId) }
          } else if (this.filter.elementType === Customer) {
            f = async () => { return api.listMaintenanceCasesByCustomer(elementId) }
          } else if ([Gateway, Device].includes(this.filter.elementType)) {
            f = async () => { return api.listMaintenanceCasesByElementID(elementId) }
          }
        }
      }
      this.isLoading = true
      f()
      .then(resp => {
        if (resp.data) {
          this.caseList = resp.data
        }
      })
      .catch(e => {
        HelpContent.setFlashMessage("Failed to load case list", true)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    loadCaseData (caseId, then = undefined) {
      if (!caseId) {
        return
      }
      api.getMaintenanceCaseById(caseId)
      .then(resp => {
        if (resp && resp.data) {
          this.caseData = resp.data
          if (then) {
            this.$nextTick(() => {
              then()
            })
          }
        }
      })
      .catch(e => {
        HelpContent.setFlashMessage(`Failed to retrieve data for Case ${this.caseId}`, true)
      })

    },
    checkDeepLink () {
      this.loadCaseData(this.caseId, () => { this.showCaseEditor() })
    },
    updateViewMode (mode) {
      this.viewMode = mode
      localStorage.setItem(ViewModeKey, mode)
    },
    updateFilterSettings () {
      this.filter = this.$refs.filterSettingsRow.filter
      this.$nextTick(() => {
        this.loadCaseList()
      })
    },
    showCaseEditor () {
      this.$refs.modalAddUpdateCase.show()
    },
    showCaseViewer () {
      if (this.$refs.modalCaseViewer) {
        this.$refs.modalCaseViewer.show()
      }
    },
    showNewCaseModal () {
      this.caseData = undefined
      this.$nextTick(() => {
        this.showCaseEditor()
      })
    },
    showCaseViewerModal (caseData) {
      this.caseData = caseData
      this.$nextTick(() => {
        this.showCaseEditor()
      })
    }
    // showEditCaseModal (caseData) {
    //   this.caseData = caseData
    //   this.$nextTick(() => {
    //     this.showCaseEditor()
    //   })
    // }
  },
  mounted () {
    Reloadables.registerReloadable(PageName, this.loadCaseList)
    this.$nextTick(() => {
      this.loadCaseList()
      this.checkDeepLink()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable(PageName)
  },
}
</script>
<style scoped>

</style>
<template>
  <div :class="mask ? 'context-menu-mask' : undefined" v-if="isVisible" id="context-menu-mask">
    <div class="context-menu-container" :class="containerClasses" :id="menuId">
      <slot name="menu" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'context-menu-container',
  data () {
    return {
      menuIdTimestamp: Date.now(),
      isVisible: false,
      isLooping: true,
      iteration: 0,
      left: 5
    }
  },
  props: ['posX', 'posY', 'posAnchorId', 'closer', 'containerClass', 'mask', 'bigShadow'],
  emits: ['contextMenuVisible', 'contextMenuHidden'],
  computed: {
    containerClasses () {
      let out = []
      if (this.bigShadow) {
        out.push('big-shadow')
      }
      if (this.containerClass) {
        out.push(this.containerClass)
      }
      return out.join(' ')
    },
    menuId () {
      return `context-menu-${this.menuIdTimestamp}`
    },
    isMenuVisible () {
      return this.isVisible
    }
  },
  methods: {
    toggle () {
      if (this.isVisible) {
        this.close()
      } else {
        this.open()
      }
    },
    open () {
      this.isVisible = true
      this.iteration = 0
      this.$nextTick(() => {
        this.$emit('contextMenuVisible')
        this.contextMenuPositionLoop()
      })
    },
    close () {
      this.$emit('contextMenuHidden')
      this.isVisible = false
      if (this.closer) {
        this.closer()
      }
    },
    contextMenuPositionLoop () {
      if (!this.isVisible || !this.isLooping || !this.posAnchorId) {
        return
      }
      this.positionContextMenu()
      this.iteration++
      if (this.iteration === 2) {
      }
      setTimeout(this.contextMenuPositionLoop, 150)
    },
    positionContextMenu () {
      const menu = document.getElementById(this.menuId)
      const anchor = document.getElementById(this.posAnchorId)
      const menuRect = menu.getBoundingClientRect()
      const anchorRect = anchor.getBoundingClientRect()

      const windowWidth = Math.floor(window.innerWidth)
      const windowHeight = Math.floor(window.innerHeight)

      // Calculate available space
      const spaceAbove = Math.floor(anchorRect.top)
      const spaceBelow = Math.floor(windowHeight - anchorRect.bottom)
      const spaceLeft = Math.floor(anchorRect.left)
      const spaceRight = Math.floor(windowWidth - anchorRect.right)

      // Determine the best position for the context menu
      let top, left

      // Priority: Try to position the menu below, then above, and so on
      if (spaceBelow >= menuRect.height) {
        // Position below
        top = anchorRect.bottom
        left = anchorRect.left // Position the menu to the left side of the anchor
      } else if (spaceAbove >= menuRect.height) {
        // Position above
        top = anchorRect.top - menuRect.height
        left = anchorRect.right // Position the menu to the right of the anchor
      } else if (spaceRight >= menuRect.width) {
        // Position to the right (default)
        top = anchorRect.top
        left = anchorRect.right
      } else if (spaceLeft >= menuRect.width) {
        // Position to the left
        top = anchorRect.top
        left = anchorRect.left - menuRect.width
      } else {
        // Fallback to positioning below if no space is available
        top = anchorRect.bottom
        left = anchorRect.right
      }
      // Ensure the menu stays within the viewport
      if (top + menuRect.height > windowHeight) {
        top = windowHeight - menuRect.height
      }
      if (left + menuRect.width > windowWidth) {
        left = anchorRect.left - menuRect.width + anchorRect.width
      }
      if (top < 0) {
        top = 0
      }
      if (left < 0) {
        left = 0
      }

      // Apply the calculated position
      menu.style.top = `${top}px`
      menu.style.left = `${left}px`
    }
  },
  unmounted () {
    this.isLooping = false
  }
}
</script>
<style scoped>

div.context-menu-container {
  position: fixed;
  z-index: 19;
  max-width: 370px;
  margin-left: 1px;
  padding: 0px;
  border-radius: 6px;
  background-color: var(--menu-bg-color);
  border: 1px var(--form-control-border-color) solid;
  transition: all 0.25s ease;
  font-size: var(--font-enlarge-2);
  min-width: 200px;
  box-shadow: 0px 0px 5px 3px #3333;
}

div.context-menu-container.big-shadow {
  box-shadow: 0 0 25px 15px var(--modal-shadow);
}

.context-menu-mask {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 12, 12, 0.6);
    display: table;
    transition: opacity 0.3s ease;
}

</style>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"color":"#666"} }
const _hoisted_2 = {
  key: 0,
  style: {"font-weight":"bold"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  style: {"font-weight":"bold"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 2,
  style: {"font-weight":"bold"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 3,
  style: {"font-weight":"bold"}
}
const _hoisted_14 = {
  key: 4,
  style: {"font-weight":"bold"}
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 5,
  style: {"font-weight":"bold"}
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 6,
  style: {"font-weight":"bold"}
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 7,
  style: {"font-weight":"bold"}
}
const _hoisted_23 = {
  key: 8,
  style: {"margin":"1px -5px 3px -5px"}
}
const _hoisted_24 = { key: 9 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = {
  key: 0,
  class: "bold",
  style: {"margin-right":"4px"}
}
const _hoisted_27 = {
  key: 1,
  class: "popup-link"
}
const _hoisted_28 = {
  key: 2,
  class: "popup-link"
}
const _hoisted_29 = { key: 5 }
const _hoisted_30 = {
  key: 2,
  class: "bold"
}
const _hoisted_31 = {
  key: 3,
  style: {"color":"#999 !important","border":"0px !important"}
}
const _hoisted_32 = { key: 10 }
const _hoisted_33 = ["src"]
const _hoisted_34 = {
  key: 0,
  class: "text-center"
}
const _hoisted_35 = {
  class: "btn-group no-border",
  role: "group",
  "aria-label": "User Search Filter",
  style: {"max-height":"27px !important","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mapbox_geolocate_control = _resolveComponent("mapbox-geolocate-control")!
  const _component_fa = _resolveComponent("fa")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mapbox_popup = _resolveComponent("mapbox-popup")!
  const _component_mapbox_marker = _resolveComponent("mapbox-marker")!
  const _component_mapbox_geogeometry_fill = _resolveComponent("mapbox-geogeometry-fill")!
  const _component_mapbox_geogeometry_polygon = _resolveComponent("mapbox-geogeometry-polygon")!
  const _component_mapbox_map = _resolveComponent("mapbox-map")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle('height:' + _ctx.mapHeight)
    }, [
      (((_ctx.markers && _ctx.markers.length > 0) || (_ctx.polygons && _ctx.polygons.length > 0)) && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_mapbox_map, {
            key: 0,
            ref: "mainMapControl",
            mapStyle: _ctx.mapBoxStyleURL,
            accessToken: _ctx.accessToken,
            height: _ctx.mapHeight,
            width: "100%",
            bounds: _ctx.mapBounds
          }, {
            default: _withCtx(() => [
              (_ctx.showGeolocation)
                ? (_openBlock(), _createBlock(_component_mapbox_geolocate_control, {
                    key: 0,
                    showUserHeading: true,
                    trackUserLocation: true,
                    showAccuracyCircle: true,
                    showUserLocation: true,
                    positionOptions: { enableHighAccuracy: true }
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (marker, i) => {
                return (_openBlock(), _createBlock(_component_mapbox_marker, {
                  lngLat: marker.coords,
                  key: i,
                  scale: 0.65
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_fa, {
                      icon: marker.icon || 'map-pin',
                      class: _normalizeClass(["map-icon", !marker.content || (!marker.content.titleLink && !marker.content.titleRoute && !marker.content.titleClickFunc) ? 'map-icon-noclick' : undefined]),
                      style: _normalizeStyle(`color:${marker.color}`)
                    }, null, 8, ["icon", "class", "style"])
                  ]),
                  default: _withCtx(() => [
                    (marker.content)
                      ? (_openBlock(), _createBlock(_component_mapbox_popup, {
                          key: 0,
                          offset: [0,-22],
                          maxWidth: "300px",
                          className: "map-popup"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              (marker.content.title && marker.content.titleLink)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    (marker.content.title && (marker.content.titleLinkText || marker.content.titleLinkIcon))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(marker.content.title), 1))
                                      : _createCommentVNode("", true),
                                    (!marker.content.titleLinkIcon)
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          class: "popup-link",
                                          href: marker.content.titleLink,
                                          style: {"font-weight":"normal"}
                                        }, _toDisplayString(marker.content.titleLinkText || marker.content.title), 9, _hoisted_4))
                                      : (_openBlock(), _createElementBlock("a", {
                                          key: 2,
                                          class: "popup-link",
                                          href: marker.content.titleLink,
                                          style: {"font-weight":"normal"}
                                        }, [
                                          _createVNode(_component_fa, {
                                            icon: marker.content.titleLinkIcon,
                                            class: "map-control-link-icon"
                                          }, null, 8, ["icon"])
                                        ], 8, _hoisted_5))
                                  ]))
                                : (marker.content.title && marker.content.titleRoute)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      (marker.content.title && marker.content.titleLinkText)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(marker.content.title) + ": ", 1))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_router_link, {
                                        class: "popup-link",
                                        to: {  name: marker.content.titleRoute, params: marker.content.titleRouteParams },
                                        style: {"font-weight":"normal"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(marker.content.titleLinkText || marker.content.title), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["to"])
                                    ]))
                                  : (marker.content.title && marker.content.titleClickFunc)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        (marker.content.title && marker.content.titleLinkText)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(marker.content.title) + ": ", 1))
                                          : _createCommentVNode("", true),
                                        (!marker.content.titleLinkIcon)
                                          ? (_openBlock(), _createElementBlock("a", {
                                              key: 1,
                                              class: "normal",
                                              style: {"font-weight":"normal"},
                                              onClick: marker.content.titleClickFunc
                                            }, _toDisplayString(marker.content.titleLinkText || marker.content.title), 9, _hoisted_10))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                              (marker.content.title)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(marker.content.title), 1))
                                                : _createCommentVNode("", true),
                                              _createElementVNode("a", {
                                                class: "normal",
                                                style: {"font-weight":"normal"},
                                                onClick: marker.content.titleClickFunc
                                              }, [
                                                _createVNode(_component_fa, {
                                                  icon: marker.content.titleLinkIcon,
                                                  class: "map-control-link-icon"
                                                }, null, 8, ["icon"])
                                              ], 8, _hoisted_12)
                                            ], 64))
                                      ]))
                                    : (marker.content.title)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(marker.content.title), 1))
                                      : _createCommentVNode("", true),
                              (marker.content.subtitle && marker.content.subtitleLink)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (marker.content.subtitle && marker.content.subtitleLinkText)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(marker.content.subtitle), 1))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("a", {
                                      class: "popup-link",
                                      href: marker.content.subtitleLink,
                                      style: {"font-weight":"normal"}
                                    }, _toDisplayString(marker.content.subtitleLinkText || marker.content.subtitle), 9, _hoisted_16)
                                  ]))
                                : (marker.content.subtitle && marker.content.subtitleRoute)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                      (marker.content.subtitle && marker.content.subtitleLinkText)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(marker.content.subtitle) + ": ", 1))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_router_link, {
                                        class: "popup-link",
                                        to: {  name: marker.content.subtitleRoute, params: marker.content.subtitleRouteParams },
                                        style: {"font-weight":"normal"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(marker.content.subtitleLinkText || marker.content.subtitle), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["to"])
                                    ]))
                                  : (marker.content.subtitle && marker.content.subtitleClickFunc)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                        (marker.content.subtitle && marker.content.subtitleLinkText)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(marker.content.subtitle), 1))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("a", {
                                          class: "normal",
                                          style: {"font-weight":"normal"},
                                          onClick: marker.content.subtitleClickFunc
                                        }, _toDisplayString(marker.content.subtitleLinkText || marker.content.subtitle), 9, _hoisted_21)
                                      ]))
                                    : (marker.content.subtitle)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(marker.content.subtitle), 1))
                                      : _createCommentVNode("", true),
                              ((marker.content.items && marker.content.items.length > 0) || marker.content.thumbnailSrc)
                                ? (_openBlock(), _createElementBlock("hr", _hoisted_23))
                                : _createCommentVNode("", true),
                              (marker.content.items && marker.content.items.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(marker.content.items, (item, j) => {
                                      return (_openBlock(), _createElementBlock("div", { key: j }, [
                                        (item.body && !item.title)
                                          ? (_openBlock(), _createElementBlock("span", {
                                              key: 0,
                                              class: _normalizeClass(_ctx.getStatusClass(item.body))
                                            }, _toDisplayString(item.body), 3))
                                          : (item.body && item.title)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                                                (item.titleLinkText || (!item.link && !item.route))
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(item.titleLinkText ? item.title + ":" : item.title), 1))
                                                  : _createCommentVNode("", true),
                                                (item.link && !item.titleLinkText)
                                                  ? (_openBlock(), _createElementBlock("a", _hoisted_27, _toDisplayString(item.title), 1))
                                                  : (item.link && item.titleLinkText)
                                                    ? (_openBlock(), _createElementBlock("a", _hoisted_28, _toDisplayString(item.titleLinkText), 1))
                                                    : _createCommentVNode("", true),
                                                (item.route && !item.titleLinkText)
                                                  ? (_openBlock(), _createBlock(_component_router_link, {
                                                      key: 3,
                                                      class: "popup-link",
                                                      to: { name: item.route, params: item.routeParams }
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(item.title), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["to"]))
                                                  : _createCommentVNode("", true),
                                                (item.route && item.titleLinkText)
                                                  ? (_openBlock(), _createBlock(_component_router_link, {
                                                      key: 4,
                                                      class: "popup-link",
                                                      to: { name: item.route, params: item.routeParams }
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(item.titleLinkText), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["to"]))
                                                  : _createCommentVNode("", true),
                                                (item.link || item.route)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_29, " - "))
                                                  : _createCommentVNode("", true),
                                                (item.body)
                                                  ? (_openBlock(), _createElementBlock("span", {
                                                      key: 6,
                                                      class: _normalizeClass(_ctx.getStatusClass(item.body))
                                                    }, _toDisplayString(item.body), 3))
                                                  : _createCommentVNode("", true)
                                              ]))
                                            : (item.title)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(item.title), 1))
                                              : _createCommentVNode("", true),
                                        (!_ctx.noPopupDivider && j < marker.content.items.length - 1)
                                          ? (_openBlock(), _createElementBlock("hr", _hoisted_31))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (marker.content.thumbnailSrc)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                    _createElementVNode("img", {
                                      src: marker.content.thumbnailSrc
                                    }, null, 8, _hoisted_33)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["lngLat"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.polygons, (path, i) => {
                return (_openBlock(), _createBlock(_component_mapbox_geogeometry_polygon, {
                  key: i,
                  path: path
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_mapbox_geogeometry_fill, {
                      color: "#22384a",
                      opacity: 0.3
                    })
                  ]),
                  _: 2
                }, 1032, ["path"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["mapStyle", "accessToken", "height", "bounds"]))
        : _createCommentVNode("", true)
    ], 4),
    (!_ctx.hideStyles)
      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: "streets-v11",
              name: "mapstyle",
              id: "mapstyle-street",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mapBoxStyle) = $event)),
              autocomplete: "off"
            }, null, 512), [
              [_vModelRadio, _ctx.mapBoxStyle]
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              class: "btn btn-outline-primary no-border",
              for: "mapstyle-street"
            }, "Streets", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: "satellite-v9",
              name: "mapstyle",
              id: "mapstyle-satellite",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mapBoxStyle) = $event)),
              autocomplete: "off"
            }, null, 512), [
              [_vModelRadio, _ctx.mapBoxStyle]
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("label", {
              class: "btn btn-outline-primary no-border",
              for: "mapstyle-satellite"
            }, "Satellite", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: "satellite-streets-v11",
              name: "mapstyle",
              id: "mapstyle-satplus",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mapBoxStyle) = $event)),
              autocomplete: "off"
            }, null, 512), [
              [_vModelRadio, _ctx.mapBoxStyle]
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("label", {
              class: "btn btn-outline-primary no-border",
              for: "mapstyle-satplus"
            }, "Satellite+Streets", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: "outdoors-v11",
              name: "mapstyle",
              id: "mapstyle-topographic",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mapBoxStyle) = $event)),
              autocomplete: "off"
            }, null, 512), [
              [_vModelRadio, _ctx.mapBoxStyle]
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("label", {
              class: "btn btn-outline-primary no-border",
              for: "mapstyle-topographic"
            }, "Topographic", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
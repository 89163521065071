<template>
  <CaseViewerPanel><template v-slot:body>
    <div class="mt-1" v-if="caseData.elementType === 'device'">
      <b>Device EUI:</b> <span >{{ caseData.elementID }}</span>
    </div>
    <div class="mt-1" v-else-if="caseData.elementType === 'gateway'">
      <b>Gateway EUI:</b> <span >{{ caseData.elementID }}</span>
    </div>
    <div class="mt-1">
      <b>Site:</b> <span >{{ caseData.siteID }}</span>
    </div>
    <div class="mt-1">
      <b>Customer:</b> <span >{{ caseData.customerID }}</span>
    </div>
  </template></CaseViewerPanel>
</template>
<script>
import CaseViewerPanel from '@/components/fleet/Maintenance/CaseViewerPanel.vue'

export default {
  name: 'maintenance-case-viewer-general',
  props: ['caseData'],
  data () {
    return {
      isLoading: false
    }
  },
  components: { CaseViewerPanel }
}
</script>
<style scoped>
  .case-viewer-pane {
    padding: 50px;
    margin-bottom: 10px;
  }
</style>
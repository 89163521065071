<template>
  <div class="case-viewer-body">
    <div class="case-viewer-col">
      <CaseViewerID :caseData="caseData" />
      <CaseViewerElement :caseData="caseData" />
      <CaseViewerStats :caseData="caseData" />


      <InfoSection class="case-viewer-pane"><template v-slot:info>
        <div>
          Case Failure Info!
        </div>
      </template></InfoSection>
    </div>
    <div class="case-viewer-col">
      <!-- Case Resolution Info -->
      <InfoSection class="case-viewer-pane"><template v-slot:info>
        <div>
          Case Resolution Info
        </div>
      </template></InfoSection>
      <!-- Case Notes -->
      <InfoSection class="case-viewer-pane"><template v-slot:info>
        <div>
          Case Notes
        </div>
      </template></InfoSection>
    </div>
    <div class="case-viewer-col">
      <!-- Case Files Info -->
      <InfoSection class="case-viewer-pane"><template v-slot:info>
        <div>
          Case Files
        </div>
      </template></InfoSection>
    </div>
  </div>
</template>
<script>
import InfoSection from '@/components/controls/InfoSection.vue'
import CaseViewerID from '@/components/fleet/Maintenance/CaseViewerID.vue'
import CaseViewerElement from '@/components/fleet/Maintenance/CaseViewerElement.vue'
import CaseViewerStats from '@/components/fleet/Maintenance/CaseViewerStats.vue'

export default {
  name: 'maintenance-case-viewer-pane',
  props: ['caseData'],
  data () {
    return {
      isLoading: false
    }
  },
  components: { InfoSection, CaseViewerID, CaseViewerElement, CaseViewerStats }
}
</script>
<style scoped>
  .case-viewer-body {
    min-height: 568px;
    height: 568px;
    max-height: 568px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-around;
  }
  .case-viewer-col {
    min-width: 364px;
    max-width: 385px;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .case-viewer-pane {
    padding: 50px;
    margin-bottom: 10px;
  }
</style>
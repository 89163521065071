<template>
  <div :class="menuClass" @click="showMenu">
    <div style="display:flex">
      <div class="fleet-map-menu">
        <fa icon="bars" :class="{'fleet-map-menu-icon-open': isOpen}"/>
      </div>
      <span class="fleet-map-title" v-if="isOpen">Map Settings</span>
    </div>
    <hr v-if="isOpen" />
    <div v-if="isOpen" class="map-menu-body">
      <ul mt-0 mb-0>
        <li class="selectable py-1" @click="showAllNodes">
          <label class="menu-icon-width">
            <fa v-if="show === 'all' || !show" icon="check" alt="Showing All Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show All Nodes
        </li>
        <li class="selectable pt-1 pb-2" @click="showProblemNodes">
          <label class="menu-icon-width">
            <fa v-if="show == 'problem'" icon="check" alt="Showing Problem Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show Nodes with Problems
        </li>
        <li class="selectable pt-1 pb-2 add-border-bottom" @click="hideNodes">
          <label class="menu-icon-width">
            <fa v-if="show === 'none'" icon="check" alt="Showing Problem Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Hide Node Pins
        </li>
        <li class="selectable py-1" @click="showAllGateways">
          <label class="menu-icon-width">
            <fa v-if="showGW === 'all' || !showGW" icon="check" alt="Showing All Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show All Gateways
        </li>
        <li class="selectable pt-1 pb-2" @click="showProblemGateways">
          <label class="menu-icon-width">
            <fa v-if="showGW == 'problem'" icon="check" alt="Showing Problem Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show Gateways w/ Problems
        </li>
        <li class="selectable pt-1 pb-2" @click="hideGateways">
          <label class="menu-icon-width">
            <fa v-if="showGW === 'none'" icon="check" alt="Showing Problem Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Hide Gateway Pins
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FleetMapMenu',
  emits: ['updateSettings'],
  data () {
    return {
      isOpen: false,
      show: '',
      showGW: ''
    }
  },
  computed: {
    menuClass () {
      if (this.isOpen) {
        return 'fleet-map-menu-container fleet-map-menu-open'
      }
      return 'fleet-map-menu-click'
    }
  },
  methods: {
    showMenu () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        const show = localStorage.getItem('prefs.fleetmap-node-scope')
        const showGW = localStorage.getItem('prefs.fleetmap-gw-scope')
        if (!show) {
          this.show = 'all'
        } else {
          this.show = show
        }
        if (!showGW) {
          this.showGW = 'all'
        } else {
          this.showGW = showGW
        }
      }
    },
    showAllNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'all')
      this.$emit('updateSettings')
    },
    showProblemNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'problem')
      this.$emit('updateSettings')
    },
    hideNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'none')
      this.$emit('updateSettings')
    },
    showAllGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'all')
      this.$emit('updateSettings')
    },
    showProblemGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'problem')
      this.$emit('updateSettings')
    },
    hideGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'none')
      this.$emit('updateSettings')
    }
  }
}
</script>
<style scoped>
.fleet-map-menu {
    padding: 1px 5px 3px 5px;
    width: 28px;
}

.fleet-map-menu:hover,
.fleet-map-menu-icon-open {
    color: var(--app-link-color);
}

.fleet-map-title {
    padding-top: 4px;
    padding-left: 8px;
    font-size: 16px;
    color: #626466;
}

.fleet-map-menu-click {
    position: absolute;
    z-index: 100;
    margin-left: 6px;
    margin-top: 3px;
    border-radius: 3px;
    background-color: var(--menu-bg-color);
    color: var(--app-text-color);
    box-shadow: 1px 1px 5px #333;
    cursor: pointer;
    opacity: .7;
    width: 28px;
}

.fleet-map-menu-open {
    width: 270px !important;
    position: absolute;
    z-index: 100;
    margin-left: 6px;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px #333;
    cursor: pointer;
}

.fleet-map-menu-container {
    background-color: var(--menu-bg-color);
    border-radius: 6px;
    border: 1px var(--form-control-border-color) solid;
}

.fleet-map-menu-click {
    position: absolute;
    z-index: 100;
    margin-left: 6px;
    margin-top: 3px;
    border-radius: 3px;
    background-color: var(--menu-bg-color);
    color: var(--app-text-color);
    box-shadow: 1px 1px 5px #333;
    cursor: pointer;
    opacity: .7;
    width: 28px;
}

.fleet-map-menu-open {
    width: 270px !important;
    position: absolute;
    z-index: 100;
    margin-left: 6px;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px #333;
    cursor: pointer;
}

div.fleet-map-menu-open hr {
    color: var(form-control-border-color) !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

</style>
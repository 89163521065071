<template>
  <InfoSection class="case-viewer-panel"><template v-slot:info>
    <slot name="body" />
  </template></InfoSection>
</template>
<script>
import InfoSection from '@/components/controls/InfoSection.vue'

export default {
  name: 'maintenance-case-viewer-general',
  props: ['caseData'],
  data () {
    return {
      isLoading: false
    }
  },
  components: { InfoSection }
}
</script>
<style scoped>
  .case-viewer-panel {
    min-height: 60px;
    padding: 3px 0px 5px 7.5px;
    margin-bottom: 10px;
  }
</style>
<template>
  <div v-if="label" :class="labelClasses">{{ label }}</div>
  <div :class="classes">
    <slot name="buttons" />
  </div>
</template>
<script>
export default {
  name: 'toolbar-section-control',
  props: ['label', 'noHover', 'class', 'labelClass'],
  computed: {
    labelClasses () {
      const base = 'toolbar-section-label'
      if (this.labelClass) {
        return`${base} ${this.labelClass}`
      }
      return base
    },
    classes () {
      let base = "toolbar-section"
      if (this.class) {
        base = `${base} ${this.class}`
      }
      if (!this.noHover) {
        return `${base} toolbar-section-hoverable`
      }
      return base
    }
  }
}
</script>
<style scoped>
  .toolbar-section {
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    margin-right: 2.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 36px;
    padding: 0px 4px;
  }
  .toolbar-section.toolbar-section-hoverable:hover {
    background-color: var(--toolbar-section-hover-bg-color);
    border-radius: var(--form-border-radius);
    border: 1px solid var(--form-control-border-color);
    padding: 0px 3px;
  }
  .toolbar-section:first-child {
    margin-left: 0px;
  }
  .toolbar-section:last-child {
    margin-right: 0px;
  }
  .toolbar-section-label {
    font-size: var(--font-reduce-2);
    font-weight: 600;
    margin-left: 5px;
    margin-right: 4px;
    margin-top: 0.5px;
    color: var(--info-label-color);
    cursor: default;
  }
  .toolbar-section-hoverable .toolbar-section-label {
    padding-left: 5px;
  }
</style>
<template>
  <div :class="wrapperClasses">
    <label :class="labelClasses">{{ label }}</label>
    <div :class="valueClasses">
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  props: ['wrapperClass', 'height', 'label', 'value', 'labelClass', 'valueClass', 'disabled'],
  computed: {
    wrapperClasses () {
      const base = 'text-label-row'
      let out = base
      if (this.wrapperClass) {
        out = `${out} ${this.wrapperClass}`
      }
      if (this.height && ['short', 'shorter'].includes(this.height)) {
        out = `${out} ${this.height}`
      }
      return out
    },
    valueClasses () {
      const base = 'text-label-row-value'
      let out = base
      if (this.valueClass) {
        out = `${out} ${this.valueClass}`
      }
      return out
    },
    labelClasses () {
      let out = 'text-label-row-label'
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      if (this.disabled) {
        out += ' subtle'
      }
      return out
    }
  }
}
</script>
<style scoped>
  .text-label-row {
    display: flex;
    height: 32px;
  }

  .text-label-row-label {
    text-align: right;
    margin-right: 8px;
    width: 85px;
    white-space: nowrap;
    color: var(--form-label-color);
    font-size: var(--font-reduce-2);
    height: 32px;
    line-height: 32px;
}
  .text-label-row-value {
    height: 32px;
    min-height: 32px;
    line-height: 32px;
  }

  .text-label-row.short {
    height: 24px;
  }

  .text-label-row.short .text-label-row-label {
    height: 24px;
    line-height: 24px;
  }

  .text-label-row.short .text-label-row-value {
    height: 24px;
    min-height: 24px;
    line-height: 24px;
  }

  .text-label-row.shorter {
    height: 20px;
  }

  .text-label-row.shorter .text-label-row-label {
    height: 20px;
    line-height: 20px;
  }

  .text-label-row.shorter .text-label-row-value {
    height: 20px;
    min-height: 20px;
    line-height: 20px;
  }
</style>
<template>
  <div :class="wrapperClasses">
    <label :class="labelClasses" :for="id" v-if="label">{{ label }}</label>
    <div v-if="!selectedLabel">
      <input
        type="text"
        :id="mergedId"
        :name="id"
        :style="inputStyle"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="inputClasses"
        v-model="searchValue"
        @keypress="keyPressed"
        @keydown="inputKeyDown"
        autocomplete="off"
        ref="searchValueInput"
        @focus="showMenu"
      />
      <div :class="resultsClasses" v-if="isSearching && searchResults && searchResults.length > 0">
        <ul>
          <li
            v-for="(res, i) of searchResults"
            :key="i"
            :id="i"
            :class="{'navigation-target': i === resultsFocusIndex}"
            @click="() => { makeSelection(i)}"
          >
            <span>
              {{ res['title'] }}
            </span>
            <div v-if="i === resultsFocusIndex" class="results-selector-icon-area">
              <fa icon="turn-down" alt="Search" class="return-key-icon" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="valueClasses" @click="selectedLabelClicked" v-else>
      <label>{{ displayName }}</label>
    </div>
  </div>
</template>
<script>
import api from '@/services/fleet/FleetDataService'

export default {
  name: 'index-search-row',
  emits: ['update:modelValue', 'searchItemSelected'],
  props: ['label', 'placeholder', 'id', 'labelClass', 'inputStyle', 'inputClass',
    'separator', 'disabled', 'required', 'elementType', 'modelValue',
    'valueChangedFunc', 'wrapperClass', 'resultsClass', 'valueClass'],
  data () {
    return {
      resultsFocusIndex: 0,
      searchValue: '',
      selectedLabel: this.modelValue,
      selectedItem: undefined,
      isSearching: true,
      defaultState: true,
      searchIndex: api.getManifest()
    }
  },
  watch: {
    searchValue: {
      handler () {
        this.resultsFocusIndex = 0
      }
    }
  },
  methods: {
    getID (input) {
      if (!input || !input.type || !input.key) {
        return undefined
      }
      if (input.type === 'Device') {
        return input.key.replace('device.', '').toUpperCase()
      }
      if (input.type === 'Site') {
        return input.key.replace('site.', '')
      }
      if (input.type === 'Customer') {
        return input.key.replace('customer.', '')
      }
      if (input.type === 'Gateway') {
        return input.key.replace('gateway.', '').toUpperCase()
      }
    },
    showMenu () {
      if (!this.isSearching || this.defaultState) {
        this.isSearching = true
        this.defaultState = false
        // adding 500ms timeout allows time for the user release the mouse
        // curser after clicking into the input box to prevent the imminent
        // mouseup event from registering as a click and hiding the menu again
        setTimeout(() => { document.addEventListener('click', this.hide) }, 500)
      }
    },
    hide () {
      if (this.isSearching) {
        this.isSearching = false
        document.removeEventListener('click', this.hide)
      }
    },
    selectedLabelClicked () {
      this.selectedItem = undefined
      this.selectedLabel = null
      this.$emit('update:modelValue', null)
      if (this.valueChangedFunc) {
        this.valueChangedFunc(undefined)
      }
      this.showMenu()
      this.$nextTick(() => {
        if (this.$refs.searchValueInput) {
          this.$refs.searchValueInput.focus()
          this.$refs.searchValueInput.select()
        }
      })
    },
    clearSelection () {
      this.$emit('update:modelValue', null)
      this.selectedItem = undefined
      this.selectedLabelClicked()
      if (this.valueChangedFunc) {
        this.valueChangedFunc(undefined)
      }
    },
    keyPressed (e) {
      if (this.searchResults.length === 0 || e.keyCode !== 13 ||
      this.resultsFocusIndex >= this.searchResults.length) {
        return
      }
      this.makeSelection(this.resultsFocusIndex)
    },
    makeSelection (i) {
      this.resultsFocusIndex = i
      this.hide()
      this.selectedLabel = this.searchResults[i]['title']
      this.$emit('searchItemSelected', this.searchResults[i])
      this.$emit('update:modelValue', this.getID(this.searchResults[i]))
      this.selectedItem = this.searchResults[i]
      if (this.valueChangedFunc) {
        this.valueChangedFunc(undefined)
      }
    },
    inputKeyDown (e) {
      if (!e || !e.keyCode || e.keyCode === 13) {
        return
      }
      if (e.keyCode === 27) {
        this.searchValue = ''
      }
      this.showMenu()
      if (this.searchResults.length === 0) {
        return
      }
      if (e.keyCode === 38) {
        if (this.resultsFocusIndex === 0) {
          return
        }
        let i = this.resultsFocusIndex - 1
        if (i < 0) {
          i = 0
        }
        this.resultsFocusIndex = i
        e.preventDefault()
        return
      }
      if (e.keyCode === 40) {
        if (this.resultsFocusIndex >= this.searchResults.length - 1) {
          return
        }
        let i = this.resultsFocusIndex + 1
        if (i >= this.searchResults.length) {
          i = this.searchResults.length - 1
        }
        this.resultsFocusIndex = i
        e.preventDefault()
      }
    }
  },
  computed: {
    displayName () {
      if (!this.selectedLabel) {
        return ''
      }
      if (!this.elementType) {
        return this.selectedLabel
      }
      if (this.elementType === 'customer') {
        return api.getCustomerDisplayName(this.selectedLabel)
      }
      if (this.elementType === 'site') {
        return api.getSiteDisplayName(this.selectedLabel)
      }
      if (this.elementType === 'node') {
        return api.getNodeDisplayName(this.selectedLabel)
      }
      if (this.elementType === 'device') {
        return this.selectedLabel.toUpperCase()
      }
      if (this.elementType === 'gateway') {
        return this.selectedLabel.toUpperCase()
      }
      return this.selectedLabel
    },
    valueClasses () {
      const base = 'searchlist-value'
      if (this.valueClass) {
        return `${base} ${this.valueClass}`
      }
      return base
    },
    resultsClasses () {
      const base = 'searchlist-results'
      if (this.resultsClass) {
        return `${base} ${this.resultsClass}`
      }
      return base
    },
    wrapperClasses () {
      const base = 'd-flex input-row text-input-row align-items-center'
      if (this.wrapperClass) {
        return `${base} ${this.wrapperClass}`
      }
      return base
    },
    kvps () {
      if (!this.elementType) {
        return this.searchIndex
      }
      const out = {}
      for (const k in this.searchIndex) {
        if (!(k.toLowerCase().startsWith(`${this.elementType.toLowerCase()}.`))) {
          continue
        }
        out[k] = this.searchIndex[k]
      }
      return out
    },
    inputRef () {
      if (!this.id) {
        return 'searchlist-input-ref'
      }
      return `${this.id}-searchlist-input`
    },
    searchResults () {
      const out = []
      const dedupe = {}
      if (!this.searchValue || this.searchValue.length < 3) {
        return out
      }
      const keyPrefix = this.elementType ? `${this.elementType}.` : undefined
      for (const key of this.keys) {
        if (keyPrefix && !key.startsWith(keyPrefix)) {
          continue
        }
        if (!('title' in this.kvps[key])) {
          continue
        }
        if ((key.includes(this.searchValue.toLowerCase()) || this.kvps[key]['title'].toLowerCase().includes(this.searchValue.toLowerCase()) ) && !(this.kvps[key]['title'] in dedupe)) {
          out.push(this.kvps[key])
          dedupe[this.kvps[key]['title']] = true
        }
      }
      return out
    },
    keys () {
      const out = []
      if (!this.kvps) {
        return out
      }
      for (const k in this.kvps) {
        out.push(k)
      }
      return out
    },
    labelClasses () {
      let out = 'input-row-header searchlist-label'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (this.disabled) {
        out += ' subtle'
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    },
    inputClasses () {
      let out = 'searchlist-input form-input text-input'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      if (!this.isSearching && !this.selectedItem && this.searchValue) {
        if (this.required) {
          out += ' critical-border'
        } else {
          out += ' caution-border'
        }
      }
      return out
    },
    mergedId () {
      if (this.id) {
        return this.id
      }
      return 'search-list-input'
    }
  }
}
</script>
<style scoped>
.searchlist-input {
  position: relative;
}
.searchlist-input:focus {
    position: relative;
    z-index: 10;
}

.searchlist-value {
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: 234px;
    border: 1px solid var(--form-control-border-color);
    border-radius: var(--input-border-radius);
    padding: 3px 0 3px 3px;
    margin: 1.5px 0;
    align-items: center;
    font-size: .975em;

    background-color: var(--form-control-bg-color);
    background-size: 12px 10px;
    background-position-x: calc(100% - 8px);
    background-position-y: 10px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 7px);
    background-size: 12px 10px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23545a60%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}

.searchlist-value:hover {
    border: 1px var(--select-hover-border-color) solid;
}

.searchlist-value label {
    font-size: var(--font-reduce-1a);
    padding-left: 7px;
    line-height: normal;
    margin-top: -1.5px;
}

.searchlist-results {
    margin-top: -2px;
    border-end-start-radius: var(--form-border-radius);
    border-end-end-radius: var(--form-border-radius);
    position: relative;
    border: 1px solid var(--form-control-border-color);
    box-shadow: 0 0 25px 15px var(--modal-shadow);
    z-index: 9;
    position: absolute;
    width: 245px;
}

.searchlist-results ul {
    width: 100%;
    max-height: 252px;
    overflow-y: auto;
    cursor: pointer;
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
    border-end-start-radius: var(--form-border-radius);
    border-end-end-radius: var(--form-border-radius);
    background-color: var(--search-menu-bg-color);
}

.searchlist-results ul li {
    position: relative;
    padding: 5px 4px 5px 11px;
    height: 34px;
    line-height: 23px;
    font-size: var(--font-reduce-2);
    overflow-x: clip;
    white-space: nowrap;
    border-bottom: 1px solid var(--form-control-border-color);
}

.searchlist-results ul li:hover {
    background-color: var(--form-row-hover);
}

.searchlist-results ul li:last-child {
    border-end-start-radius: var(--form-border-radius);
    border-end-end-radius: var(--form-border-radius);
    padding-bottom: 5px;
    border-bottom: none;
}

.searchlist-results ul li.navigation-target {
    padding-left: 9px !important;
}

.searchlist-results ul li.navigation-target div.lucky-watermark {
    position: absolute;
    right: 4px;
    bottom: 6px;
}

.searchlist-label {
  height: 32px;
  line-height: 32px;
}

.results-selector-icon-area {
    background-color: var(--tab-hover-background);
    border-radius: 3px;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    right: 4px;
    bottom: 6px;
}

.results-selector-icon-area .return-key-icon {
    width: 24px;
    height: 14px;
    color: var(--app-highlight-color);
    opacity: .8;
    transform: rotate(90deg);
}

</style>
<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" :sz="modalSz" :helpCopy="helpCopy" :showSpinner="isLoading">
      <template v-slot:header>
        {{ formTitle }}
      </template>
      <template v-slot:body>
        <div>
        <SubmissionForm
          ref="modalSubmissionForm"
          :fields1="fields1"
          :formData="formData"
          :updateFunc="updateFunc"
          :createFunc="createFunc"
          :deleteFunc="deleteFunc"
          @setFormData="setFormData"
          @setCanSubmit="(val) => { this.canSubmit = val }"
          @setIsLoading="(val) => { this.isLoading = val }"
          @prepareToClose="() => { this.isClosing = true }"
          @apiResponse="apiResponse"
          :elementType="elementType"
          :isCreate="isCreate"
        />
        <LastModifiedRow :ts="formData.lastModified" :author="formData.lastModifiedBy" v-if="formData && 'lastModified' in formData"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="submit()" :disabled="!canClickSubmit" v-if="!isConfirmingDelete">Submit</button>
          <button class="btn btn-red ms-3" @click="confirmDeleteElement()" :disabled="!canClickSubmit" v-if="deleteFunc && !isConfirmingDelete">Delete {{ elementType }}</button>
          <button class="btn btn-red ms-3" @click="deleteElement()" :disabled="!canClickSubmit" v-if="deleteFunc && isConfirmingDelete">Confirm &amp; Delete {{ elementType }}</button>
          <a class="btn btn-blue ms-3" @click.prevent="cancelDeleteElement()" :disabled="!canClickAnyButton" v-if="deleteFunc && isConfirmingDelete" >Cancel</a>
          <a class="btn btn-blue ms-3" @click.prevent="close()" :disabled="!canClickAnyButton" v-else>Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/modals/Modal.vue'
import SubmissionForm from '@/components/forms/SubmissionForm.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'

export default {
  name: 'modal-submission-form',
  props: ['title', 'sz', 'formData', 'fields1', 'updateFunc', 'createFunc', 'deleteFunc',
    'elementType', 'helpCopy', 'isCreate', 'closer', 'submitDisabled'],
  emits: ['setFormData'],
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      subFormData: {},
      isConfirmingDelete: false,
      canSubmit: false,
      isClosing: false
    }
  },
  computed: {
    canClickAnyButton () {
      if (this.isClosing || this.isLoading || !this.isBlocking) {
        return false
      }
      return true
    },
    canClickSubmit () {
      if (!this.canClickAnyButton || this.submitDisabled || !this.canSubmit) {
        return false
      }
      return true
    },
    action () {
      return this.isCreateModal ? 'Create' : 'Update'
    },
    element () {
      return this.elementType ? this.elementType : 'Item'
    },
    formTitle () {
      if (this.title) {
        return title
      }
      return `${this.action} ${this.element}`
    },
    isCreateModal () {
      return this.isCreate || !this.formData
    },
    modalSz () {
      if (!this.sz) {
        return 'sm'
      }
      return this.sz
    }
  },
  methods: {
    setCanSubmit (val) {
      this.canSubmit = val
    },
    setIsLoading (val) {
      this.isLoading = val
    },
    setFormData (data) {
      console.log('ModalSubmissionForm / setFormData')
      this.subFormData = data
      this.$emit('setFormData', data)
    },
    labelClass (className, required) {
      if (!className && !required) {
        return ""
      }
      if (className && required) {
        return `${className} label-required`
      }
      if (required) {
        return 'label-required'
      }
      return className || ""
    },
    submit () {
      if (this.$refs.modalSubmissionForm) {
        this.$refs.modalSubmissionForm.submit()
      }
    },
    deleteElement () {
      if (this.$refs.modalSubmissionForm) {
        this.$refs.modalSubmissionForm.deleteElement()
      }
    },
    apiResponse(verb, _, errorData = undefined) {
      if (!errorData && ['create', 'update', 'delete'].includes(verb)) {
          this.isClosing = true
          this.close()
        }
    },
    cancelDeleteElement () {
      this.isConfirmingDelete = false
    },
    confirmDeleteElement () {
      this.isConfirmingDelete = true
    },
    reset () {
      this.isLoading = false
      this.isClosing = false
      this.canSubmit = false
      this.isConfirmingDelete = false
    },
    show () {
      this.reset()
      this.isBlocking = true
      if (this.formData) {
        this.subFormData = { ...this.formData }
        delete this.subFormData.lastModified
        delete this.subFormData.lastModifiedBy
        delete this.subFormData.createdAt
        delete this.subFormData.createdBy
        this.$nextTick(() => {
          if (this.$refs.modalSubmissionForm) {
            this.canSubmit = this.$refs.modalSubmissionForm.isSubmittable()
          }
        })
      }
    },
    close () {
      this.subFormData = {}
      this.$refs.baseModal.close()
      if (this.closer) {
        this.closer()
      }
      this.isBlocking = false
    },
  },
  components: { Modal, SubmissionForm, LastModifiedRow }
}
</script>
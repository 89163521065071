<template>
  <div :style="'height:' + mapHeight">
    <mapbox-map
      ref="mainMapControl"
      :mapStyle="mapBoxStyleURL"
      :accessToken="accessToken"
      :height="mapHeight"
      width="100%"
      :bounds="mapBounds"
      v-if="((markers && markers.length > 0) || (polygons && polygons.length > 0)) && !isLoading"
      >
      <mapbox-geolocate-control
        :showUserHeading="true"
        :trackUserLocation="true"
        :showAccuracyCircle="true"
        :showUserLocation="true"
        :positionOptions="{ enableHighAccuracy: true }"
        v-if="showGeolocation"
      />
      <mapbox-marker v-for="marker, i in markers" :lngLat="marker.coords" :key="i" :scale="0.65">
        <template v-slot:icon>
          <fa
            :icon="marker.icon || 'map-pin'"
            class="map-icon"
            :class="!marker.content || (!marker.content.titleLink && !marker.content.titleRoute && !marker.content.titleClickFunc) ? 'map-icon-noclick' : undefined"
            :style="`color:${marker.color}`"
          />
        </template>
        <mapbox-popup v-if="marker.content" :offset="[0,-22]" maxWidth="300px" className="map-popup">
          <!-- title line -->
          <div style="color: #666">
            <div v-if="marker.content.title && marker.content.titleLink"
              style="font-weight: bold;">
              <span v-if="marker.content.title && (marker.content.titleLinkText || marker.content.titleLinkIcon)">
                {{ marker.content.title }}
              </span>
              <a class="popup-link" :href="marker.content.titleLink" style="font-weight: normal" v-if="!marker.content.titleLinkIcon">
                {{marker.content.titleLinkText || marker.content.title}}
              </a>
              <a class="popup-link" :href="marker.content.titleLink" style="font-weight: normal" v-else>
                <fa :icon="marker.content.titleLinkIcon" class="map-control-link-icon" />
              </a>
            </div>
            <div v-else-if="marker.content.title && marker.content.titleRoute"
              style="font-weight: bold;">
              <span v-if="marker.content.title && marker.content.titleLinkText">
                {{ marker.content.title }}:
              </span>
              <router-link class="popup-link" :to="{  name: marker.content.titleRoute, params: marker.content.titleRouteParams }" style="font-weight: normal">
                {{marker.content.titleLinkText || marker.content.title}}
              </router-link>
            </div>
            <div v-else-if="marker.content.title && marker.content.titleClickFunc"
              style="font-weight: bold;">
              <span v-if="marker.content.title && marker.content.titleLinkText">
                {{ marker.content.title }}:
              </span>
              <a class="normal" style="font-weight: normal;" @click="marker.content.titleClickFunc" v-if="!marker.content.titleLinkIcon">
                {{marker.content.titleLinkText || marker.content.title}}
              </a>
              <template v-else>
                <span v-if="marker.content.title">
                  {{ marker.content.title }}
                </span>
                <a class="normal" style="font-weight: normal;" @click="marker.content.titleClickFunc">
                  <fa :icon="marker.content.titleLinkIcon" class="map-control-link-icon" />
                </a>
              </template>
            </div>
            <div v-else-if="marker.content.title"
              style="font-weight: bold;">
              {{marker.content.title}}
            </div>
          <!-- subtitle line -->
            <div v-if="marker.content.subtitle && marker.content.subtitleLink"
              style="font-weight: bold;">
              <span v-if="marker.content.subtitle && marker.content.subtitleLinkText">
                {{ marker.content.subtitle }}
              </span>
              <a class="popup-link" :href="marker.content.subtitleLink" style="font-weight: normal">
                {{marker.content.subtitleLinkText || marker.content.subtitle}}
              </a>
            </div>
            <div v-else-if="marker.content.subtitle && marker.content.subtitleRoute"
              style="font-weight: bold;">
              <span v-if="marker.content.subtitle && marker.content.subtitleLinkText">
                {{ marker.content.subtitle }}:
              </span>
              <router-link class="popup-link" :to="{  name: marker.content.subtitleRoute, params: marker.content.subtitleRouteParams }"  style="font-weight: normal">
                {{marker.content.subtitleLinkText || marker.content.subtitle}}
              </router-link>
            </div>
            <div v-else-if="marker.content.subtitle && marker.content.subtitleClickFunc"
              style="font-weight: bold;">
              <span v-if="marker.content.subtitle && marker.content.subtitleLinkText">
                {{ marker.content.subtitle }}
              </span>
              <a class="normal" style="font-weight: normal;" @click="marker.content.subtitleClickFunc">
                {{marker.content.subtitleLinkText || marker.content.subtitle}}
              </a>
            </div>
            <div v-else-if="marker.content.subtitle"
              style="font-weight: bold;">
              {{marker.content.subtitle}}
            </div>
            <hr v-if="(marker.content.items && marker.content.items.length > 0) || marker.content.thumbnailSrc" style="margin: 1px -5px 3px -5px" />
            <!-- body lines -->
            <div v-if="marker.content.items && marker.content.items.length > 0">
              <div v-for="(item, j) in marker.content.items" :key="j">
                <span v-if="item.body && !item.title" :class="getStatusClass(item.body)">
                  {{ item.body }}
                </span>
                <span v-else-if="item.body && item.title">
                  <span v-if="item.titleLinkText || (!item.link && !item.route)" class="bold" style="margin-right: 4px">
                    {{ item.titleLinkText ? item.title + ":" : item.title }}
                  </span>
                  <a class="popup-link" v-if="item.link && !item.titleLinkText">
                    {{ item.title }}
                  </a>
                  <a class="popup-link" v-else-if="item.link && item.titleLinkText">
                    {{ item.titleLinkText }}
                  </a>
                  <router-link class="popup-link" v-if="item.route && !item.titleLinkText" :to="{ name: item.route, params: item.routeParams }">
                    {{ item.title }}
                  </router-link>
                  <router-link class="popup-link" v-if="item.route && item.titleLinkText" :to="{ name: item.route, params: item.routeParams }">
                    {{ item.titleLinkText }}
                  </router-link>
                  <span v-if="item.link || item.route"> - </span>
                  <span v-if="item.body" :class="getStatusClass(item.body)">{{ item.body }}</span>
                </span>
                <span v-else-if="item.title" class="bold">
                  {{ item.title }}
                </span>
                <hr style="color: #999 !important; border: 0px !important" v-if="!noPopupDivider && j < marker.content.items.length - 1" />
              </div>
            </div>
            <div v-if="marker.content.thumbnailSrc">
              <img :src="marker.content.thumbnailSrc" />
            </div>
          </div>
        </mapbox-popup>
      </mapbox-marker>
      <mapbox-geogeometry-polygon v-for="path, i in polygons" :key="i" :path="path">
        <mapbox-geogeometry-fill color="#22384a" :opacity="0.3" />
      </mapbox-geogeometry-polygon>
    </mapbox-map>
  </div>
  <div class="text-center" v-if="!hideStyles">
    <div class="btn-group no-border" role="group" aria-label="User Search Filter" style="max-height:27px !important; width:100%">
      <input type="radio" class="btn-check" value="streets-v11" name="mapstyle"
        id="mapstyle-street" v-model="mapBoxStyle" autocomplete="off" />
      <label class="btn btn-outline-primary no-border" for="mapstyle-street">Streets</label>

      <input type="radio" class="btn-check" value="satellite-v9" name="mapstyle"
        id="mapstyle-satellite" v-model="mapBoxStyle" autocomplete="off" />
      <label class="btn btn-outline-primary no-border" for="mapstyle-satellite">Satellite</label>

      <input type="radio" class="btn-check" value="satellite-streets-v11" name="mapstyle"
        id="mapstyle-satplus" v-model="mapBoxStyle" autocomplete="off" />
      <label class="btn btn-outline-primary no-border" for="mapstyle-satplus">Satellite+Streets</label>

      <input type="radio" class="btn-check" value="outdoors-v11" name="mapstyle"
        id="mapstyle-topographic" v-model="mapBoxStyle" autocomplete="off" />
      <label class="btn btn-outline-primary no-border" for="mapstyle-topographic">Topographic</label>

    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { MapboxMap, MapboxMarker, MapboxPopup, MapboxGeogeometryPolygon, MapboxGeogeometryFill, MapboxGeolocateControl } from 'vue-mapbox-ts'

export default defineComponent({
  name: 'Mapbox',
  props: ['zoom', 'markers', 'polygons', 'hideStyles', 'height', 'useBounds', 'boundBorderPct', 'showGeolocation', 'noPopupDivider'],
  data () {
    return {
      mapBoxStyle: '',
      isLoading: false,
      boundsMultiplier: .04
    }
  },
  components: {
    MapboxMap,
    MapboxMarker,
    MapboxGeogeometryPolygon,
    MapboxGeogeometryFill,
    MapboxPopup,
    MapboxGeolocateControl
  },
  methods: {
    getStatusClass (input: string) {
      return {
        'status-error': input.includes('error'),
        'status-warning-orange': input.includes('warning'),
        'status-inactive': input.includes('unmonitored'),
        'status-ok-light': input === 'ok'
      }
    },
    stopLoading () {
      this.isLoading = false
    },
    calcPolygonArea (vertices) {
      var total = 0
      for (var i = 0, l = vertices.length; i < l; i++) {
        var addX = vertices[i][1]
        var addY = vertices[i === vertices.length - 1 ? 0 : i + 1][0]
        var subX = vertices[i === vertices.length - 1 ? 0 : i + 1][1]
        var subY = vertices[i][0]
        total += (addX * addY * 0.5)
        total -= (subX * subY * 0.5)
      }
      return Math.abs(total)
    },
    calcPolygonCenter (arr) {
      var minX, maxX, minY, maxY
      for (var i = 0; i < arr.length; i++) {
        minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX
        maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX
        minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY
        maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY
      }
      return [(minX + maxX) / 2, (minY + maxY) / 2]
    }
  },
  watch: {
    mapBoxStyle: {
      handler () {
        if (this.mapBoxStyle) {
          localStorage.setItem('mapBoxStyle', this.mapBoxStyle)
          this.isLoading = true
          setTimeout(this.stopLoading, 100)
        }
      }
    }
  },
  mounted () {
    const style = localStorage.getItem('mapBoxStyle')
    if (!style) {
      this.mapBoxStyle = 'satellite-v9'
      localStorage.setItem('mapBoxStyle', 'satellite-v9')
    } else {
      this.mapBoxStyle = style
    }
    if (this.boundBorderPct !== undefined) {
      this.boundsMultiplier = this.boundBorderPct / 100
    }
  },
  computed: {
    mapHeight () {
      if (this.height) {
        return this.height
      }
      return '320px'
    },
    mapZoom () {
      if (this.zoom) {
        return this.zoom
      }
      return 13
    },
    mapBounds () {
      if (!this.useBounds) {
        return null
      }
      let minLat = 10000
      let minLong = 10000
      let maxLat = -10000
      let maxLong = -10000
      if (this.polygons) {
        for (const polygon of this.polygons) {
          for (const point of polygon) {
            if (point[0] < minLong) {
              minLong = point[0]
            }
            if (point[0] > maxLong) {
              maxLong = point[0]
            }
            if (point[1] < minLat) {
              minLat = point[1]
            }
            if (point[1] > maxLat) {
              maxLat = point[1]
            }
          }
        }
      }
      if (this.markers) {
        for (const mk of this.markers) {
          if (mk.coords && mk.coords.length === 2) {
            if (mk.coords[0] < minLong) {
              minLong = mk.coords[0]
            }
            if (mk.coords[0] > maxLong) {
              maxLong = mk.coords[0]
            }
            if (mk.coords[1] < minLat) {
              minLat = mk.coords[1]
            }
            if (mk.coords[1] > maxLat) {
              maxLat = mk.coords[1]
            }
          }
        }
      }
      if (minLat === 10000 || minLong === 10000 ||
        maxLat === -10000 || maxLong === -10000) {
        return null
      }

      const xd = Math.abs((maxLat - minLat) * this.boundsMultiplier)
      const yd = Math.abs((maxLong - minLong) * this.boundsMultiplier)
      minLong -= yd
      minLat -= xd
      maxLong += yd
      maxLat += xd
      if (minLong < -180) { minLong = -180 }
      if (minLat < -90) { minLat = -90 }
      if (maxLong > 180) { maxLong = 180 }
      if (maxLat > 90) { maxLat = 90 }
      return [minLong, minLat, maxLong, maxLat]
    },
    mapCenter () {
      if (this.polygons) {
        const arr = [] as any[]
        for (const polygon of this.polygons) {
          for (const point of polygon) {
            if (point.length === 2) {
              arr.push(point)
            }
          }
        }
        return this.calcPolygonCenter(arr)
      }
      return undefined
    },
    mapBoxStyleURL () {
      if (this.mapBoxStyle) {
        return this.mapBoxStyle
      }
      return 'satellite-v9'
    },
    accessToken () {
      return process.env.VUE_APP_MAP_LICENCE_KEY
    }
  }
})
</script>
<style scoped>
  .map-control-link-icon {
    max-height: 14px;
    margin-left: 10px;
    margin-top: -4px;
  }
</style>
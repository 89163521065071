import { NewMaintenanceCase, MaintenanceCase } from '@/components/fleet/Maintenance/types'
import http from '@/http-common'

class MaintenanceService {
  async createMaintenanceCase (data: NewMaintenanceCase) {
    return http.post('/provisioning/maint-cases', data)
  }

  async updateMaintCaseById (caseId: string, data: MaintenanceCase) {
    return http.put(`/provisioning/maint-cases/${caseId}`, data)
  }

  async deleteMaintCaseById (caseId: string) {
    return http.delete(`/provisioning/maint-cases/${caseId}`)
  }

  async getMaintenanceCaseById (caseId: string): Promise<MaintenanceCase> {
    return http.get(`/provisioning/maint-cases/${caseId}`)
  }

  async listMaintenanceCasesByAssignee (email: string): Promise<MaintenanceCase[]> {
    return http.get(`/provisioning/maint-cases/assignee/${email}`)
  }

  async listMaintenanceCasesBySite (siteID: string): Promise<MaintenanceCase[]> {
    return http.get(`/provisioning/maint-cases/site/${siteID}`)
  }

  async listMaintenanceCasesByCustomer (customerID: string): Promise<MaintenanceCase[]> {
    return http.get(`/provisioning/maint-cases/customer/${customerID}`)
  }

  async listMaintenanceCasesByElementID (elementID: string): Promise<MaintenanceCase[]> {
    return http.get(`/provisioning/maint-cases/element-id/${elementID}`)
  }

}

export default new MaintenanceService()

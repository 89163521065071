<template>
  <div v-if="mode === 'submitting'" class="p-4 font-increase-2 subtle bold">
    Working ...
  </div>

  <SubmissionForm
    :fields1="formFields"
    @setFormData="formDataChanged"
    @setCanSubmit="setCanSubmit"
    :formData="formData"
    v-else
  />
</template>
<script>
import SubmissionForm from '@/components/forms/SubmissionForm.vue'
import { DevicePositions } from '@/app/data/positions'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'new-relocate-request-form',
  props: ['jobsData', 'data'],
  emits: ['setCanSubmit', 'onSubmitSuccess'],
  data () {
    return {
      isLoading: false,
      canSubmit: false,
      formData: { ...this.data } || {},
      mode: ''
    }
  },
  watch: {
    hasInvalidDevEui: {
      handler () {
        this.setCanSubmit(this.canSubmit)
      }
    },
    devEui: {
      handler () {
        if (this.devEui && this.devEui.length > 15) {
          this.deviceEuiChanged()
        }
      }
    }
  },
  computed: {
    isJobAlreadyRunningForDevice () {
      if (!this.devEui || !this.jobsData || this.jobsData.length === 0) {
        return false
      }
      for (const job of this.jobsData) {
        if (job.status !== 'new') {
          continue
        }
        if (job.metadata && job.metadata.deviceEui && job.metadata.deviceEui === this.devEui) {
          return true
        }
      }
      return false
    },
    hasInvalidDevEui () {
      if (!this.devEui || this.devEui.length < 16) {
        return false
      }
      const key = `device.${this.devEui.trim().toLowerCase()}`
      if (key in FleetDataService.manifest.data) {
        return false
      }
      return true
    },
    devEui () {
      if ('deviceEui' in this.formData && this.formData.deviceEui &&
        this.formData.deviceEui.length >= 16) {
        return this.formData.deviceEui.trim()
      }
      return undefined
    },
    canDoQuickMove () {
      if (!this.formData.srcSite || !this.formData.destSite) {
        return false
      }
      return this.formData.srcSite === this.formData.destSite
    },
    sites () {
      const out = []
      if (FleetDataService.manifest && FleetDataService.manifest.data) {
        for (const key in FleetDataService.manifest.data) {
          if (!(key.startsWith('site.'))) {
            continue
          }
          const element = FleetDataService.manifest.data[key]
          const id = key.substring(5)
          const title = element.title ? element.title : id
          if (title === 'Main List') {
            continue
          }
          out.push({
            id: id,
            value: id,
            displayName: title ? title : id
          })
        }
      }
      return out
    },
    srcNodes () {
      return this.siteNodes(this.formData.srcSite, true)
    },
    destNodes () {
      return this.siteNodes(this.formData.destSite)
    },
    positionOptionsSrc () {
      const out = []
      out.push({
        id: 'any',
        value: 'any',
        displayName: 'Any'
      })
      for (const pos in DevicePositions) {
        out.push({
          id: pos,
          value: pos,
          displayName: pos
        })
      }
      return out
    },
    positionOptionsDest () {
      const out = []
      for (const pos in DevicePositions) {
        out.push({
          id: pos,
          value: pos,
          displayName: pos
        })
      }
      return out
    },
    formFields () {
      return [
        {
          field: 'srcSite',
          label: 'Source Site',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1',
          options: this.sites,
          disabled: this.isLoading
        },
        {
          field: 'srcNode',
          label: 'Source Node',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1',
          options: this.srcNodes,
          disabled: this.isLoading
        },
        {
          field: 'srcPosition',
          label: 'Source Position',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1',
          options: this.positionOptionsSrc,
          disabled: this.isLoading
        },
        {
          field: 'deviceEui',
          label: 'Device EUI',
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1' + 
            (this.hasInvalidDevEui || (this.formData.deviceEui && 
            this.formData.deviceEui.length != 16) ? ' text-input-error' : ''),
          controlType: 'textInput',
          required: true,
          dataType: 'hex',
          disabled: this.isLoading
        },
        {
          controlType: 'textContent',
          text: 'The Submit button is disabled until the Request currently processing for this Device completes.',
          textClass: 'field-error font-reduce-1',
          hidden: !this.isJobAlreadyRunningForDevice
        },
        {
          controlType: 'textContent',
          text: 'Unrecognized Device EUI',
          textClass: 'field-error',
          hidden: !this.hasInvalidDevEui
        },
        {
          field: 'destSite',
          label: 'Destination Site',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1',
          options: this.sites,
          disabled: this.isLoading
        },
        {
          field: 'destNode',
          label: 'Destination Node',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          inputClass: 'width-205 font-reduce-1',
          options: this.destNodes,
          disabled: this.isLoading
        },
        {
          field: 'destPosition',
          label: 'Destination Posiiton',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-155 font-reduce-2',
          inputClass: 'width-205 font-reduce-1',
          options: this.positionOptionsDest,
          disabled: this.isLoading
        },
        {
          field: 'startTime',
          label: 'Start Time',
          controlType: 'simpleDateTime',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          disabled: this.isLoading
        },
        {
          field: 'endTime',
          label: 'End Time',
          controlType: 'simpleDateTime',
          required: true,
          labelClass: 'width-155 font-reduce-1',
          disabled: this.isLoading
        },
        {
          controlType: 'hr',
        },
        {
          field: 'quickMove',
          controlType: 'checkboxInput',
          labelClass: 'width-0',
          checkboxLabel: this.canDoQuickMove ? 'Use Same-site Quick Update (Recommended)' : 'Use Same-site Quick Update',
          wrapperClass: 'mt-2',
          disabled: !this.canDoQuickMove || this.isLoading
        },
        {
          field: 'deleteSource',
          controlType: 'checkboxInput',
          labelClass: 'width-0',
          checkboxLabel: 'Delete Source Data After Loading into Destination',
          wrapperClass: 'mt-2',
          disabled: this.isLoading || (this.canDoQuickMove && this.formData.quickMove)
        }
      ]
    }
  },
  methods: {
    deviceEuiChanged() {
      if (!this.devEui || this.devEui.length < 16) {
        return
      }
      const key = `device.${this.devEui}`.toLowerCase()
      if (key in FleetDataService.manifest.data) {
        const obj = FleetDataService.manifest.data[key]
        if ((!('destSite' in this.formData) || !this.formData.destSite) &&
          obj.site) {
          this.formData.destSite = obj.site
        }
        if ((!('destNode' in this.formData) || !this.formData.destNode) &&
          obj.node) {
          this.formData.destNode = obj.node
        }
        if ((!('destPosition' in this.formData) || !this.formData.destPosition) &&
          obj.position) {
          this.formData.destPosition = obj.position
        }
      }
    },
    submitForm() {
      if (!this.canSubmit) {
        return
      }
      const data = {}
      data.start = this.formData.startTime
      data.end = this.formData.endTime
      data.sourceSite = this.formData.srcSite
      if (this.devEui) {
        data.deviceEui = this.devEui
      }
      if (this.formData.srcNode) {
        data.sourceNode = this.formData.srcNode
      }
      if (this.formData.srcPosition) {
        data.sourcePosition = this.formData.srcPosition
      }
      if (this.formData.destSite) {
        data.destSite = this.formData.destSite
      }
      if (this.formData.destNode) {
        data.destNode = this.formData.destNode
      }
      if (this.formData.destPosition) {
        data.destPosition = this.formData.destPosition
      }
      if (this.canDoQuickMove && this.formData.quickMove) {
        data.quickMove = this.formData.quickMove
      }
      if ((!this.canDoQuickMove || !this.formData.quickMove) &&
        this.formData.deleteSource) {
        data.deleteFromSource = this.formData.deleteSource
      }
      this.mode = 'submitting'
      this.isLoading = true
      FleetDataService.createRelocationRequest(data)
      .then(() => {
        HelpContent.setTimedFlashMessage('Uplink Relocation Request Submitted!')
        this.$emit('onSubmitSuccess')
      })
      .catch((e) => {
        HelpContent.setFlashMessage('Failed to submit Upload Relocation Request', true)
      })
      .finally(() => {
        this.mode = ''
        this.isLoading = false
      })
    },
    setCanSubmit(t) {
      this.canSubmit = t && !this.hasInvalidDevEui &&
        this.devEui !== undefined && !this.isJobAlreadyRunningForDevice
      this.$emit('setCanSubmit', this.canSubmit)
    },
    formDataChanged(data) {
      this.formData = data
    },
    siteNodes (siteId, includeAny = false) {
      const out = []
      if (includeAny) {
        out.push({
          id: 'any',
          value: 'any',
          displayName: 'Any'
        })
      }
      if (!siteId) {
        return out
      }
      for (const key in FleetDataService.manifest.data) {
        if (!(key.startsWith('node.'))) {
          continue
        }
        const element = FleetDataService.manifest.data[key]
        if (!element.site || element.site !== siteId) {
          continue
        }
        const id = key.substring(5)
        const title = element.title ? element.title : id
        out.push({
          id: id,
          value: id,
          displayName: title
        })
      }
      return out
    },
  },
  components: { SubmissionForm }
}
</script>
